import React, { useEffect, useRef } from "react";
import Chart from "react-apexcharts";
import "../Graf/Graf.scss";
import chartInstances from "../../../../../../../../utils/PDF/graf/f_chart_Instances";

const GrafVertical = ({
  id,
  grafTitle,
  grafTitle_bottom,
  text,
  // name,
  data,
  data2,
  graf_height,
  // meanings
  meaningsText,
  staticTexts,
  number_meaningsText,
  setMeanings,
  meanings
}) => {
  const chartRef = useRef(null);
  console.log(staticTexts)

  // console.log(meaningsText)
  // console.log(number_meaningsText)
  // console.log(data)
  
  // Uložení instance grafu do chartInstances
  useEffect(() => {
    
    chartInstances[id] = {
      chart: chartRef.current.chart,
      grafTitle: grafTitle,
    };
    
    return () => { // Odstranění instance grafu při unmountu
      delete chartInstances[id];
    };
  }, [id, grafTitle]);

  const seriesData = [{ name: grafTitle_bottom[0], data: data || [] }];

  if (data2 && data2.length > 0) {
    seriesData.push({ name: grafTitle_bottom[1], data: data2 });
  }

  // Získání minima a maxima z dat
  const allData = [...data, ...(data2 || [])];
  const minDataValue = Math.min(...allData);
  const maxDataValue = Math.max(...allData);

  // Dynamické nastavení rozsahu osy y
  let yMin, yMax;

  if (minDataValue >= 0) {
    yMin = -20;
    yMax = maxDataValue;
  } else if (maxDataValue <= 0) {
    yMin = minDataValue;
    yMax = 20;
  } else {
    yMin = minDataValue;
    yMax = maxDataValue;
  }

  // ----------
  let number_meanings = [];

// meanings - graf 2
if (meaningsText && meaningsText.length !== 0) {
  let averagedData = [];

  // Pokud máme dvě sady dat (data a data2), spočítej průměr hodnot na stejných indexech
  if (data2 && data2.length > 0) {
    averagedData = data.map((value, index) => {
      // Spočítej průměr hodnot na stejném indexu z data a data2
      const average = (value + data2[index]) / 2;
      return average;
    });
  } else {
    // Pokud máme jen jednu sadu dat, použij pouze tu
    averagedData = [...data];
  }

  // Seřazení textů na základě průměrné hodnoty (od největší po nejmenší)
  const sortedDataWithText = averagedData
    .map((value, index) => ({
      value,
      meaning: meaningsText[index], // správný klíč pro významové texty
    }))
    .sort((a, b) => b.value - a.value); // Seřazení podle průměrné hodnoty sestupně

  // Získání prvních položek podle number_meaningsText
  number_meanings = sortedDataWithText.slice(0, number_meaningsText);
}

  useEffect(() => {
    if(number_meanings.length > 0 && meanings.length === 0){
      setMeanings(number_meanings)
    } 

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[number_meanings])
  
 // ---------

  return (
    <div className="Graf" id={id}>
      <h4>{grafTitle}</h4>
      <Chart
        ref={chartRef}
        type="bar"
        width="100%"
        height={`${graf_height}px`}
        series={seriesData}
        options={{
          xaxis: {
            // title: {
            //   text: name,
            //   offsetX: 500, // Posun textu směrem dolů (protože je graf otočený)
            //   offsetY: 0,
            //   style: {
            //     fontSize: '12px',
            //     color: '#000',
            //   },
            // },
            categories: text,
            labels: {
              formatter: function (value) {
                return value === 0 ? "Norma" : value;
              },
              style: {
                fontSize: "12px",
                colors: ["#000"],
              },
            },
          },
          yaxis: {
            min: yMin,
            max: yMax,
            labels: {
              formatter: function (value, index) {
                return text[index] ? text[index] : value;
              },
              style: {
                fontSize: "14px",
                colors: ["#000"],
              },
            },
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "12px",
              colors: ["#000000"], // Nastavení barvy popisků
            },
            // offsetY: -15, // Posun hodnot na ose Y, uprav podle potřeby
            offsetX: 80, // Posun hodnot na ose X
            formatter: function (value) {
              return value.toFixed(1); // Zaokrouhlení na 1 des. místo
            },
          },
          plotOptions: {
            bar: {
              horizontal: true, // Horizontální graf
              dataLabels: {
                position: "top", // Popisky nad sloupci (bottom/center/top)
              },
              borderRadius: 10,
              borderRadiusApplication: "end",
            },
          },
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: undefined,
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 50, 100],
              colorStops: [],
            },
          },
          annotations: {
            xaxis: [
              {
                x: 0,
                borderColor: "#000",
              },
            ],
          },
          tooltip: {
            enabled: true,
            y: {
              formatter: function (value) {
                // Přidání mezer před hodnotu pro zarovnání na 10. místo
                const formattedValue = value.toFixed(1).toString().padStart(10, ' ');
                return formattedValue; // Vrací hodnotu zarovnanou s mezerami
              },
              title: {
                formatter: function (seriesName) {
                  return seriesName; // Zachování názvu série
                },
              },
            },
            style: {
              fontSize: "12px",
              colors: ["#000"], // Barva textu v tooltipu
            },
          },
        }}
      />
      
      <div className="meaningText">
        {
          number_meanings.length > 0 && number_meanings.map((onemeaning, index) => {
                const {meaning} = onemeaning;

                return <div key={index} className="meaningText_one">
                  <p className="graf_title">{meaning.title}</p>
                  <p className="graf_description" style={meaning.practice ? {borderBottom: "1px solid black "} : {}}>{meaning.description}</p>
                  <p>{meaning.practice}</p>
                </div>
                })
        }
        {
          staticTexts && staticTexts.map((oneText, index) => {
            const {title, description} = oneText;

            return <div key={index} className="meaningText_one">
              <p className="graf_title">{title}</p>
              <p className="graf_description">{description}</p>
            </div>
          })
        }
        </div>
    </div>
  );
};

export default GrafVertical;
