import "./Searcher.scss";
import { useEffect } from "react";
import InputContainer from "../inputs/InputContainer/InputContainer";
import AddValue from "./AddValue/AddValue";
import route_post_unversal from "../../../routes/global/route_post_universal";

const Searcher = ({
  btnAddDelete,
  title,
  inputType,
  inputLabel,
  inputID,
  inputRegex,
  urlServer,
  wordResultKey,
  state,
  urlFetch,
  urlFetch_add,
  setState,
  state_fetch_result,
  setFetch_usaRegion,
  setError,
  refresh,
}) => {

// Search
  useEffect(() => {
    const fetchData = async () => {
      state_fetch_result([]);
      if (state.length >= 2) {
        const result = await route_post_unversal(urlServer, urlFetch, wordResultKey, state)
        state_fetch_result(result.fetch_data);
        if(result.usa_fetch_data){
          setFetch_usaRegion(result.usa_fetch_data); // USA regions
        }
        
        setError(result.msg);
        // console.log(result.msg);
      }
    };

    // fetchData();
    if(urlFetch !== "" && state.length >= 2) {
      fetchData();
    } else if (state_fetch_result) {
        state_fetch_result([]);
    }
    

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, urlServer, refresh]);

  return (
    <div className="Searcher">
      <section className="title">
        <h1>{title}</h1>
      </section>

      <section className="main-searcher">
        <div className="searcher">
          <InputContainer
            type={inputType}
            label={inputLabel}
            id={inputID}
            regexSetting={inputRegex}
            value={state}
            set={setState}
            autocomplete="off"
          />
          {
            (!btnAddDelete && urlFetch_add !== undefined) && <AddValue
            wordResultKey={wordResultKey}
            state={state}
            urlServer={urlServer}
            urlFetch={urlFetch_add}
            route={route_post_unversal}
            setError={setError}
          />
          }
          
        </div>
      </section>
    </div>
  );
};

export default Searcher;
