import React from "react";
import "./BtnClose.scss";
import { GrClose } from "react-icons/gr";

const BtnClose = ({ setClose }) => {
  return (
    <>
      <button className="BtnClose" onClick={() => setClose(false)}>
        <GrClose />
      </button>
    </>
  );
};

export default BtnClose;
