export const f_checkBox_update_parant = (setFetch_result, id, fieldName, newStatus) => {
  setFetch_result((prevResult) => {
    console.log("Previous Result:", prevResult);

    // Rozdělení fieldName na cestu (pokud je vnořený, např. "admin_create.private")
    const fieldPath = fieldName.split(".");

    // Funkce pro dynamickou aktualizaci pole podle cesty
    const updateNestedField = (obj, path, value) => {
      const [firstKey, ...restPath] = path;
      if (restPath.length === 0) {
        return { ...obj, [firstKey]: value };
      }
      return {
        ...obj,
        [firstKey]: updateNestedField(obj[firstKey] || {}, restPath, value),
      };
    };

    // Kontrola, zda `fetch_data` existuje a je polem
    if (Array.isArray(prevResult.fetch_data)) {
      const updatedData = prevResult.fetch_data.map((item) => {
        if (item._id === id) {
          return updateNestedField(item, fieldPath, newStatus); // Dynamická aktualizace
        }
        return item;
      });
      return { ...prevResult, fetch_data: updatedData };
    } else if (Array.isArray(prevResult)) {
      // Pokud je `prevResult` polem bez `fetch_data`, zpracujeme ho jako takové
      const updatedData = prevResult.map((item) => {
        if (item._id === id) {
          return updateNestedField(item, fieldPath, newStatus); // Dynamická aktualizace
        }
        return item;
      });
      return updatedData; // Vrací aktualizované pole
    } else {
      console.error("Unsupported structure in `fetch_result`");
      return prevResult; // Vrací původní strukturu, pokud není rozpoznána
    }
  });
};