import Select from "react-select";

const SelectBasic = ({ options, object, onChange, placeholder, defaultOption }) => {
  let formattedOptions;

  if (!object || object.length === 0) {
    formattedOptions = options && options.length > 0 ? options.map((text, index) => ({
      value: index,
      label: text,
    })) : [];
  } else {
    formattedOptions = object.map((oneObject, index) => {
      const { value, label } = oneObject;

      return {
        value,
        label,
        index,
      };
    });
  }


  // const formattedOptions = options.length > 0 
  //   ? options.map((text) => ({
  //       value: text, // Použijeme přímo hodnotu jako `value`
  //       label: text.toString(), // Převod na řetězec pro zobrazení
  //     }))
  //   : [];
  

  return (
    <div>
      <Select
        options={formattedOptions}
        onChange={(selectedOption) => onChange(selectedOption.value)} // Vrátíme pouze `value`
        menuPlacement="auto"
        placeholder={placeholder}
        maxMenuHeight={200} // Maximální výška výběru
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: "center",
          }),
          option: (provided) => ({
            ...provided,
            textAlign: "center",
            cursor: "pointer",
          }),
        }}
        defaultValue={defaultOption} // Nastavit výchozí možnost
      />
    </div>
  );
};

export default SelectBasic;