import { useState, useContext } from "react";
import "./NewTag.scss";
import { GlobalContext } from "../../../../../../../global/GlobalContext";
import InputContainer from "../../../../../../elements/inputs/InputContainer/InputContainer";
import route_post_universal from "../../../../../../../routes/global/route_post_universal";
import CompanyFindFromName from "./CompanyFindFromName/CompanyFindFromName";
import SelectWordSet from "./SelectWordSet/SelectWordSet";
// import NewCompany from "./NewCompany/NewCompany";
import CheckBox from "../../../../../../elements/checkBox/CheckBox";

const NewTag = ({
  setError,
  error,
  newCompanyShow,
  setNewCompanyShow,
  setPhase,
  selectCompany,
  setSelectCompany,
  wordSet_select, 
  setWordSet_select,
  setNewTagShow
}) => {
  const { urlServer } =
    useContext(GlobalContext);

  const [newTag, setNewTag] = useState("");
  const [jobPosition, setJobPosition] = useState("");
  const [jobURL, setJobURL] = useState("");
  const [private_tag, setPrivate_tag] = useState(true);

  // ----------------------------------------------

  const btnCreateTag = async () => {
    if (newTag && selectCompany?.value) {
      try {
        const response = await route_post_universal(
          urlServer,
          "/tag_create",
          "tag_name",
          newTag,
          "job_position",
          jobPosition,
          "job_URL",
          jobURL,
          "respondnet_company_ID",
          selectCompany.value,
          "respondnet_company_Name",
          selectCompany.label,
          "wordSet_ID",
          wordSet_select.value,
          "wordSet_Name",
          wordSet_select.label,
          "private",
          private_tag
          // "admin_color",
          // admin_color,
          // "admin_name",
          // admin_name,
          // "admin_email",
          // admin_email
        );

        if (response && response.msg === "Tag vytvořen") {
          setPhase(0);
          setNewTagShow(false)
        } else {
          setError(response.msg);
        }
      } catch (err) {
        setError("Client - Catch: " + err);
      }
    } else {
      setError("Vyplňte všechna pole");
    }
  };

    // ----------------------------------------------

  return (
    <div className="NewTag">
      {/* Compeny Select + Create Company */}
      <CompanyFindFromName
      selectCompany={selectCompany}
        setSelectCompany={setSelectCompany}
        setError={setError}
        error={error}
        newCompanyShow={newCompanyShow}
        setNewCompanyShow={setNewCompanyShow}
      />
      {
        !selectCompany.value && <button className="normalButton" onClick={() => setPhase(2)}>
        Vytvořit společnost
      </button>
      }
      

      {/* Tag */}
      {selectCompany.value && (
        <>
          {/* New Tag Name */}
          <InputContainer
            type="text"
            label="Zadejte nový tag"
            id="newTag"
            value={newTag}
            set={setNewTag}
            regexSetting="tag"
          />
          {/* Job position */}
          <InputContainer
            type="text"
            label="Pracovní pozice"
            id="jopPosition"
            value={jobPosition}
            set={setJobPosition}
            regexSetting="tag"
          />
          {/* Job position */}
          <InputContainer
            type="text"
            label="Pracovní URL"
            id="jopURL"
            value={jobURL}
            set={setJobURL}
            regexSetting="URL"
          />

          <SelectWordSet
            wordSet_select={wordSet_select}
            setWordSet_select={setWordSet_select}
          />

          <CheckBox 
            value={private_tag} 
            set={setPrivate_tag}
            text={["Soukromí","Veřejný"]}
          />

          <button className="normalButton" onClick={btnCreateTag}>
            Vyvořit tag
          </button>
        </>
      )}
    </div>
  );
};

export default NewTag;
