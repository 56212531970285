import { BrowserRouter, Routes, Route } from "react-router-dom";
import { GlobalProvider } from "./global/GlobalContext";
// -Shared - Main
import SharedLayouts from "./global/layouts/SharedLayouts";
// UserData
import UserDataHome from "./pages/UserData/Home/UserDataHome";
import UsersData from "./pages/UserData/UserData/UsersData"; // users
import ColorSets from "./pages/UserData/ColorSets/ColorSets"; // colorSets - complete
import Unfilled from "./pages/UserData/NewRespondent/NewRespondent"; // colorSets - newRespondents
import Tags from "./pages/UserData/Tags/Tags";

// - Shared - Admin
import AdminDataSharedLayouts from "./global/layouts/AdminData/AdminData_SharedLayouts";
import Word from "./pages/Word";
import MeaningOfWords from "./pages/MeaningOfWords";
import WordSet from "./pages/WordSet";
//UserForm
import FormDataSharedLayouts from "./global/layouts/FormData/FormDataSharedLayouts";
import FormData from "./pages/FormData/Home/FormData";
import Country from "./pages/FormData/Country/Country";
import Education from "./pages/FormData/Education/Education";
import Profession from "./pages/FormData/Profession/Profession";

import Error from "./pages/Error";

const App = () => {
  
  return (
    <>
      <GlobalProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<SharedLayouts />}>
              <Route path="*" element={<Error />} />


              {/* User Data */}
              <Route index element={<UserDataHome />} />
              {/* Users */}
              <Route path="users_data" element={<UsersData />} />
              <Route path="users_data/:userID" element={<UsersData />} />
              {/* Filled */}
              <Route path="color_sets" element={<ColorSets />} />
              {/* Unfilled */}
              <Route path="color_sets_unfilled" element={<Unfilled />} />
              {/* Tags */}
              <Route path="tags" element={<Tags />} />


              {/* Admin Data */}
              <Route path="/admin_data" element={<AdminDataSharedLayouts />}>
                <Route path="meaning_of_words" element={<MeaningOfWords />} />
                <Route path="word_set" element={<WordSet />} />
                {/* TO:DO - pořešit že nic neělají ted */}
                <Route path="word" element={<Word />} /> 
                {/* Form Data */}
                <Route path="data_form" element={<FormDataSharedLayouts />}>
                  <Route index element={<FormData />} />
                  <Route path="country" element={<Country />} />
                  <Route path="education" element={<Education />} />
                  <Route path="profesion" element={<Profession />} />
                </Route>
              </Route>

            </Route>
          </Routes>
        </BrowserRouter>
      </GlobalProvider>
    </>
  );
};

export default App;