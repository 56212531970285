import NavFormData from "./layouts/NavFormData"
import { Outlet } from "react-router-dom"
import "./FormDataSharedLayouts.scss"

const FormDataSharedLayouts = () => {
  return (
    <> 
       <NavFormData />
        <div className="main">
            <Outlet />
        </div>
    </>
  )
}

export default FormDataSharedLayouts