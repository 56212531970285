import "./LogOut.scss";
import { useContext } from 'react';
import { GlobalContext } from '../../../GlobalContext';

const LogOut = () => {
  const { urlServer, setError } = useContext(GlobalContext);

  const btnLogOut = async () => {
    try {
      const response = await fetch(`${urlServer}/refreshToken-logOut`, {
        method: 'POST',
        credentials: 'include', // Zajistí, že cookies budou zahrnuty v požadavku
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        localStorage.clear();
        window.location.reload()
        console.log("Odhas")
      } else {
        setError("Odhlášení se nezdařilo");
      }
    } catch (error) {
      // console.error("Chyba při odhlašování:", error);
      setError("Odhlášení se nezdařilo");
    }
  };

  return (
    <div className="LogOut">
      <button className="button-full-div" onClick={btnLogOut}>Odhlásit</button>
    </div>
  );
}

export default LogOut;