import { useContext } from 'react'
import { GlobalContext } from '../../../../../../../global/GlobalContext'
import route_post_universal from '../../../../../../../routes/global/route_post_universal'
import "./BtnSendEmail_NewRespondent.scss"

const BtnSendEmail_NewRespondent = ({gender, name, surname, addressing, email, wordSet_select, selected_TagData, tagSelect, setNewRespondent, emailForm}) => {
    const { urlServer } = useContext(GlobalContext)

  // send email - newRespondent
  const send_email_newRespondent = async () => {
    const response = await route_post_universal(
      urlServer,
      "/newRespondent_emailSend",
      "gender_resp",
      gender,
      "name_resp",
      name,
      "surname_resp",
      surname,
      "addressing_resp",
      addressing,
      "email_resp",
      email,
      "tag_ID",
      tagSelect.value,
      "tag_Name",
      tagSelect.label,
      "wordSet_ID",
      wordSet_select.value,
      "wordSet_Name",
      wordSet_select.label,

      "respondent_company_ID",
      selected_TagData.company.company_ID,
      "respondent_company_Name",
      selected_TagData.company.company_Name,
      "respondent_company_URL",
      selected_TagData.company.company_URL,
      "tag_profesion_Name",
      selected_TagData.job_position,
      "tag_profesion_URL",
      selected_TagData.job_URL,

      "emailForm",
      emailForm
    )

    if(response.msg === "Respondent vytvořen a email odeslán"){
      console.log("Odesláno")
      setNewRespondent(false)
    } else {
      console.log(response.msg)
    }
  }

  // - No Send Email - New Respondent
  const newRespondent_noSendEmail = async () => {
    console.log(selected_TagData)
    const response = await route_post_universal(
      urlServer,
      "/newRespondent_noEmailSend",
      "gender_resp",
      gender,
      "name_resp",
      name,
      "surname_resp",
      surname,
      "addressing_resp",
      addressing,
      "email_resp",
      email,
      "tag_ID",
      tagSelect.value,
      "tag_Name",
      tagSelect.label,
      "wordSet_ID",
      wordSet_select.value,
      "wordSet_Name",
      wordSet_select.label,

      "respondent_company_ID",
      selected_TagData.company.company_ID,
      "respondent_company_Name",
      selected_TagData.company.company_Name,
    )

    if(response.msg === "Respondent vytvořen"){
      console.log("Odesláno")
      setNewRespondent(false)
    } else {
      console.log(response.msg)
    }
  }

  return (
    <div className="BtnSendEmail_NewRespondent">
        <button onClick={newRespondent_noSendEmail} className="normalButton">Vytvořit respondenta</button>
        <button onClick={send_email_newRespondent} className="normalButton">Vytvořit respondenta a odeslat E-mail</button>
    </div>
  )
}

export default BtnSendEmail_NewRespondent