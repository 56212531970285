// import Graf from "./Graf/Graf";
import GrafRadar from "./Graf_Radar/Graf_Radar";
import GrafVertical from "./Graf_Vertical/Graf_Vertical";
import "./GrafData_DataForGraf.scss";

const GrafData_DataForGraf = ({
  frequency,
  // name,
  // for 1 graf data = ((number word's color / number words) * 100)
  redResponse_userResult,
  blueResponse_userResult,
  greenResponse_userResult,
  whiteResponse_userResult,
  purpleResponse_userResult,
  id,
  numberWords,
  // graf 1 (meanings)
  meanings1,
  setMeanings1,
  // graf 2 (meanings)
  meanings2,
  setMeanings2,
  // graf 3 (meanings)
  meanings3,
  setMeanings3,
  // graf 4 (meanings)
  meanings4,
  setMeanings4,
  // graf 5 (static)
  inGroup_consciousness_staticTexty,
  // graf 6 (meanings)
  meanings6,
  setMeanings6,
  // graf 6 (meanings)
  meanings7,
  setMeanings7,
  //graf 8 (meanings)
  setTopThreeMeanings,
  topThreeMeanings,
}) => {
  
  // functions
  // Clasic IndexInFrequency
  const f_numberIndexInFreqyuency = (indexs, values) => {
    for (let i = 0; i < indexs.length; i++) {
      for (let y = 0; y < indexs[i].length; y++) {
        const index = indexs[i][y]; // Získání aktuálního indexu z surplusValueIndex - aktuálního pole
        values[i] += frequency[index].length; // Přičtení hodnoty z pole frequency k odpovídajícímu prvku v surplusValue
      }
    }
  };

  const f_sum = (values) => {
    let sum = 0;

    for (let i = 0; i < values.length; i++) {
      sum += values[i];
    }

    return sum;
  };

  //user = values / sum * 100
  const f_userValues = (values, sum, user) => {
    for (let i = 0; i < values.length; i++) {
      user.push((values[i] / sum) * 100);
    }
  };

  // ABS user + trends
  const f_ABS_trends = (user, normProcent, fristTrend, secondTrend) => {
    let ABSUser_NormProcent = 0; // Inicializace uvnitř funkce

    // Nejprve spočítáme ABSUser_NormProcent
    for (let i = 0; i < normProcent.length; i++) {
      ABSUser_NormProcent += Math.abs(user[i] - normProcent[i]);
    }

    // Poté spočítáme trendy
    for (let i = 0; i < normProcent.length; i++) {
      fristTrend.push(((user[i] - normProcent[i]) / ABSUser_NormProcent) * 100);
      secondTrend.push(((user[i] - normProcent[i]) / normProcent[i]) * 100);
    }

    return ABSUser_NormProcent;
  };

  // Graf 5 -------------------------------
  // Ve skupinovém vědomí - In the group consciousness
  //+,-      IndexInFrequency - every 2 have ((index + index) + (indexs)/2)
  const f_numberIndexInFreqyuency_PLUS_MINUS = (indexs, values) => {
    for (let i = 0; i < indexs.length; i++) {
      if (i % 2 === 0) {
        for (let y = 0; y < indexs[i].length; y++) {
          const index = indexs[i][y]; // Získání aktuálního indexu z surplusValueIndex - aktuálního pole
          values[i] += frequency[index].length; // Přičtení hodnoty z pole frequency k odpovídajícímu prvku v surplusValue
        }
      } else {
        for (let y = 0; y < indexs[i].length; y++) {
          if (y === 0 || y === 1) {
            const index = indexs[i][y]; // Získání aktuálního indexu z surplusValueIndex - aktuálního pole
            values[i] += frequency[index].length; // Přičtení hodnoty z pole frequency k odpovídajícímu prvku v surplusValue
          } else {
            const index = indexs[i][y]; // Získání aktuálního indexu z surplusValueIndex - aktuálního pole
            values[i] += frequency[index].length / 2; // Přičtení hodnoty z pole frequency k odpovídajícímu prvku v surplusValue
          }
        }
      }
    }
  };

  // ABS user + trends
  const f_ABS_trends_withABS = (
    user,
    normProcent,
    ABSUser_NormProcent,
    fristTrend,
    secondTrend
  ) => {
    // Nejprve spočítáme ABSUser_NormProcent
    for (let i = 0; i < normProcent.length; i++) {
      ABSUser_NormProcent += Math.abs(user[i] - normProcent[i]);
    }

    // Poté spočítáme trendy
    for (let i = 0; i < normProcent.length; i++) {
      fristTrend.push(((user[i] - normProcent[i]) / ABSUser_NormProcent) * 100);
      secondTrend.push(((user[i] - normProcent[i]) / normProcent[i]) * 100);
    }
  };

  // Graf 6 ----------------------------------------
  // 6 no name
  // ABS user + trends
  const f_ABS_trends_ABSOnly6Values = (
    user,
    normProcent,
    fristTrend,
    secondTrend
  ) => {
    let ABSUser_NormProcent = 0; // Inicializace uvnitř funkce

    // Nejprve spočítáme ABSUser_NormProcent
    for (let i = 0; i < normProcent.length - 2; i++) {
      ABSUser_NormProcent += Math.abs(user[i] - normProcent[i]);
    }

    // Poté spočítáme trendy
    for (let i = 0; i < normProcent.length; i++) {
      fristTrend.push(((user[i] - normProcent[i]) / ABSUser_NormProcent) * 100);
      secondTrend.push(((user[i] - normProcent[i]) / normProcent[i]) * 100);
    }

    return ABSUser_NormProcent;
  };
  // --------------------------------------------------------------------------------------------

  // Data from top table
  const consistentData = [14, 25, 23, 3, 0];
  let consistentData_Sum = f_sum(consistentData);

  // --------------------------------------------------------------------------------------------

  // Table pieces
  // 0 - VLIV  POSTOJE  NA  SKUPINOVÉ  VĚDOMÍ - THE EFFECT OF ATTITUDE ON GROUP CONSCIOUSNESS
  // user
  const effectAttitudeOnGroupConsciousness_user = [];
  f_userValues(
    consistentData,
    consistentData_Sum,
    effectAttitudeOnGroupConsciousness_user
  );

  // Nomr in %
  const effectAttitudeOnGroupConsciousness_normProcent = [
    12.19, 24.212, 30, 21.43, 12.19,
  ];

  // trends
  const behavioralTrend = []; // trend chování - behavioral trend
  const trendOfExperiencing = []; //trend prožívání - the trend of experiencing

  // ABS user(norm) + set trends
  // const ABSUser_NormProcent = f_ABS_trends(
  f_ABS_trends(
    effectAttitudeOnGroupConsciousness_user,
    effectAttitudeOnGroupConsciousness_normProcent,
    behavioralTrend,
    trendOfExperiencing
  );

  //---------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------
  // 1 - NA  SKUPINOVÉ  VĚDOMÍ - word's color
  const wordsColor_GroupC_graf_text = [
    "Ideály",
    "Motivace",
    "Neutralita",
    "Stresory",
    "Destruktory",
  ];

  const wordsColor_GroupC_user = [
    redResponse_userResult,
    blueResponse_userResult,
    greenResponse_userResult,
    whiteResponse_userResult,
    purpleResponse_userResult,
  ];
  const wordsColor_GroupC_nromProcent = [
    6.9351, 24.3968, 38.8263, 18.7044, 11.1373,
  ];

  const wordsColor_GroupC_behavioralTrend = []; // trend chování - behavioral trend
  const wordsColor_GroupC_trendOfExperiencing = []; //trend prožívání - the trend of experiencing

  f_ABS_trends(
    wordsColor_GroupC_user,
    wordsColor_GroupC_nromProcent,
    wordsColor_GroupC_behavioralTrend,
    wordsColor_GroupC_trendOfExperiencing
  );

  // Graf
  const wordsColor_GroupC_graf_title =
    "Jak vnímám realitu";
  const wordsColor_GroupC_graf_titleBottom = [
    "Co ukazuji navenek",
    "Co prožívám uvnitř",
  ];
  const wordsColor__meaningText = [
    {
      title: "Ideály",
      description: "Zaměstnanec preferuje hodnoty, které jsou abstraktní představy, které jedinec považuje za dokonalé nebo vysoce žádoucí a které ho motivují k určitému způsobu myšlení, chování a rozhodování. Ideály mohou vycházet z osobních přesvědčení, kulturního a společenského prostředí nebo filozofických a náboženských systémů. Mají hluboký psychologický a sociální význam, protože formují osobní identitu, smysl života a ovlivňují každodenní jednání. Mohou vést k seberealizaci a osobnímu růstu, ale také k frustraci, pokud jsou ideály nedosažitelné."
    },
    {
      title: "Motivace",
      description: "Zaměstnanec preferuje hodnoty, které popisují vnitřní procesy, jež vedou člověka k určitému jednání, rozhodování a dosahování cílů. Motivace odpovídá na otázku, proč jednáme určitým způsobem a co nás k tomu vede. Jde o hnací sílu lidského chování, která je ovlivněna jak vnitřními, tak vnějšími faktory. Motivace je klíčovým faktorem, který určuje směr, intenzitu a trvání lidského chování. Je nezbytná pro osobní i profesní rozvoj a naplnění životních cílů. Rozvoj vnitřní motivace, stejně jako pochopení vnějších faktorů, které ovlivňují naše jednání, může pomoci dosáhnout většího naplnění a spokojenosti v životě."
    },
    {
      title: "Neutralita",
      description: "Zaměstnanec preferuje hodnoty, které se podílejí na každodenním fungování jedince v různých situacích a úkolech. Tento koncept lze chápat jako soubor kognitivních, emočních a behaviorálních procesů, které umožňují člověku zvládat běžné aktivity a reagovat na každodenní výzvy."
    },
    {
      title: "Stresory",
      description: "Zaměstnanec preferuje hodnoty, které vyvolávají stresovou reakci v těle a mysli člověka. V psychologii označují všechny faktory, které přispívají k pocitu stresu, což může být fyzická, psychická nebo emocionální reakce na určité události či podmínky. Stresory jsou nedílnou součástí života každého člověka, ale jejich vliv na jednotlivce se liší v závislosti na subjektivním vnímání a schopnostech zvládání stresu. Pochopení a identifikace stresorů, stejně jako rozvoj vhodných copingových mechanismů, jsou klíčové pro udržení zdravé mysli a těla."
    },
    {
      title: "Destruktory",
      description: "Zaměstnanec preferuje hodnoty, které se vztahují k faktorům, událostem nebo mechanismům, které mohou negativně ovlivnit psychické zdraví jednotlivce, narušit jeho fungování nebo způsobit psychické, emoční nebo behaviorální problémy. Mohou zahrnovat jak vnitřní, tak vnější prvky, které vedou k destruktivnímu chování nebo k duševnímu utrpení. Identifikace a porozumění těmto faktorům mohou pomoci jednotlivcům lépe zvládat stres a zlepšovat své psychické zdraví. Uvědomění si destruktorů a práce na jejich překonání je klíčovým krokem k dosažení psychické pohody a zdravého životního stylu."
    }
  ];
  //---------------------------------------------------------------------------------------------

  // 2 NA  SKUPINOVÉ  VĚDOMÍ - ON GROUP CONSCIOUSNESS
  // surplus values index
  const surplusValue_logicIndex = [0, 1, 4, 10, 16, 22];
  const surplusValue_vitalityIndex = [2, 3, 9, 15, 21, 27];
  const surplusValue_ambitionIndex = [1, 2, 8, 14, 20, 26];
  const surplusValue_cooperationIndex = [0, 3, 5, 11, 17, 23];
  const surplusValue_participationIndex = [1, 3, 6, 12, 18, 24];
  const surplusValue_dominionIndex = [0, 2, 7, 13, 19, 25];

  const surplusValueIndex = [
    surplusValue_logicIndex,
    surplusValue_vitalityIndex,
    surplusValue_ambitionIndex,
    surplusValue_cooperationIndex,
    surplusValue_participationIndex,
    surplusValue_dominionIndex,
  ];

  // surplus values from frequency
  const surplusValue = [0, 0, 0, 0, 0, 0];
  f_numberIndexInFreqyuency(surplusValueIndex, surplusValue);

  // surplus sum
  let surplusValue_sum = f_sum(surplusValue);

  // Texts
  const groupConsciousness_text = [
    "Logika",
    "Vitalita",
    "Ambice",
    "Kooperace",
    "Participace",
    "Dominance",
  ];

  // user
  const groupConsciousness_user = [];
  f_userValues(surplusValue, surplusValue_sum, groupConsciousness_user);

  // Nomr in %
  const groupConsciousness_normProcent = [
    17.14, 24.4439, 13.4032, 13.9512, 14.7818, 16.2799,
  ];

  // users Values
  // let groupConsciousness_ABSUser_NormProcent_sum = 0; // ABS user - norm
  const groupConsciousness_behavioralTrend = []; // trend chování - behavioral trend
  const groupConsciousness_trendOfExperiencing = []; // trend prožívání - the trend of experiencing

  const groupConsciousness_ABS = f_ABS_trends(
    groupConsciousness_user,
    groupConsciousness_normProcent,
    groupConsciousness_behavioralTrend,
    groupConsciousness_trendOfExperiencing
  );

  // For Graf
  const groupConsciousness_graf_title =
    "Kritické myšlení";
  const groupConsciousness_graf_titleBottom = ["Co prožívám uvnitř"];

  const groupConsciousness_meaningText = [
    {
      title: "Logika",
      description:
        "Zaměstnanec se bude nejlépe učit na základě opakování nových vědomostí a činností. Dále potřebuje mít při učení jasný řád a dostatek informací. Vhodnými materiály jsou sepsané zdroje jako třeba knihy, elektronické zdroje, popisy nových postupů a znalostní báze.",
      practice:
        "V praxi to znamená, že nový pracovník by měl ihned po nástupu dostat k dispozici všechny potřebné zdroje informací, včetně toho, kde je možné se obrátit pro pomoc. Měl by být nastaven harmonogram s pevně danými termíny, do kterých se nové věci naučí a začne je používat. Není vhodné pracovníka nutit ihned vykonávat pracovní činnost, aniž by mu byl dán prostor k zaučení a aklimatizaci na nové pozici.",
    },
    {
      title: "Vitalita",
      description:
        "Zaměstnanec se bude učit velmi aktivně pomocí metody opakovaného pokusu a omylu. Vždy si nejdříve potřebuje vyzkoušet praktické příklady nových pracovních aktivit.",
      practice:
        "V praxi je pro něj přínosné nové věci vidět, poslechnout si je, či si je přečíst, ale je nezbytné na tuto aktivitu navázat praktickou činností, aby si nově nabytou vědomost osvojil a udělal z ní dovednost. V případě, že k tomuto vyzkoušení nedojde, nebude proces učení efektivní. Je potřeba provádět činnosti rychle a 'živelně', což je v případě tohoto zaměstnance mnohem lepší, než volit strategický a rozvážný systém učení.",
    },
    {
      title: "Ambice",
      description:
        "Zaměstnanec se kvalitně učí v prostředí, kde vidí možnost vyniknout. Při učení novému je vhodné, aby se pracovník přímo identifikoval s novými informacemi.",
      practice:
        "V praxi to znamená, že nové informace a postupy musí nový zaměstnanec přijmout 'za své'. Pokud se tak nestane, hrozí, že budou odmítnuty a v budoucnu si je uzpůsobí podle svého. Je vhodné, aby si nový zaměstnanec sám určil (pokud je to možné), jakou formou se bude nové informace a pracovní postupy učit. Je žádoucí získávat od něj pravidelnou zpětnou vazbu, ujišťovat se, jestli se se svou pozicí i novou prací ztotožnil. Pokud se tak nestane, bude pro něj velice obtížné nové vědomosti i dovednosti používat.",
    },
    {
      title: "Kooperace",
      description:
        "Zaměstnanec se efektivně učí v prostředí, kde je možné napodobovat práci jiných. Nejlepší formou učení je pro něj sociální nápodoba.",
      practice:
        "Prakticky by mělo dojít ke stínování práce zkušeného kolegy, který pracovní činnosti dobře zvládá, a je ochoten novému zaměstnanci předávat dobrou praxi i své zkušenosti. V tomto případě nový zaměstnanec dokáže jeho práci napodobit a efektivně ji provádět. Je žádoucí, aby docházelo k přímé spolupráci mezi zkušeným a novým pracovníkem. Ostatní způsoby učení a zdroje informací by měly být pro zaměstnance spíše doplňkové a podpůrné.",
    },
    {
      title: "Participace",
      description:
        "Zaměstnanec se kvalitně učí v prostředí, kde je možnost stálé komunikace a kontaktu. Je pro něj velice vhodná skupinová forma učení.",
      practice:
        "Prakticky je nejlepší jej zařadit do skupinového školení, kde se více nových zaměstnanců učí stejné pracovní postupy a pracují na společném cíli. Pokud se bude jednat o samostatné učení, bude vhodné nastavit si správně komunikační kanály s kolegy, kterých se může na potřebné informace zeptat, nebo konzultovat nově objevené fakta. Ostatní způsoby učení a zdroje informací by měly být pro zaměstnance spíše doplňkové a podpůrné.",
    },
    {
      title: "Dominance",
      description:
        "Zaměstnanec potřebuje při učení nového efektivně prosadit svou osobu.",
      practice:
        "V praxi to znamená, že je potřeba, aby pracovník viděl, že nové věci fungují a jeho osoba může být tím, kdo může novou činnost provádět. Je velice vhodné použít příklady dobré praxe, které spolehlivě fungují a vysvětlit zapojení zaměstnance do procesu. V případě, že půjde jenom o teoretickou výuku, bez příkladů a jasného zapojení nového pracovníka, pak nedojde k efektivnímu učení.",
    },
  ];
  //---------------------------------------------------------------------------------------------------------------------------

  // 3 funkčnost pri zpracování reality - unctionality in processing reality
  //surplusValue
  const processingReality_surplusValue_Susceptibility_Index = [
    0, 1, 3, 4, 5, 6, 10, 11, 12, 16, 17, 18, 22, 23, 24, 28, 32, 36, 40, 44,
    48,
  ];
  const processingReality_surplusValue_Stamina_Index = [
    0, 1, 2, 4, 7, 8, 10, 13, 14, 16, 19, 20, 22, 25, 26, 29, 33, 37, 41, 45,
    49,
  ];
  const processingReality_surplusValue_Action_Index = [
    0, 2, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 30, 34, 38, 42, 46,
    50,
  ];
  const processingReality_surplusValue_Opportunity_Index = [
    1, 2, 3, 6, 8, 9, 12, 14, 15, 18, 20, 21, 24, 26, 27, 31, 35, 39, 43, 47,
    51,
  ];
  const processingReality_surplusValue_Sensitivity_Index = [
    4, 5, 6, 7, 8, 9, 28, 29, 30, 31, 32, 33, 34, 35, 40, 41, 42, 43, 52, 53,
    54,
  ];
  const processingReality_surplusValue_Sensuality_Index = [
    10, 11, 12, 13, 14, 15, 28, 29, 30, 31, 36, 37, 38, 39, 44, 45, 46, 47, 52,
    53, 55,
  ];
  const processingReality_surplusValue_Defense_Index = [
    22, 23, 24, 25, 26, 27, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 53,
    54, 55,
  ];
  const processingReality_surplusValue_Protection_Index = [
    16, 17, 18, 19, 20, 21, 32, 33, 34, 35, 36, 37, 38, 39, 48, 49, 50, 51, 52,
    54, 55,
  ]; // bud 50,50 nebo 51,51

  const processingReality_surplusValue_Index = [
    processingReality_surplusValue_Susceptibility_Index,
    processingReality_surplusValue_Stamina_Index,
    processingReality_surplusValue_Action_Index,
    processingReality_surplusValue_Opportunity_Index,
    processingReality_surplusValue_Sensitivity_Index,
    processingReality_surplusValue_Sensuality_Index,
    processingReality_surplusValue_Defense_Index,
    processingReality_surplusValue_Protection_Index,
  ];

  // values
  let processingReality_surplusValue = [0, 0, 0, 0, 0, 0, 0, 0];
  f_numberIndexInFreqyuency(
    processingReality_surplusValue_Index,
    processingReality_surplusValue
  );

  // Sum
  let processingReality_surplusValue_sum = f_sum(
    processingReality_surplusValue
  );

  // Text
  const processingReality_text = [
    "Vnímavost",
    "Výdrž",
    "Akceschopnost",
    "Příležitost",
    "Citlivost",
    "Smyslovost",
    "Obrana",
    "Ochrana",
  ];

  //user = values / sum * 100
  const processingReality_user = [];
  f_userValues(
    processingReality_surplusValue,
    processingReality_surplusValue_sum,
    processingReality_user
  );

  // Nomr in %
  const processingReality_normProcent = [
    14.0145, 13.5994, 14.5226, 13.4016, 12.5426, 11.5867, 8.9183, 11.4143,
  ];

  const processingReality_externalTrends = []; // trend venkovní
  const processingReality_internalTrends = []; // trend vnitřní

  f_ABS_trends(
    processingReality_user,
    processingReality_normProcent,
    processingReality_externalTrends,
    processingReality_internalTrends
  );

  // Graf
  //Graf titles
  const processingReality_graf_title =
    "Každodenní chování a postoje";
  const processingReality_graf_titleBottom = ["Trendy Vnímání"];
  const processingReality_graf_meanings_texts = [
    {
      title: "Vnímavost",
      description: "Zaměstnanec preferuje přijímat, zpracovávat a interpretovat smyslové informace z okolí. V psychologii má vnímavost zásadní význam, protože ovlivňuje, jak jedinec vnímá svět, reaguje na podněty a vytváří si obraz reality. Tato schopnost je základní pro lidské chování, rozhodování i emocionální a kognitivní procesy. Osoby s širšími zkušenostmi nebo znalostmi v určité oblasti mohou být schopny zachytit více podnětů a nuance."
    },
    {
      title: "Výdrž",
      description: "Zaměstnanec preferuje psychickou odolnost nebo rezilienci, vztahuje se na schopnost jednotlivce dlouhodobě se vyrovnat s obtížemi, stresovými situacemi a překážkami. Je to schopnost čelit výzvám, přizpůsobit se změnám a zotavit se z nepříznivých událostí."
    },
    {
      title: "Akceschopnost",
      description: "Zaměstnanec je schopen reagovat a jednat v určité situaci, zejména pod tlakem nebo ve stresujících podmínkách. Je schopen rychle a efektivně reagovat na nové podněty nebo situace. To zahrnuje jak fyzickou, tak psychickou rychlost. Je schopen se přizpůsobit měnícím se podmínkám a situacím, což je důležité pro úspěšné zvládání neočekávaných událostí."
    },
    {
      title: "Příležitost",
      description: "Zaměstnanec vidí příležitosti, která se vztahuje k situacím nebo okolnostem, které jednotlivci umožňují růst, rozvoj a dosažení cílů. Může se jednat o příležitosti, které nabízejí nové zkušenosti, učení, nebo interakce s ostatními. Příležitosti mohou podněcovat osobní rozvoj a sebeuvědomění."
    },
    {
      title: "Citlivost",
      description: "Zaměstnanec se věnuje rozpoznávání, chápání a reakcím na vlastní emoce a emoce ostatních. Lidé s vysokou emocionální citlivostí mohou být schopni lépe empatičtěji a reagovat na potřeby druhých."
    },
    {
      title: "Smyslovost",
      description: "Zaměstnanec má schopnost schopnost vnímat a interpretovat podněty ze smyslových orgánů (zrak, sluch, čich, chuť a hmat). Smyslovost hraje klíčovou roli v tom, jak lidé vnímají a interagují se světem kolem sebe. Smyslovost hraje důležitou roli v mezilidských vztazích. Například neverbální komunikace, jako je mimika, tón hlasu nebo dotek, je klíčová pro porozumění a interakci s ostatními."
    },
    {
      title: "Obrana",
      description: "Zaměstnanec má náklonnost k nevědomým psychologickým strategiím, které jednotlivci používají k ochraně svého psychického zdraví před stresem, úzkostí a vnitřním konfliktem. Obranné mechanismy jsou často automatické a mohou pomáhat lidem zvládat negativní myšlenky a emoce."
    },
    {
      title: "Ochrana",
      description: "Zaměstnanec se věnuje různým způsobům, jakým dlouhodobě chrání své duševní zdraví a obranu před negativními vlivy, stresem nebo emocemi. Může zahrnovat jak fyzické, tak psychické strategie."
    }
  ];

  // ---------------------------------------------------------------------------------------

  // 4 systémy - systems

  // surplusValues
  // Pod tabilkou - Skupinové vědomí
  const system_somatic_index = [
    10, 11, 12, 13, 14, 15, 28, 29, 30, 31, 36, 37, 38, 39, 44, 45, 46, 47, 52,
    53, 55,
  ];
  const system_energetic_index = [
    0, 2, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 30, 34, 38, 42, 46,
    50,
  ];
  const system_mentail_index = [
    0, 1, 2, 4, 7, 8, 10, 13, 14, 16, 19, 20, 22, 25, 26, 29, 33, 37, 41, 45,
    49,
  ];
  const system_social_index = [
    0, 3, 4, 5, 6, 10, 11, 12, 16, 17, 18, 22, 23, 24, 28, 32, 36, 40, 44, 48,
  ];
  const system_surplusValue_index = [
    system_somatic_index,
    system_energetic_index,
    system_mentail_index,
    system_social_index,
  ];

  let system_surplusValues = [0, 0, 0, 0];
  f_numberIndexInFreqyuency(system_surplusValue_index, system_surplusValues);

  let system_surplusValues_sum = f_sum(system_surplusValues);

  //text
  const system_text = [
    "Tělesný",
    "Emoční",
    "Rozumový",
    "Vztahový",
  ];

  //user = values / sum * 100
  const system_user = [];
  f_userValues(system_surplusValues, system_surplusValues_sum, system_user);

  // Nomr in %
  const system_normProcent = [21.937, 27.4955, 25.7476, 24.8198];

  const system_behavioral_trend = []; // trend chování
  const system_trendOfExperiencing = []; // trend prožívání

  f_ABS_trends(
    system_user,
    system_normProcent,
    system_behavioral_trend,
    system_trendOfExperiencing
  );

  // Graf ----------------
  const system_graf_title = "Subsystémy mozku";
  const system_graf_titleBottom = ["Co ukazuji navenek", "Co prožívám uvnitř"];
  const system_graf_meaningsTexts = [
    {
      title: "Tělesný",
      description: "Zaměstnanec ve svém vědomí nejvíce používá subsystém, který se vztahuje k našemu tělesnému prožívání a vnímání těla, včetně fyziologických procesů, smyslového vnímání a propojení mezi tělem a myslí. Tento subsystém má významný psychologický dopad, protože tělo a mysl jsou úzce propojené, což se odráží v psychologickém fungování člověka. Tělesný subsystém vědomí tedy hraje zásadní roli v tom, jak prožíváme svět, sami sebe a jakým způsobem se staráme o naše fyzické i psychické zdraví."
    },
    {
      title: "Emoční",
      description: "Zaměstnanec ve svém vědomí nejvíce používá subsystém, který se zaměřuje na vnímání, prožívání a regulaci emocí. Emoce jsou důležitou součástí našeho každodenního fungování a mají zásadní vliv na naše myšlenky, chování i rozhodování. Tento subsystém hraje klíčovou roli v psychologickém fungování jedince a jeho vztazích s ostatními. Emoční subsystém vědomí je nezbytný pro komplexní lidské fungování. Ovlivňuje nejen to, jak prožíváme různé situace, ale také jak na ně reagujeme a jak zvládáme stres. Schopnost vnímat a regulovat své emoce, stejně jako empatie vůči druhým, jsou klíčové dovednosti, které ovlivňují celkovou kvalitu života a psychickou pohodu."
    },
    {
      title: "Rozumový",
      description: "Zaměstnanec ve svém vědomí nejvíce používá subsystém, který se zaměřuje na myšlení, logické usuzování, plánování a zpracování informací. Tento subsystém má významný psychologický dopad, protože umožňuje lidem analyzovat své zkušenosti, myslet o budoucnosti a chápat svět kolem sebe. Rozumový subsystém vědomí je tedy klíčový pro intelektuální i emoční růst, pro efektivní interakci s okolím a pro úspěšné zvládání výzev, které život přináší."
    },
    {
      title: "Vztahový",
      description: "Zaměstnanec ve svém vědomí nejvíce používá subsystém, který vnímá a interpretuje vztahové interakce – vědomí je odpovědné za to, jak vnímáme a hodnotíme chování druhých lidí. Zaměstnanec neustále posuzuje, zda jsou vztahy harmonické, konfliktní nebo podporující. Vztahový subsystém vědomí je klíčový pro psychickou pohodu, protože kvalita vztahů výrazně ovlivňuje naši emoční stabilitu, identitu a schopnost zvládat stres či konflikty."
    }
  ];

  //-----------------------------------------
  // 5 - Ve skupinovém vědomí - In the group consciousness
  const inGroup_consciousness_logic_PLUS_index = [0, 1, 4, 10]; // logic +
  const inGroup_consciousness_logic_MINUS_index = [
    16, 22, 28, 29, 32, 33, 36, 37,
  ]; // logic -     (0+1(*****)/2)
  const inGroup_consciousness_vitality_PLUS_index = [2, 3, 9, 15]; // vitality +
  const inGroup_consciousness_vitality_MINUS_index = [
    21, 27, 30, 31, 34, 35, 38, 39,
  ]; // vitality -     (0+1(*****)/2)
  const inGroup_consciousness_ambition_PLUS_index = [1, 2, 8, 14]; // ambition +
  const inGroup_consciousness_ambition_MINUS_index = [
    20, 26, 29, 31, 33, 35, 37, 39,
  ]; // ambition -     (0+1(*****)/2)
  const inGroup_consciousness_cooperation_PLUS_index = [0, 3, 5, 11]; // cooperation +
  const inGroup_consciousness_cooperation_MINUS_index = [
    17, 23, 28, 30, 32, 34, 36, 38,
  ]; // cooperation -     (0+1(*****)/2)
  const inGroup_consciousness_participation_PLUS_index = [1, 3, 6, 12]; // participation +
  const inGroup_consciousness_participation_MINUS_index = [
    18, 24, 28, 31, 32, 35, 36, 39,
  ]; // participation -     (0+1(*****)/2)
  const inGroup_consciousness_domination_PLUS_index = [0, 2, 7, 13]; // domination +
  const inGroup_consciousness_domination_MINUS_index = [
    19, 25, 29, 30, 33, 34, 37, 38,
  ]; // domination -     (0+1(*****)/2)
  // Indexs
  const inGroup_consciousness_indexs = [
    inGroup_consciousness_logic_PLUS_index,
    inGroup_consciousness_logic_MINUS_index,
    inGroup_consciousness_vitality_PLUS_index,
    inGroup_consciousness_vitality_MINUS_index,
    inGroup_consciousness_ambition_PLUS_index,
    inGroup_consciousness_ambition_MINUS_index,
    inGroup_consciousness_cooperation_PLUS_index,
    inGroup_consciousness_cooperation_MINUS_index,
    inGroup_consciousness_participation_PLUS_index,
    inGroup_consciousness_participation_MINUS_index,
    inGroup_consciousness_domination_PLUS_index,
    inGroup_consciousness_domination_MINUS_index,
  ];

  let inGroup_consciousness_surplusValues = [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ];
  f_numberIndexInFreqyuency_PLUS_MINUS(
    inGroup_consciousness_indexs,
    inGroup_consciousness_surplusValues
  );

  const inGroup_consciousness_text = [
    "Logika +",
    "Logika -",
    "Vitalita +",
    "Vitalita -",
    "Ambice +",
    "Ambice -",
    "Kooperace +",
    "Kooperace -",
    "Participace +",
    "Participace -",
    "Dominance +",
    "Dominance -",
  ];

  //user = values / sum(graf 2 surplusValue_sum) * 100
  const inGroup_consciousness_user = [];
  f_userValues(
    inGroup_consciousness_surplusValues,
    surplusValue_sum,
    inGroup_consciousness_user
  );

  // Nomr in %
  const inGroup_consciousness_normProcent = [
    12.2211, 11.248, 20.1551, 7.2746, 11.9368, 5.9896, 12.0533, 6.6898, 13.3662,
    5.8892, 14.942, 6.1794,
  ];

  // users Values
  // let groupConsciousness_ABSUser_NormProcent_sum = 0; // ABS user - norm
  const inGroup_consciousness_behavioralTrend = []; // trend chování - behavioral trend
  const inGroup_consciousness_trendOfExperiencing = []; // trend prožívání - the trend of experiencing

  // groupConsciousness_ABS
  f_ABS_trends_withABS(
    inGroup_consciousness_user,
    inGroup_consciousness_normProcent,
    groupConsciousness_ABS, // graf 2 ABS - groupConsciousness_ABS
    inGroup_consciousness_behavioralTrend,
    inGroup_consciousness_trendOfExperiencing
  );

  // Graf ---------
  const inGroup_consciousness_grafTitle =
    "Kritické myšlení";
  const inGroup_consciousness_grafBottom = ["Co ukazuji navenek", "Co prožívám uvnitř"];
  // graf 5 have static text in OneColorSet_GrafData

  //-----------------------------------------
  // 6 no name
  // index
  const noName2_extra_intro_ExtraAggression_index = [
    23, 24, 25, 26, 27, 42, 43, 50, 51,
  ]; // extra aggression
  const noName2_extra_intro_IntroAggression_index = [
    22, 23, 24, 25, 26, 40, 41, 44, 45, 48, 49,
  ]; // intro aggression
  const noName2_extra_intro_ExtraDistress_index = [
    17, 18, 19, 20, 21, 34, 35, 38, 39, 50, 51,
  ]; // extra úzkost
  const noName2_extra_intro_IntroDistress_index = [
    16, 17, 18, 19, 20, 32, 33, 36, 37, 48, 49,
  ]; // intro úzkost
  //indexs
  const noName2_extra_intro_indexs = [
    noName2_extra_intro_ExtraAggression_index,
    noName2_extra_intro_IntroAggression_index,
    noName2_extra_intro_ExtraDistress_index,
    noName2_extra_intro_IntroDistress_index,
  ];
  // values
  let noName2_extra_intro_Value = [0, 0, 0, 0];
  f_numberIndexInFreqyuency(
    noName2_extra_intro_indexs,
    noName2_extra_intro_Value
  );

  const noName2_extra_intro_text = [
    "Agrese projevovaná",
    "Agrese prožívaná",
    "Úzkost projevovaná",
    "Úzkost prožívaná",
    "Vnější vlivy",
    "Co prožívám uvnitř",
    "Agrese",
    "Úzkost",
  ];
  // user
  const noName2_extra_intro_user = [];
  f_userValues(
    noName2_extra_intro_Value,
    numberWords,
    noName2_extra_intro_user
  ); //  numberWords

  const noName2_extra_intro_ExtraInfluences_user =
    noName2_extra_intro_Value[0] + noName2_extra_intro_Value[2]; // extra vlivy
  const noName2_extra_intro_IntroInfluences_user =
    noName2_extra_intro_Value[1] + noName2_extra_intro_Value[3]; // intro Vlivy
  const noName2_extra_intro_Aggression_user =
    noName2_extra_intro_Value[0] + noName2_extra_intro_Value[1]; // agrese
  const noName2_extra_intro_Distress_user =
    noName2_extra_intro_Value[2] + noName2_extra_intro_Value[3]; // úzkost

  noName2_extra_intro_user.push(
    noName2_extra_intro_ExtraInfluences_user,
    noName2_extra_intro_IntroInfluences_user,
    noName2_extra_intro_Aggression_user,
    noName2_extra_intro_Distress_user
  ); // - push to user

  // Nomr in %
  const noName2_extra_intro_normProcent = [8.0577, 11.5199, 10.7682, 19.3261];
  const noName2_extra_intro_ExtraInfluences_normProcent =
    noName2_extra_intro_normProcent[0] + noName2_extra_intro_normProcent[2]; // extra vlivy
  const noName2_extra_intro_IntroInfluences_normProcent =
    noName2_extra_intro_normProcent[1] + noName2_extra_intro_normProcent[3]; // intro Vlivy
  const noName2_extra_intro_Aggression_normProcent =
    noName2_extra_intro_normProcent[0] + noName2_extra_intro_normProcent[1]; // agrese
  const noName2_extra_intro_Distress_normProcent =
    noName2_extra_intro_normProcent[2] + noName2_extra_intro_normProcent[3]; // úzkost

  noName2_extra_intro_normProcent.push(
    noName2_extra_intro_ExtraInfluences_normProcent,
    noName2_extra_intro_IntroInfluences_normProcent,
    noName2_extra_intro_Aggression_normProcent,
    noName2_extra_intro_Distress_normProcent
  ); // - push to norm

  // Values
  const noName2_extra_intro_disintegrationExternal = []; // dezintegrace vnější
  const noName2_extra_intro_internalDisintegration = []; // dezintegrace vnitřní
  f_ABS_trends_ABSOnly6Values(
    noName2_extra_intro_user,
    noName2_extra_intro_normProcent,
    noName2_extra_intro_disintegrationExternal,
    noName2_extra_intro_internalDisintegration
  );

  // Graf
  const noName2_extra_intro_grafTitle = "Vnější a vnitřní realita prožívání";
  const noName2_extra_intro_grafBottom = [
    "Dezintegrace vnější",
    "Dezintegrace vnitřní",
  ];
  const noName2_extra_meaningsTexts = [
    {
      title: "Agrese projevovaná",
      description: "U zaměstnance se může projevit agresivní postoj vůči nespravedlnosti nebo špatným podmínkám, může vést k sociálním změnám a aktivismu. V některých skupinách může být mírná agresivita vnímána jako součást dynamiky a pomáhá udržovat skupinovou soudržnost a hierarchii."
    },
    {
      title: "Agrese prožívaná",
      description: "Zaměstnanec má tendenci k prožívání agrese na subjektivní úrovni, kdy zažívá frustraci nebo ohrožení. Prožívání agrese často vyvolává fyziologické změny v těle, jako je zvýšení srdeční frekvence, napětí svalů a zvýšení hladiny stresových hormonů."
    },
    {
      title: "Úzkost projevovaná",
      description: "Zaměstnanec má tendenci k projevení situační úzkosti, která je vyvolána specifickými situacemi, jako jsou zkoušky, veřejné vystupování nebo stresující životní události. Obvykle je dočasná a odezní, jakmile situace pominou."
    },
    {
      title: "Úzkost prožívaná",
      description: "Zaměstnanec je zaměřen na pocity úzkosti, které člověk prožívá uvnitř sebe, aniž by je vždy snadno vyjadřoval navenek. Může zahrnovat intenzivní obavy, strach, napětí a neklid, které mohou být obtížné pojmenovat nebo vysvětlit."
    },
    {
      title: "Vnější vlivy",
      description: "Zaměstnanec je zaměřen na vnější vlivy na psychiku, které jsou faktory z prostředí, které mohou ovlivnit duševní zdraví a psychické prožívání jednotlivce. Tyto vlivy mohou být pozitivní i negativní a mohou pocházet z různých zdrojů."
    },
    {
      title: "Vnitřní prožitek",
      description: "Zaměstnanec je zaměřen na vnímání subjektivních pocitů, myšlenek a zkušeností, které jednotlivci zažívají ve svém nitru. Tyto prožitky mohou zahrnovat široké spektrum emocionálních, psychologických a tělesných reakcí a mohou mít významný dopad na duševní zdraví a celkovou pohodu. Vnitřní prožitky jsou také spojeny s vnímáním sebe sama, sebedůvěrou a sebeohodnocením."
    },
    {
      title: "Agrese",
      description: "U zaměstnance se může projevit agresivní chování, které může pomoci jasně stanovit hranice a komunikovat, co je akceptovatelné a co nikoli. To může být důležité pro udržení zdravých vztahů. Agrese je složitý jev, který vyžaduje komplexní přístup pro pochopení a řešení. Některé osobnostní charakteristiky, jako je vysoká míra neuroticismu nebo nízká úroveň empatie, mohou předpovídat agresivní nebo agresivní chování."
    },
    {
      title: "Úzkost",
      description: "Zaměstnanec má tendenci ke komplexním emocionálním reakcím, které se projevují jako pocit napětí, obavy, strachu nebo neklidu. Může být normální reakcí na stresující situace, ale v některých případech se může stát chronickou a výrazně ovlivnit každodenní život. Úzkost může ovlivnit myšlení a vnímání, což může vést k negativním myšlenkám, bezmoci a bezradnosti. Může také vést k obavám z budoucnosti a tendenci se vyhýbat situacím, které způsobují úzkost."
    }
  ];

  // -------------------------------------------------------------------
  // graf 7 - Segmenty vědomí při interakci s okolím - segments of consciousness when interacting with the environment - RED
  const segmentsOfConsciousness_grafTitle =
    "Interakce s okolím - čím se zabývám";
  const segmentsOfConsciousness_text = [
    "Výstup",
    "Zpracování + Ukotvení",
    "Vstup",
  ];
  const segmentsOfConsciousness_grafBottom = [
    "Co prožívám uvnitř",
    "Co ukazuji navenek",
  ];

  // indexs
  const segmentsOfConsciousness_inpit_indexs = [14, 15, 12, 31]; // Vstup
  const segmentsOfConsciousness_processing_indexs = [0, 4, 5, 8]; // Zpracování
  const segmentsOfConsciousness_exit_indexs = [2]; // Výstup

  const segmentsOfConsciousness_indexs = [
    segmentsOfConsciousness_inpit_indexs,
    segmentsOfConsciousness_processing_indexs,
    segmentsOfConsciousness_exit_indexs,
  ];
  // indexs values
  let segmentsOfConsciousness_indexs_values = [0, 0, 0];
  f_numberIndexInFreqyuency(
    segmentsOfConsciousness_indexs,
    segmentsOfConsciousness_indexs_values
  );
  // indexs values sum
  let segmentsOfConsciousness_indexs_values_sum = f_sum(
    segmentsOfConsciousness_indexs_values
  );

  // user
  const segmentsOfConsciousness_user = [];
  f_userValues(
    segmentsOfConsciousness_indexs_values,
    segmentsOfConsciousness_indexs_values_sum,
    segmentsOfConsciousness_user
  );
  // norm in %
  const segmentsOfConsciousness_normProcent = [24.017, 50.41, 25.572];

  // trens + ABS
  const segmentsOfConsciousness_behavioralTrend = []; // trend chování - behavioral trend
  const segmentsOfConsciousness_trendOfExperiencing = []; // trend prožívání - the trend of experiencing
  f_ABS_trends(
    segmentsOfConsciousness_user,
    segmentsOfConsciousness_normProcent,
    segmentsOfConsciousness_behavioralTrend,
    segmentsOfConsciousness_trendOfExperiencing
  );

  const segmentsOfConsciousness_meaningsText = [
    {
      title: "Výstup",
      description: "Zaměstnanec se soustředí na interakci s okolím a má významný psychologický dopad, ovlivňuje nejen kognitivní procesy, ale i emocionální a sociální aspekty života. Aktivní soustředění na interakci s ostatními lidmi podporuje rozvoj mezilidských vztahů. Zlepšuje komunikační dovednosti a empatii, což umožňuje lepší porozumění potřebám a emocím ostatních."
    },
    {
      title: "Zpracování a ukotvení",
      description: "Zaměstnanec vnímá každodenní vystavení mnoha podnětům z okolí, ať už jde o lidi, situace nebo informace. Ale také vnímá tyto podněty a ovlivňují jeho názory, postoje a chování. Jak zpracováváme informace, vytváříme asociace a stereotypy na základě zkušeností a naučených vzorců. Tyto asociace mohou ovlivnit naše rozhodování a chování."
    },
    {
      title: "Vstup",
      description: "Zaměstnanec je zaměřen na vstupy, které zahrnují všechny podněty a informace, které člověk přijímá z prostředí, a jejich zpracování může ovlivnit duševní zdraví, emoce a chování. Vstupy od ostatních lidí, jako jsou slova, neverbální signály a chování, mají významný vliv na psychiku."
    }
  ];
  // -------------------------------------------------------------------
  // graf 8 - coolGraf - phaze 1 - YELLOW

  // const coolGraf_text = ["Logika", "Vitalita", "Ambice", "Kooperace", "Participace", "Dominance"]

  const coolGraf_reliably = []; //spolehlivé
  const coolGraf_dependingOnTheConditions = []; //závislé na podmínkách
  let coolGraf_reliably_sum = 0;
  let coolGraf_dependingOnTheConditions_sum = 0;

  for (let i = 0; i < inGroup_consciousness_surplusValues.length; i++) {
    if (i % 2 === 0) {
      coolGraf_reliably.push(inGroup_consciousness_surplusValues[i]);
      coolGraf_reliably_sum += inGroup_consciousness_surplusValues[i]; // sum
    } else {
      coolGraf_dependingOnTheConditions.push(
        inGroup_consciousness_surplusValues[i]
      );
      coolGraf_dependingOnTheConditions_sum +=
        inGroup_consciousness_surplusValues[i]; // sum
    }
  }

  // Blocking
  const coolGraf_blocking = [0, 0, 0, 0, 0, 0]; // blokační
  // const coolGraf_blocking_sum = f_sum(coolGraf_blocking);

  // underBlocking
  const coolGraf_underBlocking = []; // pod blokacní - Helpr
  for (let i = 0; i < coolGraf_blocking.length; i++) {
    coolGraf_underBlocking.push(
      coolGraf_reliably[i] +
        coolGraf_dependingOnTheConditions[i] +
        coolGraf_blocking[i]
    );
  }

  let coolGraf_underBlocking_sum = f_sum(coolGraf_underBlocking); // main sum

  // procent_bottom
  const coolGraf_procent_bottom = [];
  f_userValues(
    coolGraf_underBlocking,
    coolGraf_underBlocking_sum,
    coolGraf_procent_bottom
  );

  // right - procnet
  const coolGraf_sums = [
    coolGraf_reliably_sum,
    coolGraf_dependingOnTheConditions_sum,
    0,
  ]; // TO:DO pořešit
  const coolGraf_right_procent = [];
  f_userValues(
    coolGraf_sums,
    coolGraf_underBlocking_sum,
    coolGraf_right_procent
  );

  // right - norm
  // const coolGraf_right_norm = [55.51, 28.37, 16.12];

  // ---------------------------------------------------
  // graf 8 - coolGraf - phaze 2
  // solving behavior styles - řešící styly chování

  const coolrGraf_text2 = [
    "1 Organizátor",
    "2 Posuzovatel",
    "3 Vůdce",
    "4 Tahoun",
    "5 Demokrat",
    "6 Vizionář",
    "7 Komunikátor",
    "8 Stratég",
    "9 Vykonavatel",
    "10 Velitel",
    "11 Navrhovatel",
    "12 Diplomat",
    "13 Obhajovatel",
    "14 Inovátor",
    "15 Vytrvalec",
    "16 Kazatel",
    "17 Stmelovač",
    "18 Objevitel",
    "19 Mluvčí",
    "20 Přeďělávač",
  ];

  // indexs from coolGraf_reliably (graf 7 - coolGraf - phaze 1)
  const coolrGraf_i1 = [1, 4, 5];
  const coolrGraf_i2 = [0, 1, 5];
  const coolrGraf_i3 = [1, 3, 5];
  const coolrGraf_i4 = [1, 2, 5];
  const coolrGraf_i5 = [0, 1, 4];
  const coolrGraf_i6 = [1, 2, 4];
  const coolrGraf_i7 = [1, 3, 4];
  const coolrGraf_i8 = [0, 1, 3];
  const coolrGraf_i9 = [0, 1, 2];
  const coolrGraf_i10 = [1, 2, 3];
  const coolrGraf_i11 = [0, 4, 5];
  const coolrGraf_i12 = [3, 4, 5];
  const coolrGraf_i13 = [2, 4, 5];
  const coolrGraf_i14 = [0, 3, 5];
  const coolrGraf_i15 = [0, 2, 5];
  const coolrGraf_i16 = [2, 3, 5];
  const coolrGraf_i17 = [0, 3, 4];
  const coolrGraf_i18 = [0, 2, 4];
  const coolrGraf_i19 = [2, 3, 4];
  const coolrGraf_i20 = [0, 2, 3];

  const coolrGraf_indexs = [
    coolrGraf_i1,
    coolrGraf_i2,
    coolrGraf_i3,
    coolrGraf_i4,
    coolrGraf_i5,
    coolrGraf_i6,
    coolrGraf_i7,
    coolrGraf_i8,
    coolrGraf_i9,
    coolrGraf_i10,
    coolrGraf_i11,
    coolrGraf_i12,
    coolrGraf_i13,
    coolrGraf_i14,
    coolrGraf_i15,
    coolrGraf_i16,
    coolrGraf_i17,
    coolrGraf_i18,
    coolrGraf_i19,
    coolrGraf_i20,
  ];

  // values
  let coolrGraf_indexs_values = new Array(20).fill(0); // Pole inicializováno nulami
  for (let i = 0; i < coolrGraf_indexs.length; i++) {
    for (let y = 0; y < coolrGraf_indexs[i].length; y++) {
      const index = coolrGraf_indexs[i][y]; // Získání aktuálního indexu z surplusValueIndex - aktuálního pole
      coolrGraf_indexs_values[i] += coolGraf_reliably[index]; // Přičtení hodnoty z pole frequency k odpovídajícímu prvku v surplusValue
    }
  }
  // values sum
  const coolrGraf_indexs_values_sum = f_sum(coolrGraf_indexs_values);

  // In Procent
  const coolGraf_indexs_values_procent = []; // TO:DO pak do grafu a seskladat od nejvetsiho po nejmensi
  f_userValues(
    coolrGraf_indexs_values,
    coolrGraf_indexs_values_sum,
    coolGraf_indexs_values_procent
  );

  // In Procent - diameter (průměr procent)
  // let coolGraf_indexs_values_procent_diameter = f_sum(coolGraf_indexs_values_procent)
  // coolGraf_indexs_values_procent_diameter = coolGraf_indexs_values_procent_diameter / coolGraf_indexs_values_procent.length;

  // GRAF
  const coolGraf_title = "Šnek rolí";
  const coolGraf_title_bottom = "Řešící styly chování";

  const coolGraf_sortedRoleMeaning = [
    {
      role: "Organizátor",
      meaning:
        "Zaměstnanec preferuje dynamické a rychlé aktivity, rád organizuje ostatní osoby a aktivity.",
    },
    {
      role: "Posuzovatel",
      meaning:
        "Zaměstnanec rád nastavuje a prosazuje pravidla a rozhodovací kritéria.",
    },
    {
      role: "Vůdce",
      meaning: "Zaměstnanec rád vede skupinu s vysokou mírou osobního vlivu.",
    },
    {
      role: "Tahoun",
      meaning:
        "Zaměstnanec preferuje rychlé, automatizované postupy s důrazem na výkonnost a svým příkladem táhne skupinu k úspěšnému cíli.",
    },
    {
      role: "Demokrat",
      meaning:
        "Zaměstnanec potřebuje být součástí skupiny a hledá v ní přijatelné kompromisy.",
    },
    {
      role: "Vizionář",
      meaning:
        "Zaměstnanec spojuje i zdánlivě nesouvisející informace a je schopen vidět vzdálený cíl.",
    },
    {
      role: "Komunikátor",
      meaning:
        "Zaměstnanec potřebuje stálou komunikaci s okolím a přátelskou atmosféru.",
    },
    {
      role: "Stratég",
      meaning:
        "Zaměstnanec se cítí dobře v prostředí logicky nastavené spolupráce vedoucí k jasnému cíli.",
    },
    {
      role: "Vykonavatel",
      meaning:
        "Zaměstnanec potřebuje sebeuplatnění při jasně stanovených pravidlech a postupech.",
    },
    {
      role: "Velitel",
      meaning:
        "Zaměstnanec rád uplatňuje svůj osobní vliv na okolí a snaží se mu vnutit vlastní řešení.",
    },
    {
      role: "Navrhovatel",
      meaning:
        "Zaměstnanec využívá své nápady a vyhovuje mu logický pracovní řád.",
    },
    {
      role: "Diplomat",
      meaning:
        "Zaměstnanec usiluje o nalezení řešení akceptovatelných pro všechny.",
    },
    {
      role: "Obhajovatel",
      meaning:
        "Zaměstnanec uplatňuje svůj osobní vliv v závislosti na ochraňování řešení, postupu či osob ze svého prostředí.",
    },
    {
      role: "Inovátor",
      meaning:
        "Zaměstnanec vyžaduje strukturované, silně provázané a jasné pracovní aktivity, které vedou k dosažení nových cílů.",
    },
    {
      role: "Vytrvalec",
      meaning:
        "Zaměstnanec rád dlouhodobě a důsledně prosazuje jednou dané pracovní postupy.",
    },
    {
      role: "Kazatel",
      meaning:
        "Zaměstnanec rád prosazuje svůj vlastní model rozhodování. Dokáže přesvědčit ostatní a získat je na svou stranu.",
    },
    {
      role: "Stmelovač",
      meaning:
        "Zaměstnanec je důležitou osobou pro tým, sleduje dlouhodobý společný cíl a stmeluje kolektiv.",
    },
    {
      role: "Objevitel",
      meaning:
        "Zaměstnanec objevuje nové možnosti a hledá v nich své uplatnění.",
    },
    {
      role: "Mluvčí",
      meaning:
        "Zaměstnanec rád aktivně zveřejňuje společné cíle skupiny a je u toho rád viděn.",
    },
    {
      role: "Předělávač",
      meaning:
        "Zaměstnanec se svým působením snaží měnit formy spolupráce ve svém okolí.",
    },
  ];

    // ----------------------------------------------------------------

   // Graf 9 - Rizikové Jevy - risk phenomena 

   // statistick Data
   // Hraju automaty
   const g9_riskPhenomena_data1 = [
    3726, 3543, 7616, 4714, 3360, 2856, 8632, 2579, 2384, 8020, 
    2637, 1304, 1609, 7150, 1923, 2973, 6397, 1286, 1351, 1230, 
    1596, 2971, 5245, 3018, 1791, 2301, 1642, 8241, 2757, 2446, 
    2348, 2768, 5830, 1285, 1493, 2268, 3562, 9630, 2449, 3136, 
    5464, 1658, 3002, 5760, 10073, 10004, 11133, 2703, 27644, 
    10411, 13466, 7734, 5177, 6818, 15023, 108675
  ];
  // Beru drogy
  const g9_riskPhenomena_data2 = [
    2495, 2838, 7037, 2900, 2269, 1904, 6575, 1894, 2086, 6575, 
    1728, 852, 1175, 5603, 1785, 2429, 5182, 927, 1089, 1043, 
    1583, 2662, 5278, 2617, 1428, 2269, 1584, 7249, 1958, 2018, 
    1883, 2319, 4763, 1075, 1226, 2124, 2249, 8306, 1916, 2835, 
    4985, 1720, 2977, 5049, 10550, 10524, 10612, 2247, 30083, 
    11595, 14643, 7801, 4435, 6702, 16192, 130928
  ];
  // Kouřím Cigarety
  const g9_riskPhenomena_data3 = [
    3784, 3929, 10625, 5021, 3786, 2798, 7786, 2249, 2796, 10868, 
    2157, 1276, 1474, 5156, 1808, 3311, 5058, 1396, 1380, 1254, 
    1868, 4399, 5239, 3288, 1817, 2611, 1887, 9547, 2441, 1965, 
    2011, 2183, 4989, 1160, 1488, 2259, 2889, 7097, 2760, 3867, 
    4515, 1917, 2906, 5170, 9200, 9346, 10981, 3108, 28065, 11140, 
    17521, 9743, 4628, 6206, 15072, 127144
  ];
  // Kradu
  const g9_riskPhenomena_data4 = [
    2515, 2773, 6144, 3202, 2494, 2035, 8261, 1675, 2011, 6796, 
    2087, 1061, 1190, 5160, 1738, 2766, 6844, 1017, 1236, 993, 
    1529, 3004, 5265, 2880, 1577, 2025, 1469, 7747, 2279, 2233, 
    2024, 2310, 6440, 1160, 1272, 2318, 3013, 8869, 2314, 3080, 
    5101, 1542, 3145, 5521, 11174, 11504, 12456, 2588, 27809, 
    10917, 15009, 7788, 5100, 7085, 15468, 127465
  ];
  //Podvádím
  const g9_riskPhenomena_data5 = [
    4670, 4462, 9532, 5119, 4699, 3686, 11684, 3338, 3323, 9771, 
    3479, 1949, 2222, 6700, 2550, 3767, 7181, 2012, 2013, 1875, 
    2407, 4716, 4963, 3856, 2290, 3157, 2207, 10599, 3773, 3003, 
    3209, 3078, 7741, 1892, 2367, 3067, 4651, 9473, 3573, 4293, 
    5415, 2355, 4057, 7695, 9053, 9790, 12256, 3596, 20729, 9559, 
    15900, 8418, 6055, 6940, 14045, 86539
  ]
  // Zneužívám
  const g9_riskPhenomena_data6 = [
    749, 860, 1704, 675, 1168, 934, 1643, 651, 703, 1441, 
    810, 391, 451, 1785, 654, 1009, 1613, 443, 370, 422, 
    573, 1000, 825, 967, 316, 608, 352, 2022, 1042, 769, 
    865, 681, 2385, 380, 580, 745, 1410, 2625, 1104, 1255, 
    2017, 518, 1436, 1288, 2043, 2112, 3751, 628, 7576, 2042, 
    5732, 2396, 1820, 1922, 5268, 22038
  ];
  // Piju Alkohol
  const g9_riskPhenomena_data7 = [
    8464, 8067, 17772, 9380, 7540, 5903, 11045, 5315, 5467, 15069, 
    4474, 2540, 2641, 8419, 3749, 5116, 7867, 2913, 2639, 2728, 
    3535, 6288, 6157, 4616, 2612, 4012, 2741, 11263, 3978, 3080, 
    3392, 2808, 6737, 1999, 2650, 2902, 4616, 8746, 3522, 4533, 
    4375, 2442, 3316, 5762, 7702, 7744, 9537, 3368, 19576, 9511, 
    12209, 7429, 4951, 5094, 9915, 74054
  ];

  // Frequenci - Dynamické převedení 2D pole na 1D pole s délkami
  const frequency_finalNumbers = frequency.map(innerArray => innerArray.length);

  //test
  // const frequency_finalNumbers = [
  //   3, 16, 7, 8, 1, 4, 2, 1, 3, 3, 1, 0, 3, 1, 4, 1, 
  //   0, 0, 1, 0, 0, 1, 1, 2, 2, 2, 1, 0, 1, 1, 2, 0, 
  //   0, 0, 0, 2, 0, 1, 1, 0, 4, 1, 3, 2, 3, 2, 0, 0, 
  //   1, 0, 0, 0, 2, 3, 2, 3
  // ];

  // Funkce pro výpočet průměru
function mean(array) {
  return array.reduce((sum, value) => sum + value, 0) / array.length;
}

// Funkce pro výpočet korelace
function pearsonCorrelation(data, frequency) {
  const meanData = mean(data);
  const meanFrequency = mean(frequency);

  let numerator = 0; // Čitatel
  let denominatorData = 0; // Jmenovatel (část pro data)
  let denominatorFrequency = 0; // Jmenovatel (část pro frekvence)

  for (let i = 0; i < data.length; i++) {
    const dataDeviation = data[i] - meanData; // Odchylka od průměru pro data
    const frequencyDeviation = frequency[i] - meanFrequency; // Odchylka od průměru pro frekvence

    numerator += dataDeviation * frequencyDeviation; // Součin odchylek
    denominatorData += dataDeviation ** 2; // Druhá mocnina odchylky dat
    denominatorFrequency += frequencyDeviation ** 2; // Druhá mocnina odchylky frekvencí
  }

  const denominator = Math.sqrt(denominatorData * denominatorFrequency);
  return numerator / denominator;
}


const g9_riskPhenomena_correlation1 = (pearsonCorrelation(g9_riskPhenomena_data1, frequency_finalNumbers) * 140);
const g9_riskPhenomena_correlation2 = (pearsonCorrelation(g9_riskPhenomena_data2, frequency_finalNumbers) * 140);
const g9_riskPhenomena_correlation3 = (pearsonCorrelation(g9_riskPhenomena_data3, frequency_finalNumbers) * 140);
const g9_riskPhenomena_correlation4 = (pearsonCorrelation(g9_riskPhenomena_data4, frequency_finalNumbers) * 140);
const g9_riskPhenomena_correlation5 = (pearsonCorrelation(g9_riskPhenomena_data5, frequency_finalNumbers) * 140);
const g9_riskPhenomena_correlation6 = (pearsonCorrelation(g9_riskPhenomena_data6, frequency_finalNumbers) * 140);
const g9_riskPhenomena_correlation7 = (pearsonCorrelation(g9_riskPhenomena_data7, frequency_finalNumbers) * 140);

const g9_riskPhenomena_finalCorrelation = [
  g9_riskPhenomena_correlation1, 
  g9_riskPhenomena_correlation2, 
  g9_riskPhenomena_correlation3, 
  g9_riskPhenomena_correlation4, 
  g9_riskPhenomena_correlation5, 
  g9_riskPhenomena_correlation6, 
  g9_riskPhenomena_correlation7]

  //texts
  const g9_riskPhenomena_text = ["Hraju automaty","Beru drogy","Kouřím cigarety","Kradu","Podvádím","Zneužívám","Piju alkohol"];
  const g9_riskPhenomena_title = "Rizikové jevy";

  // ----------------------------------------------------------------
  return (
    <div className="GrafData_DataForGraf">
      <div className="line">
        <GrafRadar
          id={`${id}_8`}
          // name={name}
          text={coolrGraf_text2}
          grafTitle={coolGraf_title}
          grafTitle_bottom={coolGraf_title_bottom}
          data={coolGraf_indexs_values_procent}
          meaningsText={coolGraf_sortedRoleMeaning}
          setTopThreeMeanings={setTopThreeMeanings}
          topThreeMeanings={topThreeMeanings}
        />
      </div>
      <div className="line">
        {/* 1 */}
        <GrafVertical
          id={`${id}_1`}
          // name={name}
          text={wordsColor_GroupC_graf_text}
          grafTitle={wordsColor_GroupC_graf_title}
          grafTitle_bottom={wordsColor_GroupC_graf_titleBottom}
          data={wordsColor_GroupC_behavioralTrend}
          data2={wordsColor_GroupC_trendOfExperiencing}
          graf_height="500"
          meaningsText={wordsColor__meaningText}
          number_meaningsText={1}
          setMeanings={setMeanings1}
          meanings={meanings1}
        />
      </div>
      <div className="line">
        {/* 2 */}
        <GrafVertical
          id={`${id}_2`}
          // name={name}
          text={groupConsciousness_text}
          grafTitle={groupConsciousness_graf_title}
          grafTitle_bottom={groupConsciousness_graf_titleBottom}
          data={groupConsciousness_trendOfExperiencing}
          graf_height="500"
          meaningsText={groupConsciousness_meaningText}
          number_meaningsText={1}
          setMeanings={setMeanings2}
          meanings={meanings2}
        />
      </div>
      <div className="line">
        {/* 3 */}
        <GrafVertical
          id={`${id}_3`}
          // name={name}
          text={processingReality_text}
          grafTitle={processingReality_graf_title}
          grafTitle_bottom={processingReality_graf_titleBottom}
          data={processingReality_internalTrends}
          graf_height="500"
          meaningsText={processingReality_graf_meanings_texts}
          number_meaningsText={1}
          setMeanings={setMeanings3}
          meanings={meanings3}

        />
        
      </div>
      <div className="line">
        {/* 4 - systém */}
        <GrafVertical
          id={`${id}_4`}
          // name={name}
          text={system_text}
          grafTitle={system_graf_title}
          grafTitle_bottom={system_graf_titleBottom}
          data={system_behavioral_trend}
          data2={system_trendOfExperiencing}
          graf_height="500"
          meaningsText={system_graf_meaningsTexts}
          number_meaningsText={1}
          setMeanings={setMeanings4}
          meanings={meanings4}
        />
      </div>
      <div className="line">
        {/* 5 - Na skupinové vědomí */}
        <GrafVertical
          id={`${id}_5`}
          // name={name}
          text={inGroup_consciousness_text}
          grafTitle={inGroup_consciousness_grafTitle}
          grafTitle_bottom={inGroup_consciousness_grafBottom}
          data={inGroup_consciousness_behavioralTrend}
          data2={inGroup_consciousness_trendOfExperiencing}
          graf_height="1500"
          staticTexts={inGroup_consciousness_staticTexty}
        />
      </div>
      <div className="line">
        {/* 6 - systém */}
        <GrafVertical
          id={`${id}_6`}
          // name={name}
          text={noName2_extra_intro_text}
          grafTitle={noName2_extra_intro_grafTitle}
          grafTitle_bottom={noName2_extra_intro_grafBottom}
          data={noName2_extra_intro_disintegrationExternal}
          data2={noName2_extra_intro_internalDisintegration}
          graf_height="1100"
          meaningsText={noName2_extra_meaningsTexts}
          number_meaningsText={1}
          setMeanings={setMeanings6}
          meanings={meanings6}
        />
      </div>
      <div className="line">
        <GrafVertical
          id={`${id}_7`}
          // name={name}
          text={segmentsOfConsciousness_text}
          grafTitle={segmentsOfConsciousness_grafTitle}
          grafTitle_bottom={segmentsOfConsciousness_grafBottom}
          data={segmentsOfConsciousness_behavioralTrend}
          data2={segmentsOfConsciousness_trendOfExperiencing}
          graf_height="500"
          meaningsText={segmentsOfConsciousness_meaningsText}
          number_meaningsText={1}
          setMeanings={setMeanings7}
          meanings={meanings7}
        />
      </div>
      <div className="line">
        <GrafVertical
          id={`${id}_9`}
          // name={name}
          text={g9_riskPhenomena_text}
          grafTitle={g9_riskPhenomena_title}
          grafTitle_bottom={[g9_riskPhenomena_title]}
          data={g9_riskPhenomena_finalCorrelation}
          graf_height="500"
          // meaningsText={segmentsOfConsciousness_meaningsText}
          // number_meaningsText={1}
          // setMeanings={setMeanings7}
          // meanings={meanings7}
        />
      </div>
      
    </div>
  );
};

export default GrafData_DataForGraf;
