import "./scss/WordSet.scss";
import { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../global/GlobalContext";
// import CompleteWordSet from "../components/completeComponents/CompleteWordSet/CompleteWordSet";
import Search from "../components/elements/Search/Search";
import route_post_universal from "../routes/global/route_post_universal";
import WordSetResult from "../components/pages/admin_pages/WordSet/WordSet_Result";

const WordSet = () => {
  const { urlServer, setError} = useContext(GlobalContext);
  const [searcher, setSearcher] = useState("");
  const [fetch_result, setFetch_result] = useState([]);

  console.log(fetch_result)

  // --------------
  // hooks
  // find WordSets
  useEffect(() => {
    setFetch_result([]);
    if(fetch_result.length > 0){
      return;
    }

    const fetch = async () => {
      const result = await route_post_universal(
        urlServer, 
        "/findWordSets", 
      )
      if(result.fetch_data.length > 0){
        setFetch_result(result.fetch_data)
      } 
    }

    fetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[searcher])

  return (
    <div className="WordSet">
      <div className="title">
        <h1>Sety Slov</h1>
      </div>
      <div className="search">
        <Search
          urlServer={urlServer}
          inputType="text"
          inputLabel="Jméno setu"
          inputID="basicWordSet"
          inputRegex="word"
          wordResultKey="name"
          state={searcher}
          setState={setSearcher}
          urlFetch="/findWordSets"
          fetch_result={fetch_result}
          setFetch_result={setFetch_result}
          setError={setError}
        />
      </div>

      <div className="result">
        {
          fetch_result.length > 0 && <WordSetResult fetch_result={fetch_result} searcher={searcher} />
        }
      </div>
      
      {/* <CompleteWordSet
        // Searcher
        title="Hlavní Slovní modul"
        inputType="text"
        inputID="basicWordSet"
        inputLabel="Jméno setu"
        inputRegex="word"
        // fetch Set
        wordResultKey="name"
        wordResultKey2="wordSet"
        wordResultKey3="language"
        urlFetch_find="/findWordSet_basic"
        // btn
        // btnLabel=""

        // words
        inputID_words="word"
        inputLabel_words="Slovo"
        wordResultKey_words="word"
        wordResultKey2_words="meaning_id"
        // wordResultKey4_words= "word_name"
        // urlFetch_find_words="/findWord"
        urlFetch_findAll_words="/findAlldWords"
        // wordResultKey3_words="language"
        urlFetch_addWords="/addWords_basic"
      /> */}
    </div>
  );
};

export default WordSet;
