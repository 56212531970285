import React from "react";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Box from "@mui/material/Box";

const RadioGender = ({ gender, setGender }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
      <RadioGroup
        row
        value={gender}
        onChange={(e) => setGender(e.target.value)}
        aria-label="gender"
        name="gender-radio-buttons"
      >
        {/* Muž Radio Button s popiskem nad tlačítkem */}
        <Box display="flex" flexDirection="column" alignItems="center" mr={2}>
          <FormControlLabel
            value="0" // Muž bude mít hodnotu 0
            control={
              <Radio
                sx={{
                  color: "#007bff", // Modrá barva
                  "&.Mui-checked": {
                    color: "#007bff",
                  },
                  transform: "scale(1.5)", // Zvětšení velikosti
                }}
              />
            }
            label="Muž"
            labelPlacement="top" // Umístí popisek nad tlačítko
          />
        </Box>

        {/* Žena Radio Button s popiskem nad tlačítkem */}
        <Box display="flex" flexDirection="column" alignItems="center">
          <FormControlLabel
            value="1" // Žena bude mít hodnotu 1
            control={
              <Radio
                sx={{
                  color: "#ff4081", // Červená barva
                  "&.Mui-checked": {
                    color: "#ff4081",
                  },
                  transform: "scale(1.5)", // Zvětšení velikosti
                }}
              />
            }
            label="Žena"
            labelPlacement="top" // Umístí popisek nad tlačítko
          />
        </Box>
      </RadioGroup>
    </Box>
  );
};

export default RadioGender;