import React from 'react'
import "./Number_Attempts.scss";

const NumberAttempts = ({number_attempts}) => {
  return (
    <div className="NumberAttempts">
        <p 
            style={{backgroundColor: number_attempts === 0 ? "red" : "green"}}
            title="Počet zbývajících průchodů"
        >{number_attempts}</p>
    </div>
  )
}

export default NumberAttempts