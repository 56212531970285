
import "./PopUp_Medium.scss";
import BtnClose from "../../btn/BtnClose/BtnClose";
import React from "react";

const PopUpMedium = ({setClose, title, children}) => {
  return (
    <div className="PopUp_Medium">
        <div className="PopUp_window">
            <h1>{title}</h1>
            <div className="PopUp_window_main">
                <BtnClose  setClose={setClose} />
                <div className="PopUp_content">
                  {/* {children} */}
                  {React.cloneElement(children, { setClose })}
                </div>
            </div>
        </div>
    </div>
  )
}

export default PopUpMedium