import { useState, useContext } from "react";
import "./Profession_AddProfession.scss";
import { GlobalContext } from "../../../../../../global/GlobalContext";
import BtnClose from "../../../../../elements/btn/BtnClose/BtnClose";
import InputContainer from "../../../../../elements/inputs/InputContainer/InputContainer";
import route_post_universal from "../../../../../../routes/global/route_post_universal";

const ProfessionAddProfession = ({ setShowAdd, ID, name }) => {
  const { urlServer, setError } = useContext(GlobalContext);
  const [profession, setProfession] = useState("");

  // -------------------------
  //f
  const btnAdd = async () => {
    if (profession) {
      let response = null; // Definujeme response zde, abychom ho mohli použít i v finally

      try {
        response = await route_post_universal(
          urlServer,
          "/add_OneSpecialization_professin",
          "ID",
          ID,
          "profession",
          profession
        );

        if (response.msg === "Profese úspěšně přidány") {
          // Úspěšně přidáno
          setShowAdd(false); // Zavřít okno
        }
      } catch (error) {
        setError("Chyba při přidávání profese");
      } finally {
        setError(
          response ? response.msg : "Akce byla dokončena, ale došlo k chybě."
        );
      }
    }
  };

  // -------------------------

  return (
    <div className="Profession_AddProfession">
      <div className="Profession_relative">
        {/* Btn Close */}
        <BtnClose setClose={setShowAdd} />
        <div className="Profession_main">
          <div className="title">
            <h1>{name} - Přidat profesi</h1>
          </div>
          <div className="add_profession">
            {profession && (
              <button className="normalButton" onClick={btnAdd}>
                Přidat
              </button>
            )}
            <InputContainer
              type="text"
              label="Zadejte mužské profese (lze přidat více oddelené čárkou)"
              id="profession"
              value={profession}
              set={setProfession}
              regexSetting="profession"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfessionAddProfession;
