import React from 'react'
import "./BtnShowColorSet_PopUpColorSet.scss"
import { GrClose } from "react-icons/gr";
import OneColorSetGrafData from '../../../../UsersData_Result/UsersData_Result_GrafData/OneColorSet_GrafData/OneColorSet_GrafData';

const BtnShowColorSet_PopUpColorSet = ({setShow, fetch_data, setFetch_data, name, setFetch_result}) => {

    const btnClose = ()=> {
        setFetch_data({});
        setShow(false);
    }

  return (
    <div className="BtnShowColorSet_PopUpColorSet">
        <div className="BtnShowColorSet_PopUpColorSet_main">
            
            {/* btn close */}
            <button className="btnClose" onClick={btnClose}><GrClose  /></button>

            <section className="BtnShowColorSet_PopUpColorSet_main_response">
                {
                    fetch_data._id && <OneColorSetGrafData colorSet={fetch_data} name={name} setFetch_result={setFetch_result} />
                }
            </section>

        </div>
    </div>
  )
}

export default BtnShowColorSet_PopUpColorSet