import { useState } from "react";
import "./UserData_listFromLast_result.scss";
import { f_date_to_cz } from "../../../../../utils/date/f_date_to_cz";
import BtnClose from "../../../../elements/btn/BtnClose/BtnClose";
import f_initials from "../../../../../utils/nameAndEmail/f_initials";
import TagUsers from "../../../UserData/Tags/Tag_Users/Tag_Users";
import { useOneColorSet } from "../../../../../hooks/colorSet/useOneColorSet";
import BtnShowColorSetPopUpColorSet from "./UserData_listFormLast_result_btnShowColorSet/BtnShowColorSet_PopUpColorSet/BtnShowColorSet_PopUpColorSet";
import { useNavigate } from 'react-router-dom';
import CheckBoxonlyShow from "../../../../elements/checkBox/CheckBox_onlyShow/CheckBox_onlyShow";

const UserData_listFromLast_result = ({ fetch_result, setFetch_result }) => {
  const navigate = useNavigate();
  // show Email
  const [showEmail, setShowEmail] = useState({});
  // tagID
  const [selectedTagID, setSelectedTagID] = useState(null);
  // one Color Set
  const [colorSet_ID, setColorSet_ID] = useState({});

  //------------
  // custom hook

  // colorSet
  const [fetch_data, setFetch_data] = useOneColorSet(colorSet_ID.ID);

  //------------

  // USER - ID
  const findUser = (userID) => {
    navigate(`../users_data/${userID}`);
  };

  // Email Show
  const btn_email = (id) => {
    setShowEmail((prevState) => ({
      ...prevState,
      [id]: !prevState[id], // Přepnutí zobrazení emailu pro konkrétní ID
    }));
  };

  //------------

  return (
    <div className="UserData_listFromLast_result">
      {/* POP UP TagUSers */}
      {selectedTagID?._id && (
        <TagUsers tagData={selectedTagID} setSelectedTagID={setSelectedTagID} />
      )}
      {/* one color Set */}
      {
        colorSet_ID.ID && <BtnShowColorSetPopUpColorSet setShow={setColorSet_ID} fetch_data={fetch_data} setFetch_data={setFetch_data} name={colorSet_ID.name} setFetch_result={setFetch_result} />
      }

      {fetch_result.fetch_data && (
        <div className="colorSets">
          <div className="titles">
            <p>Jméno</p>
            <p>Tag</p>
            <p>Odkonzultováno</p>
            <p>Společnost</p>
            <p>Slovní modul</p>
            <p>Datum vytvoření</p>
            <p>Datum Dokončení</p>
          </div>
          {fetch_result.fetch_data.map((oneColorSet_info) => {
            const {
              _id,
              consultation_complete,
              user,
              tag,
              company,
              date_create,
              date_complete,
              email,
              admin_create,
              wordSet /*, code */,
            } = oneColorSet_info;
            const { userID, userName } = user;
            const { /*admin_ID, */ admin_Name, admin_Color } =
              admin_create || {};
            const { /*companyID, */ companyName } = company;
            const { tag_ID, tag_Name } = tag || {};
            const { /*wordSet_ID, */ wordSet_Name } = wordSet || {};

            return (
              <div key={_id} className="oneColorSet_info">
                <div className="nameEmail">
                  <p className="name" onClick={() => findUser(userID)}>{userName}</p>
                  {/* Tlačítko pro zobrazení emailu */}
                  <button className="email_btn" onClick={() => btn_email(_id)}>E-mail</button>
                  {/* Zobrazení emailu podle stavu pro konkrétní ID */}
                  {showEmail[_id] && (
                    <p className="email" /*onClick={() => btn_email(_id)} */>
                      {email} <BtnClose setClose={() => btn_email(_id)} />
                    </p>
                  )}
                </div>
                <div className="item">
                  <div
                    className="tag_box"
                    style={{ backgroundColor: admin_Color }}
                    onClick={() =>
                      setSelectedTagID({
                        admin_color: admin_Color,
                        admin_name: admin_Name,
                        companyName,
                        date_create,
                        name: tag_Name,
                        _id: tag_ID,
                      })
                    }
                  >
                    <p className="tag_name">{f_initials(admin_Name)}</p>
                    <p className="tag">{tag_Name}</p>
                  </div>
                </div>
                {/* open one color set */}
                <div className="items" onClick={() => setColorSet_ID({ID:_id, name: userName})}>
                <div className="ChackBox_box">
                    {consultation_complete ? (
                      <CheckBoxonlyShow value={consultation_complete} />
                    ) : (
                      <p className="title_chackBox"></p>
                    )}
                  </div>
                  <div className="item">
                    <p>{companyName}</p>
                  </div>
                  <div className="item">
                    <p>{wordSet_Name}</p>
                  </div>
                  <div className="item">
                    <p>{f_date_to_cz(date_create)}</p>
                  </div>
                  <div className="item">
                    <p>{f_date_to_cz(date_complete)}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {}
    </div>
  );
};

export default UserData_listFromLast_result;
