import React from "react";
import NewTag from "./NewTag/NewTag";

const RespondentNew1 = ({
  error,
  setError,
  setPhase,
  selectCompany,
  setSelectCompany,
  wordSet_select,
  setWordSet_select,
  setNewTagShow,
}) => {
  return (
    <div className="NewRespondent">
      <div className="NewRespondent_title">
        <h2>Nový Tag</h2>
      </div>
      <div className="NewRespondent_main">
        <NewTag
          error={error}
          setError={setError}
          setPhase={setPhase}
          selectCompany={selectCompany}
          setSelectCompany={setSelectCompany}
          wordSet_select={wordSet_select}
          setWordSet_select={setWordSet_select}
          setNewTagShow={setNewTagShow}
        />
      </div>
    </div>
  );
};

export default RespondentNew1;
