import { useState } from "react";
import "./Profession_Response.scss";
import ProfessionOneResponse from "./Profession_OneResponse/Profession_OneResponse";

const ProfessionResponse = ({ fetch_result }) => {
  const [oneSpecialization_ID, setOneSpecialization_ID] = useState("");
  console.log(fetch_result)
  return (
    <div className="Profession_Response">
      {/* PoPUp - One Specialization */}
      {oneSpecialization_ID && (
        <ProfessionOneResponse
          oneSpecialization_ID={oneSpecialization_ID}
          setOneSpecialization_ID={setOneSpecialization_ID}
        />
      )}
      
      {/* Fetch Specialization */}
      {fetch_result.map((one) => {
        const { _id, name, profession } = one; // Deconstructing `profession` here
        return (
          <div
            key={_id}
            className="fetch_data_one"
            onClick={() =>
              setOneSpecialization_ID({ ID: _id, name, profession })
            }
          >
            <div className="item">
              <p className="one_title">Obor</p>
              <p className="one_name">{name}</p>
            </div>

            <div>
              {!profession || profession.length === 0 ? (
                <p>...</p>
              ) : (
                <div className="profesion_one_object">
                  {profession.map((one, index) => (
                    <p key={index}>{one}</p>
                  ))}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProfessionResponse;