import React from "react";

const CheckBox = ({value, set, text}) => {
  return (
    <div
      className="CheckBox"
      style={{
        backgroundColor: value ? "#a8e6a3" : "#f8a5a5", // Světle zelená nebo světle červená
        // padding: '10px', // Pro trochu mezery kolem obsahu
        // borderRadius: '5px', // Kulatější rohy pro lepší vzhled
        transition: "background-color 0.3s ease", // Plynulý přechod barev
      }}
    >
      <label>
        {value ? text[0] : text[1]}
        <input
          type="checkbox"
          checked={value}
          onChange={() => set(!value)}
        />
        <span className="checkmark"></span>
      </label>
    </div>
  );
};

export default CheckBox;
