import "./FormData.scss"

const FormData = () => {
  return (
    <div className="FormData">
        Vyberte si
    </div>
  )
}

export default FormData