import { useState } from "react";
import Searcher2MeaningOfWords from "./Searcher2_MeaningOfWords/Searcher2_MeaningOfWords";

const SearcherResult_MeaningOfWords = ({
  urlServer,
  fetch_result,
  wordResultKey,
  wordResultKey2,
  setError,
  error,
  setValue,
  setValue2,
  urlFetch_addArray,
  inputRegex,
  refresh,
  setRefresh,
  //
  btnLabel_AddWord,
  btnLabel_AddMeaningKey,
  inputLabel_AddWord,
  inputLabel_AddMeaningKey,
}) => {
  const [fetchDataSercher2, setFetchDataSercher2] = useState({});

  const btnChoose_mainWord = (value) => {
    setValue(value);
    setValue2("");
  };

  const updateFetchDataSercher2 = (id, data) => {
    setFetchDataSercher2(prevState => ({
      ...prevState,
      [id]: data,
    }));
  };

  return (
    <div className="SearcherResult_MeaningOfWords">
      <p>{error}</p>
      <div className="fetch_results">
        {fetch_result && fetch_result.length > 0 ? (
          fetch_result.map((oneFetch) => {
            const {
              _id,
              [wordResultKey]: result1,
              [wordResultKey2]: result2,
            } = oneFetch;
            return (
              <div key={_id} className="fetch_oneFetch">
                <div className="fetch_oneFetch_box">
                  {/* Title Set */}
                  <button
                    className="fetch_oneFetch_text"
                    onClick={() => btnChoose_mainWord(result1)}
                  >
                    {result1}
                  </button>
                  <div className="Searcher2_box">
                    {/* ADD Meaning Key */}
                    <Searcher2MeaningOfWords
    
                      id={_id}
                      state={result1}
                      setError={setError}
                      urlFetch_addArray="/findWord"
                      setFetch_data_Sercher2={(data) => updateFetchDataSercher2(_id, data)}
                      urlServer={urlServer}
                      wordResultKey="word"
                      wordResultKey2=""
                      refresh={refresh}
                      setRefresh={setRefresh}
                      inputRegex={inputRegex}
                      btnLabel={btnLabel_AddWord}
                      inputLabel_AddWord={inputLabel_AddWord}
                    />
                    {/* TO:DO - udělat přidání */}
                    <Searcher2MeaningOfWords
  
                      id={_id}
                      state={result1}
                      setError={setError}
                      urlFetch_addArray={urlFetch_addArray}
                      urlServer={urlServer}
                      wordResultKey={wordResultKey}
                      wordResultKey2={wordResultKey2}
                      refresh={refresh}
                      setRefresh={setRefresh}
                      inputRegex={inputRegex}
                      btnLabel={btnLabel_AddMeaningKey}
                      inputLabel_AddWord={inputLabel_AddMeaningKey}
                    />
                  </div>
                </div>

                {fetchDataSercher2[_id] && fetchDataSercher2[_id].length > 0 && fetchDataSercher2[_id].map((oneFetch) => {
                  const { _id: wordId, word } = oneFetch;
                  return (
                    <div key={wordId}>
                      <p>{word}</p>
                    </div>
                  );
                })}

                {Array.isArray(result2) && result2.length > 0 && (
                  <div className="array1">
                    {result2.map(
                      (item, index) =>
                        item && (
                          <div className="array2" key={index}>
                            <p>{item}</p>
                          </div>
                        )
                    )}
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <p>{error}</p>
        )}
      </div>
    </div>
  );
};

export default SearcherResult_MeaningOfWords;