import React, { useEffect, useState, useContext } from "react";
import { GlobalContext } from "../../../../../../global/GlobalContext";
import frequency_colorSet from "../../../../../../data/user-data/frequency-colorSet";
import "./OneColorSet_GrafData.scss";
import GrafDataDataForGraf from "./GrafData_DataForGraf/GrafData_DataForGraf";
import languages_name from "../../../../../../data/user-data/language/languages_name";
import route_universal from "../../../../../../routes/global/route_universal";
//
import generatePDF from "../../../../../../utils/PDF/generatePDF";
// utils
import { f_date_to_cz } from "../../../../../../utils/date/f_date_to_cz";
import { f_updateConsultationStatus } from "../../../../../../utils/colorSets/f_updateConsultationStatus";

const OneColorSet_GrafData = ({ colorSet, name, setFetch_result }) => {

  const {urlServer} = useContext(GlobalContext);
  // checkbox
  const [consultation, setConsultation] = useState(colorSet.consultation_complete);
  // Word's color index
  const redWord = [0, 3];
  const blueWord = [1, 2, 4, 5, 6, 7, 10, 11, 17];
  const greenWord = [
    8, 9, 12, 13, 14, 15, 16, 18, 19, 20, 21, 22, 23, 24, 25, 27, 28, 29, 30,
    32, 34, 36, 39,
  ];
  const whiteWord = [
    26, 31, 33, 35, 37, 38, 40, 41, 42, 43, 44, 45, 46, 48, 50,
  ];
  const purpleWord = [47, 49, 51, 52, 53, 54, 55];

  // Response Word's color sets
  const [redResponse, setRedResponse] = useState([]);
  const [blueResponse, setBlueResponse] = useState([]);
  const [greenResponse, setGreenResponse] = useState([]);
  const [whiteResponse, setWhiteResponse] = useState([]);
  const [purpleResponse, setPurpleResponse] = useState([]);

  // Static Texts for wordsColorSet columns
  const wordsColorSet_staticTexts = [
    {
      title: "Ideály",
      description: "Zaměstnanec preferuje hodnoty, které jsou abstraktní představy, které jedinec považuje za dokonalé nebo vysoce žádoucí a které ho motivují k určitému způsobu myšlení, chování a rozhodování. Ideály mohou vycházet z osobních přesvědčení, kulturního a společenského prostředí nebo filozofických a náboženských systémů. Mají hluboký psychologický a sociální význam, protože formují osobní identitu, smysl života a ovlivňují každodenní jednání. Mohou vést k seberealizaci a osobnímu růstu, ale také k frustraci, pokud jsou ideály nedosažitelné."
    },
    {
      title: "Motivace",
      description: "Zaměstnanec preferuje hodnoty, které popisují vnitřní procesy, jež vedou člověka k určitému jednání, rozhodování a dosahování cílů. Motivace odpovídá na otázku, proč jednáme určitým způsobem a co nás k tomu vede. Jde o hnací sílu lidského chování, která je ovlivněna jak vnitřními, tak vnějšími faktory. Motivace je klíčovým faktorem, který určuje směr, intenzitu a trvání lidského chování. Je nezbytná pro osobní i profesní rozvoj a naplnění životních cílů. Rozvoj vnitřní motivace, stejně jako pochopení vnějších faktorů, které ovlivňují naše jednání, může pomoci dosáhnout většího naplnění a spokojenosti v životě."
    },
    {
      title: "Neutralita",
      description: "Zaměstnanec preferuje hodnoty, které se podílejí na každodenním fungování jedince v různých situacích a úkolech. Tento koncept lze chápat jako soubor kognitivních, emočních a behaviorálních procesů, které umožňují člověku zvládat běžné aktivity a reagovat na každodenní výzvy."
    },
    {
      title: "Stresory",
      description: "Zaměstnanec preferuje hodnoty, které vyvolávají stresovou reakci v těle a mysli člověka. V psychologii označují všechny faktory, které přispívají k pocitu stresu, ať už jde o fyzická, psychická nebo emocionální reakce na určité události či podmínky. Stresory jsou nedílnou součástí života každého člověka, ale jejich vliv na jednotlivce se liší v závislosti na subjektivním vnímání a schopnostech zvládání stresu. Pochopení a identifikace stresorů, stejně jako rozvoj vhodných copingových mechanismů, jsou klíčové pro udržení zdravé mysli a těla."
    },
    {
      title: "Destruktory",
      description: "Zaměstnanec preferuje hodnoty, které se vztahují k faktorům, událostem nebo mechanismům, které mohou negativně ovlivnit psychické zdraví jednotlivce, narušit jeho fungování nebo způsobit psychické, emoční nebo behaviorální problémy. Mohou zahrnovat jak vnitřní, tak vnější prvky, které vedou k destruktivnímu chování nebo k duševnímu utrpení. Identifikace a porozumění těmto faktorům mohou pomoci jednotlivcům lépe zvládat stres a zlepšovat své psychické zdraví. Uvědomění si destruktorů a práce na jejich překonání je klíčovým krokem k dosažení psychické pohody a zdravého životního stylu."
    }
  ];

  const [loadingPhase, setLoadingPhase] = useState(0);
  // Frequency user values 
  const [assigningWordToFrequency, setAssigningWordToFrequency] = useState(
    Array(frequency_colorSet.length).fill([]) // Vytvoří pole o určité délce a kažou pozici vyplní prádzným polem = 2D pole
  );
  // const [state_showGrafData, setState_showGrafData] = useState(false);

  // - meanings graf (vertical)
  const [meanings1, setMeanings1] = useState({});
  const [meanings2, setMeanings2] = useState([]);
  const [meanings3, setMeanings3] = useState([]);
  const [meanings4, setMeanings4] = useState([]);
  const [meanings6, setMeanings6] = useState([]);
  const [meanings7, setMeanings7] = useState([]);

  // - static texty graf 5
  const inGroup_consciousness_staticTexty = [
    {
      title: "Všechny kladné typy kritického chování",
      description: "Jsou označeny znaménkem + a ve významu to znamená chování, které zahrnuje aktivity a přístupy, které vedou k pozitivním výsledkům, zlepšení situací nebo k osobnímu rozvoji. Tyto typy chování mohou být aplikovány v různých kontextech, jako jsou mezilidské vztahy, práce, vzdělání a osobní život. Efektivní a otevřená komunikace je klíčová pro konstruktivní interakce. Zahrnuje aktivní naslouchání, vyjadřování myšlenek a pocitů bez agresivity a schopnost vyřešit konflikty prostřednictvím dialogu."
    },
    {
      title: "Všechny záporné typy kritického chování",
      description: "Jsou označeny znaménkem - a ve významu to znamená chování, které se mohou objevit v situacích, kde by normálně byla očekávána konstruktivní interakce, mohou mít negativní dopad na mezilidské vztahy, pracovní prostředí a osobní pohodu. V některých případech mohou být považovány za záporné nebo destruktivní, ale v určitých situacích mají pomáhající aspekty. Konstruktivní kritika nebo skepticism může přispět k analýze a zlepšení procesů. Zdravá míra negativismu může vést k hlubšímu prozkoumání problémů a identifikaci nedostatků."
    }
  ];

  // - meanings graf 8
  const [topThreeMeanings, setTopThreeMeanings] = useState([]);
  // ------------------------------------

  useEffect(() => {
    const arraysEqual = (arr1, arr2) => {
      // Porovnání polí cyklem
      if (arr1.length !== arr2.length) return false; // kontrola délky polí
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false;
      }
      return true;
    };

    const findMatchingColorSets = () => {
      const newAssigningWordToFrequency = Array(frequency_colorSet.length)
        .fill([])
        .map(() => []);

      // Prochází jednotlivé objekty obsahující selectedColors
      colorSet.colorSet_data.forEach((oneColorSet_data) => {
        const selectedColorsSorted = [
          ...oneColorSet_data.selectedColors,
        ].sort(); //  + seřadí je

        // Prochází jednotlivé Frequence
        frequency_colorSet.forEach((freq, index) => {
          const freqSorted = [...freq].sort(); //  + seřadí je

          if (arraysEqual(selectedColorsSorted, freqSorted)) {
            newAssigningWordToFrequency[index].push(oneColorSet_data); // Přiřadíme na odpovídající index
          }
        });
      });

      setAssigningWordToFrequency(newAssigningWordToFrequency);

      // Nastavení loadingPhase na 1 po dokončení celého procesu
      setLoadingPhase(1);
    };

    findMatchingColorSets();
  }, [colorSet]);

  useEffect(() => {
    if (loadingPhase === 1 && purpleResponse.length === 0) {
      assigningWordToFrequency.forEach((oneWord, index) => {
        if (redWord.includes(index)) {
          setRedResponse((prev) => [...prev, ...oneWord]);
        } else if (blueWord.includes(index)) {
          setBlueResponse((prev) => [...prev, ...oneWord]);
        } else if (greenWord.includes(index)) {
          setGreenResponse((prev) => [...prev, ...oneWord]);
        } else if (whiteWord.includes(index)) {
          setWhiteResponse((prev) => [...prev, ...oneWord]);
        } else if (purpleWord.includes(index)) {
          setPurpleResponse((prev) => [...prev, ...oneWord]);
        }
      });
      // Nastavení loadingPhase na 2 po dokončení
      setLoadingPhase(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingPhase]);

  // -----------------------------------------------------------------------

  // Gender
  const genderOptions = ["Muž", "Žena", "Ostatní"];
  let genderText = genderOptions[colorSet.gender];

  // Language
  const languageName = languages_name.find(
    (l) => l.code === colorSet.language
  )?.name;

  // Species
  if (colorSet.species !== null && colorSet.species >= 0) {
    const speciesOptions = ["Mužský", "Ženský"];
    const speciesText = speciesOptions[colorSet.species];
    genderText = `${genderText} - ${speciesText}`;
  }

  // ---------------------------------------------------------------------
  const handleGeneratePDF = () => {
    generatePDF({
      // - graf
      id: colorSet._id,
      // - user
      name,
      email: colorSet.email,
      company: colorSet.company.companyName,
      date: f_date_to_cz(colorSet.date),
      // - 1
      language: languageName,
      years: colorSet.years,
      age: colorSet.age,
      gender: genderText,
      // - 2 country
      country: colorSet.country.countryName,
      region: colorSet.region,
      usaRegion: colorSet?.usaRegion?.usaRegionName || null,
      // - 3
      education: colorSet.education.educationName,
      profession: colorSet.profession.professionName,
      maritalStatus: colorSet.maritalStatus.maritalStatusName,
      personsInTheHousehold: colorSet.personsInTheHousehold,

      // - Color set data
      // frequency_colorSet,
      // assigningWordToFrequency,
      // - Color words
      redResponse,
      blueResponse,
      greenResponse,
      whiteResponse,
      purpleResponse,
      // - graf2 meanings
      meanings2,
      // - graf8 meanings
      topThreeMeanings,
    });
  };

  // Consultation - CheckBox
  const checkBox_consultation = async (id) => {
      // Odeslání PATCH požadavku pomocí route_universal
      console.log(colorSet.consultation_complete)
      const response = await route_universal(
        "PATCH",
        urlServer,
        "/update_checkBox_consultation",
        "colorSet_ID",
        colorSet._id,
        "consultation_complete",
        !consultation // Změna na opačný stav, protože uživatel chce přepnout stav
      );
      
      // Kontrola odpovědi ze serveru
      if (response && response.updatedColorSet !== undefined) {
        console.log("Úspěšně aktualizováno:", response.updatedColorSet);
        // Aktualizace stavu checkboxu podle nového stavu z odpovědi
        setConsultation(response.updatedColorSet.consultation_complete);
        f_updateConsultationStatus(setFetch_result, id, response.updatedColorSet.consultation_complete)
      } else {
        console.error("Chyba při aktualizaci:", response.msg || "Neznámá chyba");
      }
  };

  // ---------------------------------------------------------------------
  return (
    <div className="OneColorSet_GrafData">
      {loadingPhase === 2 && (
        <>
          <div className="colorSetInfo">
            <h4>{name}</h4>
            <p className="email">{colorSet.email}</p>

            {/* Buttons show Graf + Word info */}
            <div className="buttons_box">
              <button className="btn_showGraf" onClick={handleGeneratePDF}>
                DOWNLOAD
              </button>

              <div 
                className="CheckBox" 
                style={{
                  backgroundColor: consultation ? '#a8e6a3' : '#f8a5a5', // Světle zelená nebo světle červená
                  // padding: '10px', // Pro trochu mezery kolem obsahu
                  // borderRadius: '5px', // Kulatější rohy pro lepší vzhled
                  transition: 'background-color 0.3s ease', // Plynulý přechod barev
                }}
              >
                <label>
                
                {consultation ? 'Odkonzultováno' : 'Neodkonzultováno'}
                  <input
                    type="checkbox"
                    checked={consultation}
                    onChange={() => checkBox_consultation(colorSet._id)}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>

            </div>
            {/* ---------------------------------------------------------------------------------------- */}
            <div className="userData"></div>
            {/* Company + Day */}
            <div className="row">
              <div className="item">
                <p className="title_item">Datum Dokončení: </p>
                <p>{f_date_to_cz(colorSet.date)}</p>
              </div>

              <div className="item">
                <p className="title_item">Společnost: </p>
                <p>{colorSet.company.companyName}</p>
              </div>

              <div className="item">
                <p className="title_item">Jazyk: </p>
                <p>{languageName}</p>
              </div>
            </div>

            {/* color set - user Data */}
            {/* Language + age + gender */}
            <div className="row">
              <div className="item">
                <p className="title_item">Rok narození: </p>
                <p>{colorSet.years}</p>
              </div>
              <div className="item">
                <p className="title_item">Věk: </p>
                <p>{colorSet.age}</p>
              </div>
              <div className="item">
                <p className="title_item">Pohlaví: </p>
                <p>{genderText}</p>
              </div>
            </div>

            {/* Country + region */}
            <div className="row">
              <div className="item">
                <p className="title_item">Země: </p>
                <p>{colorSet.country.countryName}</p>
              </div>
              <div className="item">
                <p className="title_item">Region: </p>
                <p>{colorSet.region}</p>
              </div>
              <div className="item">
                {colorSet.usaRegion && (
                  <>
                    <p className="title_item">USA Region: </p>
                    <p>{colorSet.usaRegion.usaRegionName}</p>
                  </>
                )}
              </div>
            </div>

            {/* education + maritalStatus + profession + personsInTheHousehold */}
            <div className="row">
              <div className="item">
                <p className="title_item">Vzdělání: </p>
                <p>{colorSet.education.educationName}</p>
              </div>
              <div className="item">
                <p className="title_item">Rodinný stav: </p>
                <p>{colorSet.maritalStatus.maritalStatusName}</p>
              </div>

              <div className="item">
                <p className="title_item">Osob v domácnosti: </p>
                <p>
                  {colorSet.personsInTheHousehold === 9
                    ? "10+"
                    : colorSet.personsInTheHousehold + 1}
                </p>
              </div>
            </div>

            <div className="row">
            <div className="item">
                <p className="title_item">Obor: </p>
                <p>{colorSet.specialization.specialization_Name}</p>
              </div>
              <div className="item">
                <p className="title_item">Povolání: </p>
                <p>{colorSet.profession.professionName}</p>
              </div>
            </div>

            {/* frist / last color set */}
            {/* <div className="row">
              <div className="item">
                <p className="title_item">První barevný výběr: </p>
                <p>{parseInt(colorSet.fristColorRing)}</p>
              </div>
              <div className="item">
                <p className="title_item">Poslední barevný výběr: </p>
                <p>{parseInt(colorSet.lastColorRing)}</p>
              </div>
            </div> */}
          </div>

          {/* <div className="data_forGraf">

            <div className="frequency_box">
              <h3 className="OneColorSet_GrafData_title">Trojce</h3>
              {frequency_colorSet.map((oneFreq, index) => (
                <div key={index} className="frequency_group">
                  <p>
                    {oneFreq[0]}
                    {oneFreq[1]}
                    {oneFreq[2]}
                    {index}
                  </p>
                  <p className="text">
                    : {assigningWordToFrequency[index].length}
                  </p>
                </div> 
            ))} 
             </div>  

            
          </div> */}

          {/* Graf Data */}
          {
            <GrafDataDataForGraf
              frequency={assigningWordToFrequency}
              // name={name}
              redResponse_userResult={
                (redResponse.length / colorSet.colorSet_data.length) * 100
              }
              blueResponse_userResult={
                (blueResponse.length / colorSet.colorSet_data.length) * 100
              }
              greenResponse_userResult={
                (greenResponse.length / colorSet.colorSet_data.length) * 100
              }
              whiteResponse_userResult={
                (whiteResponse.length / colorSet.colorSet_data.length) * 100
              }
              purpleResponse_userResult={
                (purpleResponse.length / colorSet.colorSet_data.length) * 100
              }
              id={colorSet._id}
              numberWords={colorSet.colorSet_data.length}
              // meanings - graf 1
              meanings1={meanings1}
              setMeanings1={setMeanings1}
              // meanings - graf 2
              meanings2={meanings2}
              setMeanings2={setMeanings2}
              // meanings - graf 3
              meanings3={meanings3}
              setMeanings3={setMeanings3}
              // meanings - graf 4
              meanings4={meanings4}
              setMeanings4={setMeanings4}
              // static texts - graf 5
              inGroup_consciousness_staticTexty={inGroup_consciousness_staticTexty}
              // meanings - graf 6
              meanings6={meanings6}
              setMeanings6={setMeanings6}
               // meanings - graf 7
               meanings7={meanings7}
               setMeanings7={setMeanings7}
              // meanings - graf 8
              topThreeMeanings={topThreeMeanings}
              setTopThreeMeanings={setTopThreeMeanings}
            />
          }

          {/* word's Color */}
          <h4 className="word_schema">Slovní schéma</h4>
          <div className="words_color_res">
            
              {loadingPhase === 2 && (
                <>
                  <div className="Ideály wordsColorSetRes">
                    <h3 className="OneColorSet_GrafData_title">Ideály</h3>
                    {redResponse.map((item, index) => (
                      <div key={index}>
                        <p>{item.word.word_name[1] ? `${item.word.word_name[0]} - ${item.word.word_name[1]}` : item.word.word_name[0]}</p>
                      </div>
                    ))}
                  </div>
                  <div className="Motivace wordsColorSetRes">
                    <h3 className="OneColorSet_GrafData_title">Motivace</h3>
                    {blueResponse.map((item, index) => (
                      <div key={index}>
                        <p>{item.word.word_name[1] ? `${item.word.word_name[0]} - ${item.word.word_name[1]}` : item.word.word_name[0]}</p>
                      </div>
                    ))}
                  </div>
                  <div className="Neutralita wordsColorSetRes">
                    <h3 className="OneColorSet_GrafData_title">Neutralita</h3>
                    {greenResponse.map((item, index) => (
                      <div key={index}>
                        <p>{item.word.word_name[1] ? `${item.word.word_name[0]} - ${item.word.word_name[1]}` : item.word.word_name[0]}</p>
                      </div>
                    ))}
                  </div>
                  <div className="Stresory wordsColorSetRes">
                    <h3 className="OneColorSet_GrafData_title">Stresory</h3>
                    {whiteResponse.map((item, index) => (
                      <div key={index}>
                        <p>{item.word.word_name[1] ? `${item.word.word_name[0]} - ${item.word.word_name[1]}` : item.word.word_name[0]}</p>
                      </div>
                    ))}
                  </div>
                  <div className="Destruktory wordsColorSetRes">
                    <h3 className="OneColorSet_GrafData_title">Destruktory</h3>
                    {purpleResponse.map((item, index) => (
                      <div key={index}>
                        <p>{item.word.word_name[1] ? `${item.word.word_name[0]} - ${item.word.word_name[1]}` : item.word.word_name[0]}</p>
                      </div>
                    ))}
                  </div>
                </>
              )}
          </div>
          
          {/* Static texts for WordSet columns */}
          <div className="wordSet_columns">
            {
              wordsColorSet_staticTexts.map((oneText, index) => {
                const { title, description } = oneText;
                return <div key={index} className={`WordSet_columns_oneText ${title}`}>
                  <p className="oneText_title">{title}</p>
                  <p className="oneText_description">{description}</p>
                </div>
              })
            }
          </div>
        </>
      )}
    </div>
  );
};

export default OneColorSet_GrafData;
