import { useState } from "react";
import "./Profession_OneResponse.scss";
import BtnClose from "../../../../elements/btn/BtnClose/BtnClose";
import ProfessionAddProfession from "./Profession_AddProfession/Profession_AddProfession";

const ProfessionOneResponse = ({
  oneSpecialization_ID,
  setOneSpecialization_ID,
}) => {
  const {ID, name, profession} = oneSpecialization_ID;
  const [showAdd, setShowAdd] = useState(false);

  return (
    <div className="Profession_OneResponse">
      
      <div className="Profession_OneResponse_relative">
        {/* BTN Close */}
        <BtnClose setClose={setOneSpecialization_ID} />
        {/* PopUp Add */}
        {
          showAdd && <ProfessionAddProfession setShowAdd={setShowAdd} ID={ID} name={name} />
        }
        <div className="title">
          <h1>{name}</h1>
        </div>
        <div className="Profession_OneResponse_main">
        <button className="normalButton" onClick={() => setShowAdd(true)}>
            Přidat profesi/e
        </button>
          {!profession || profession.length === 0 ? (
            <p>...</p>
          ) : (
            profession.map((one, index) => {
              return (
                <div key={index} className="profesion_one_object">
                  <p>{one}</p>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfessionOneResponse;
