import { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../../../../global/GlobalContext";
import "./WordSet_Result.scss";
import route_post_universal from "../../../../routes/global/route_post_universal";
import route_put_universal from "../../../../routes/global/route_put_universal";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const WordSetResult = ({ fetch_result, searcher }) => {
  const { urlServer } = useContext(GlobalContext);
  const [one_WordSet, setOne_WordSet] = useState("");
  const [fetch_data, setFetch_data] = useState({});
  const [dragItem_true, setDragItem_true] = useState(false)

  console.log(fetch_data);
  // --------------
  // hook
  // Clear if search change
  useEffect(() => {
    setFetch_data({});
    setDragItem_true(false)
  }, [searcher]);

  // Find one wordSet data
  useEffect(() => {
    if (!one_WordSet) {
      return;
    }

    const fetch = async () => {
      const result = await route_post_universal(
        urlServer,
        "/findWordSet_basic",
        "id",
        one_WordSet
      );
      if (result.fetch_data._id) {
        setFetch_data(result.fetch_data);
      }
    };

    fetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [one_WordSet]);

  // ----------
  // f
  const f_OnDragEnd = (result) => {
    if (!result.destination) return;

    setDragItem_true(true) // is change

    const items = Array.from(fetch_data.wordSet);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setFetch_data({
      ...fetch_data,
      wordSet: items
    });
  };

  // Save
  const btn_save = async () => {
    if (fetch_data?._id) {
        // Odeslání požadavku na server
        const response = await route_put_universal(
          urlServer,
          "/editWordSet_basic",
          "id",
          fetch_data._id,
          "wordSet",
          fetch_data.wordSet
        );
    
        // Kontrola, zda byl request úspěšný a data byla aktualizována
        if (response && response.updatedSet) {
          // Aktualizace stavu fetch_data s novými daty z backendu
          setFetch_data(response.updatedSet);
    
          // Nastavení indikace, že byly změny uloženy
          setDragItem_true(false); // Změny byly uloženy, takže už nejsou neuložené změny
        } else {
          console.error("Nastala chyba při ukládání dat.");
        }
      }
  }
  // --------------

  return (
    <div className="WordSet_Result">
      {/* Name Sets */}
      {
        !fetch_data._id && <div className="fetch_data">
        {fetch_result.map((oneSet) => {
          const { value, label } = oneSet;
          return (
            <div
              key={value}
              className="fetch_data_one"
              onClick={() => setOne_WordSet(value)}
            >
              <div className="item">
                <p className="one_title">Jméno setu</p>
                <p className="one_name">{label}</p>
              </div>
            </div>
          );
        })}
      </div>
      }
      

      {/* One Set */}
      {fetch_data._id && (
        <DragDropContext onDragEnd={f_OnDragEnd}>
          <Droppable droppableId="wordSet">
            {(provided) => (
              <div className="wordsInSet" {...provided.droppableProps} ref={provided.innerRef}>

                {/* save changes */}
                <div className="btn_save">
                   {
                    dragItem_true && <button className="normalButton" onClick={btn_save}>Uložit změny</button>
                    
                   } 
                </div>

                {fetch_data.wordSet.map((oneWord, index) => {
                  const { word, _id } = oneWord;
                  return (
                    <Draggable key={_id} draggableId={_id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="draggable-word"
                        >
                          {word.word_name[1]
                            ? `${word.word_name[0]} - ${word.word_name[1]}`
                            : word.word_name[0]}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
};

export default WordSetResult;
