import "./Country.scss";
import CompleteFetchUserForm from "../../../components/completeComponents/CompleteFetchUserForm";

const Country = () => {
  return (
    <div className="Country">
      <CompleteFetchUserForm
        title="Země"
        inputType="text"
        inputID="findCountry"
        inputLabel="Najdi Zemi, přidej zemi (lze přidat více zemí oddělených čárkou)"
        inputRegex="country"
        //Key word
        wordResultKey="country"
        wordResultKey2="region"
        //Fetch
        urlFetch_find="/findCountry"
        urlFetch_add="/addCountry"
        urlFetch_addArray="/addRegion"
        //btn
        btnLabel="Region"
      />
    </div>
  );
};

export default Country;
