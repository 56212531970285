import { useState, useContext } from "react";
import { GlobalContext } from "../../../../GlobalContext";
import "./Registration_window.scss";
import InputContainer from "../../../../../components/elements/inputs/InputContainer/InputContainer";
import InputPassword from "../../../../../components/elements/inputs/InputPassword/InputPassword";
import SelectBasic from "../../../../../components/elements/selects/SelectBasic/SelectBasic";
import BtnRegistration from "./BtnRegistration/BtnRegistration";
import BtnClose from "../../../../../components/elements/btn/BtnClose/BtnClose";
import InputColor from "../../../../../components/elements/inputs/InputColor/InputColor";
import rank_data from "../../../../../data/user-data/rank_data";
import RadioGende from "../../../../../components/elements/radio/Radio_Gender/Radio_Gender";
import SelectPhonePrefixes from "../../../../../components/elements/selects/Select_PhonePrefixes/Select_PhonePrefixes";

const Registration_window = ({ setRegistration }) => {
  const { rank } = useContext(GlobalContext);

  const [gender, setGender] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [countryPhone_select, setCountryPhone_select] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [rankReg, setRankReg] = useState(rank + 1);
  const [passwordParent, setPasswordParrent] = useState("");
  // color
  const [showColor, setShowColor] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  // error
  const [error, setError] = useState("");

  // -----------------------------------------

  return (
    <div className="Registration_window">
      <section className="form">
        <BtnClose setClose={setRegistration} />
        <div className="title">
          <h1>Registrace</h1>
        </div>

        <div className="input_box">
          <h2>Nový uživatel</h2>
          <InputContainer
            type="text"
            label="Jméno"
            id="name"
            value={name}
            set={setName}
            regexSetting="name"
          />
          <InputContainer
            type="text"
            label="Přijmení"
            id="surname"
            value={surname}
            set={setSurname}
            regexSetting="name"
          />
          <InputContainer
            type="email"
            label="E-mail"
            id="email"
            value={email}
            set={setEmail}
            regexSetting="email"
          />

          {/* Phone */}
          <SelectPhonePrefixes set={setCountryPhone_select} />
          <InputContainer
            type="tel"
            label="Telefon"
            id="phone"
            value={phone}
            set={setPhone}
            regexSetting="phone"
          />

          {/* Gender */}
          <RadioGende gender={gender} setGender={setGender} />

          {/* Password */}
          <InputPassword
            label="Heslo"
            id="password"
            value={password}
            set={setPassword}
            regexSetting="password"
          />
          <InputPassword
            label="Heslo znovu"
            id="password2"
            value={password2}
            set={setPassword2}
            regexSetting="password"
          />

          <div className="parent"></div>
          <h2>Nadřazený uživatel</h2>

          <SelectBasic
            object={rank_data}
            onChange={setRankReg}
            placeholder="Vyberte pozici nového uživatele"
          />
          <div
            className="button_box btn_colorSelect"
            style={{ backgroundColor: selectedColor }}
          >
            <button onClick={() => setShowColor(true)}>Vybrat barvu</button>
          </div>

          <InputContainer
            type="password"
            label="Heslo nadřazeného uživatele"
            id="passwordParent"
            value={passwordParent}
            set={setPasswordParrent}
            regexSetting="email"
          />

          {showColor && (
            <InputColor
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
              setShowColor={setShowColor}
            />
          )}
        </div>

        <div
          className="error_box"
          style={{ color: error === "Nový admin vytvořen" ? "green" : "red" }}
        >
          <p>{error}</p>
        </div>

        <div className="button_box">
          <BtnRegistration
            setError={setError}
            name={name}
            surname={surname}
            gender={gender}
            countryPhone_select={countryPhone_select}
            phone={phone}
            email={email}
            password={password}
            password2={password2}
            rankReg={rankReg}
            passwordParent={passwordParent}
            selectedColor={selectedColor}
            setRegistration={setRegistration}
          />
        </div>
      </section>
    </div>
  );
};

export default Registration_window;
