import { useEffect, useContext } from "react";
import { GlobalContext } from "../../global/GlobalContext";
import route_post_universal from "../../routes/global/route_post_universal";

export const useRoutePost_FindAll = (urlFetch, specialization, setFetch_result, fetch_result) => {
  const { urlServer, setError } = useContext(GlobalContext);


  useEffect(() => {

    if(specialization.length < 2){
      // setFetch_info(false)
    }

    const fetch = async () => {
      console.log("Vyhledavání")
      const result = await route_post_universal(urlServer, urlFetch);

      if (result.fetch_data && result.fetch_data.length > 0) {
        setFetch_result(result.fetch_data);
      } else {
        setFetch_result([]);
      }

      if (result.msg) {
        setError(result.msg);
        // setFetch_info(true);
      }
    };

    if (!fetch_result || specialization.length < 2) {
      fetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlFetch, urlServer, specialization]);
};
