import { useEffect, useContext } from "react";
import { GlobalContext } from "../../../global/GlobalContext";
import route_post_universal from "../../../routes/global/route_post_universal";

const useFetch_colroSet_info = (
  urlFetch,
  wordResultKey,
  state,
  wordResultKey2,
  state2,
  setFetch_result
) => {
  const { setError, urlServer } = useContext(GlobalContext);

  useEffect(() => {
    const fetch_data = async () => {
      const result = await route_post_universal(
        urlServer,
        urlFetch,
        wordResultKey,
        state,
        wordResultKey2,
        state2
      );
      if (result.fetch_data) {
        setFetch_result(result);
        // console.log(result)

        window.scrollTo({
          top: 180,
          // behavior: 'smooth', // Plynulý posun
        });
      } else {
        setFetch_result([]);
        console.log("nic")
      }

      setError(result.msg);
    };

    fetch_data();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, state2]);
};

export default useFetch_colroSet_info;