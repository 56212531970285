const route_login = async (
    urlServer,
    urlFetch,
    wordResultKey,
    state1,
    wordResultKey2,
    state2,
    wordResultKey3,
    state3,
    wordResultKey4,
    state4
    // NEXT VALUE GET ONLY HERE BOTTOM !!!!!!!!!
  ) => {
    try {
      let body = {};
  
      if (wordResultKey && state1) {
        body = {
          [wordResultKey]: state1,
        };
      }
  
      // When exist WordResultKey 2 + checkBox
      if (wordResultKey2 && state2) {
        body[wordResultKey2] = state2;
      }
  
      if (wordResultKey3 && state3 !== "") {
        body[wordResultKey3] = state3;
      }
  
      if (wordResultKey4 && state4 !== "") {
        body[wordResultKey4] = state4;
      }
  
      const isEmptyObject = obj => {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
      };
  
      const requestOptions = {
        method: "post",
        credentials: 'include',
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        ...(isEmptyObject(body) ? {} : { body: JSON.stringify(body) }),
      };
  
      const response = await fetch(urlServer + urlFetch, requestOptions);
  
      const data = await response.json();
  
      // if (!response.ok) {
      //   return console.error(data);
      // }
  
      return data;
    } catch (err) {
      return console.error(
        `Chyba FrontEnd catch ${err}. Kontaktujte nás. Váš Novitim.`
      );
    }
  };
  
  export default route_login;