import React from "react";
import "./InputContainer.scss";

const InputContainer = ({
  type,
  label,
  id,
  value,
  set,
  regexSetting,
  autoComplete,
  // readonly,
}) => {
  const inputChange = (value) => {
    let regex = "";

    // if for regex setiting
    if (regexSetting === "text") {
      regex = /^[^\d?<>.:/_*+%;¬`@&#{}()[\]]*$/;
    } else if (regexSetting === "country") {
      // regex = /^[^\d?<>.:/_*+%;¬`@&#{}()[\]"!\\§]*$/;
      regex = /^[^?<>:/_*+%;¬`@&#{}[\]"!\\§]*$/;
    } else if (regexSetting === "word") {
      regex = /^[^\d?<>.:_*+\-%;¬`@&#{}()[\]]*$/;
    } else if(regexSetting === "email") {
      regex = /^[a-zA-Z0-9._%+-]*@{0,1}[a-zA-Z0-9-]*\.{0,1}[a-z]{0,5}$/;
    } else if (regexSetting === "password") {
      regex = /^[^<>×÷¨°'/*§%;¬`@&#{}()[\]]*$/;
    } else if (regexSetting === "name") {
      regex = /^[^\d?<>.:,/_*+\-%;¬`@&#{}()[\]]{0,50}$/;
    } else if (regexSetting === "tag") {
      regex = /^[^?<>:/_*+¬`#{}()[\]]{0,50}$/;
    } else if (regexSetting === "ID") {
      regex = /^[a-zA-Z0-9^\d?<>.:,/_*+\-%;¬`@&#{}()[\]]{0,50}$/;
    } else if (regexSetting === "profession") {
      regex = /^[^?<>._*+%;¬`@#{}[\]]*$/;
    } else if (regexSetting === "phone") {
      regex = /^\d{0,3}(?: \d{0,3}){0,2}$/
    } else if (regexSetting === "URL") {
      regex = /^[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*$/;
    } 
        // else if (regexSetting === "word+number") {
    //   regex = /^[^\?<>/_*+%;¬`@&#{}()[\]]*$/; }

    if (regex.test(value) || value === "") {
      set(value);
    }
  };

  return (
    <div className="containter-input">
      <div className="intput_box">
        <label
          htmlFor={id}
          style={
            value
              ? {
                  top: "-5px",
                  left: "20px",
                  fontSize: "15px",
                }
              : {}
          }
        >
          {label}
        </label>

        <input
          type={type}
          id={id}
          onChange={(e) => inputChange(e.target.value)}
          value={value}
          autoComplete={autoComplete}
          // readonly={readonly}
        />
      </div>
    </div>
  );
};

export default InputContainer;
