import "./NavFormData.scss";
import { NavLink } from "react-router-dom";
import { HiHome } from "react-icons/hi";

const NavFormData = () => {
  return (
    <nav className="NavFormData">
      <div className="nav-box">
        <NavLink to="country">
          <HiHome className="icon" /> Země
        </NavLink>

        <NavLink to="education">
          <HiHome className="icon" /> Vzdělání
        </NavLink>

        <NavLink to="profesion">
        <HiHome className="icon" /> Profese
        </NavLink>
      </div>
    </nav>
  );
};

export default NavFormData;
