import { useContext, useEffect } from "react";
import { GlobalContext } from "../global/GlobalContext";
import localStorage_get from "../utils/localStorage/localStorage_get";

const useLocalStorageGet = () => {
  const {
    // - localStorage Phase
    g_localStorage_loading,
    setG_localStorage_loading,

    setToken_active,
    setActive_time,
    setAdmin,
  } = useContext(GlobalContext);

  // -----------------------------------

  useEffect(() => {
    if (g_localStorage_loading <= 0) {
      // 
        if(localStorage_get("token_active")){
          const token = localStorage_get("token_active");
          setToken_active(token.token_active)
        }

        if(localStorage_get("active_time")) {
          const active = localStorage_get("active_time")
          setActive_time(active.active_time)
          console.log(active)
        }

        if(localStorage_get("admin")) {
          const admin = localStorage_get("admin")
          setAdmin(admin.admin_name, admin.admin_surname, admin.admin_phone, admin.admin_gender, admin.admin_email, admin.admin_rank, admin.admin_color, /*admin.admin_id, admin.admin_company_ID, */admin.admin_company_Name)
        }

      // Chack for loading complete LocalStorage for component start
      setG_localStorage_loading(g_localStorage_loading + 1);
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [g_localStorage_loading]);

  // localStorage keys
  //-------------------
  // token_active
  // active_time
  // admin
  // 
};

export default useLocalStorageGet;
