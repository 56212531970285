import { useEffect, useState, useContext } from "react";
import { GlobalContext } from "../../../../../../../../global/GlobalContext";
import SelectMore from "../../../../../../../elements/selects/SelectMore/SelectMore";
import route_post_universal from "../../../../../../../../routes/global/route_post_universal";
// import NewCompany from "../NewCompany/NewCompany";

const CompanyFindFromName = ({ selectCompany, setSelectCompany, setError, error }) => {
  const { urlServer } = useContext(GlobalContext);

  const [fetch_data, setFetch_data] = useState([]);
  // const [newCompanyShow, setNewCompanyShow] = useState(false);
  console.log(selectCompany)

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await route_post_universal(
          urlServer,
          "/company_listFromName"
        );
        console.log(response);

        if (response.msg === "Nalezené společnosti") {
          setFetch_data(response.fetch_data);
        } else {
          setError(response.msg);
        }
      } catch (err) {
        setError("Client - Catch: " + err);
      }
    };

    if (fetch_data.length !== 0 || error !== "Nalezené společnosti") {
      fetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlServer, setError]);

  return (
    <div>
      {fetch_data.length > 0 && (
        <SelectMore
          placeholder="Vyberte společnost"
          onChange={setSelectCompany}
          object={fetch_data}
          defaultOption={Object.keys(selectCompany).length === 0 ? null : selectCompany}
        />
      )}
      {/* <button className="normalButton" onClick={() => setNewCompanyShow(true)}>Vytvořit společnost</button> */}
      {/* {
            newCompanyShow && <NewCompany setNewCompanyShow={setNewCompanyShow} />
        } */}
    </div>
  );
};

export default CompanyFindFromName;
