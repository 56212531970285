import { useState } from "react";
import InputContainer from "../../../inputs/InputContainer/InputContainer";
import "./Search2.scss";
import route_post_universal from "../../../../../routes/global/route_post_universal";

const Search2 = ({
  id,
  state,
  setError,
  urlFetch_addArray,
  urlServer,
  wordResultKey,
  wordResultKey2,
  inputRegex,
  refresh,
  setRefresh,
  btnLabel,
  // setFetch_data,
}) => {
  const [show, setShow] = useState(false);
  const [state2, setState2] = useState("");

  const btnClick_show = () => {
    setShow(!show)
  }

  const btnClick_add = async () => {
    if (state.length >= 2) {
      const result = await route_post_universal(urlServer, urlFetch_addArray, wordResultKey, state, wordResultKey2, state2)

      setError(result.msg);
      setRefresh(!refresh);
    }
  };

  return (
    <div className="Search2">
      <button onClick={btnClick_show}>Přidat {id === "669e65955d1df8831ec4bfa7" ? "USA stát" : btnLabel}</button> 

      <div className="addBox">
        {show && (
          <>
            <InputContainer
              type="text"
              label="Kraj, Kraje oddělené čárkou"
              id={`region${id}`}
              regexSetting={inputRegex}
              value={state2}
              set={setState2}
              autocomplete="off"
            />

            <button onClick={btnClick_add}>Přidat</button>
          </>
        )}
      </div>
    </div>
  );
};

export default Search2;
