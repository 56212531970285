import { useEffect, useState, useContext } from "react";
import "./Tag_Users.scss";

import { GlobalContext } from "../../../../../global/GlobalContext";
import BtnClose from "../../../../elements/btn/BtnClose/BtnClose";
import route_post_universal from "../../../../../routes/global/route_post_universal";
import { f_date_to_cz } from "../../../../../utils/date/f_date_to_cz";
import TagUsersResponse from "./Tag_Users_Response/Tag_Users_Response";
import UserDataListFromLastPageScroll from "../../../../completeComponents/UserData_listFrom_last_result/UserData_listFromLast_pageScroll/UserData_listFromLast_pageScroll";
import CheckBox from "../../../../elements/checkBox/CheckBox";
import route_universal from "../../../../../routes/global/route_universal";
import { f_checkBox_update_parant } from "../../../../../utils/checkBox/f_checkBox_update_parant";

const TagUsers = ({ tagData, setSelectedTagID, setFetch_tags }) => {
  const { urlServer } = useContext(GlobalContext);
  // new fetch
  const [fetch_result, setFetch_result] = useState([]);

  const [page, setPage] = useState(1);
  const [limitResponse, setLimitRespose] = useState(25);

  const [private_chackBox, setPrivate_chackBox] = useState(tagData.private_chackBox)
  // ---------------------
  // custom hook
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await route_post_universal(
          urlServer,
          "/find_tag_users_listFromLast",
          "limit",
          limitResponse, // limit on page
          "page",
          page,
          "tag_ID",
          tagData._id
        );

        console.log(response); // Zobrazí celou odpověď

        if (response.msg === "Sety tagu nalezeny") {
          setFetch_result(response); // Nastavení dat do stavu
        }
      } catch (error) {
        console.error("Chyba při načítání dat:", error);
      }
    };

    fetchData();
  }, [urlServer, limitResponse, page, tagData._id]);

  // ---------------------
    // f
    // Private - checkBox
    const checkBox_private = async () => {
      const response = await route_universal(
        "PUT",
        urlServer,
        "/tag_update_private_checkBox",
        "tag_ID",
        tagData._id,
        "private",
        !private_chackBox
      )

      if(response.msg === "Soukromí tagu aktualizováno") {
        console.log("aktualizovano")
        setPrivate_chackBox(!private_chackBox)
        f_checkBox_update_parant(setFetch_tags, tagData._id, "admin_create.private", !private_chackBox)
      } else {
        console.log(response.msg)
      }
    }

    // ---------------------

  return (
    <div className="PopUp_full">
      <div className="popUp_full_window">
        {/* BTN CLOSE */}
        <BtnClose setClose={setSelectedTagID} />

        {/* Title */}
        <div className="popUp_full_title">
          <h2>Uživatelé v tagu</h2>
        </div>

        {/* Main */}
        <div className="popUp_full_main">
          <div className="Tag_Users">
            <div className="tag">
              <div
                className="tag_box"
                style={{ backgroundColor: tagData.admin_color }}
              >
                {/* CheckBox - Private */}
                <div className="private_chackBox">
                  <CheckBox value={private_chackBox} set={checkBox_private} text={["Soukromí","Veřejný"]} />
                </div>

                {/* Admin */}
                <div className="tagInfo_box_admin">
                  <p className="tagInfo_box_label">Vytvořil</p>
                  <p className="tag_name">{tagData.admin_name}</p>
                </div>

                {/* Tag Name */}
                <div className="tagInfo_box_tag">
                  <p className="tagInfo_box_label">Jméno Tagu</p>
                  <p className="tag">{tagData.name}</p>
                </div>

                {/* Company*/}
                <div className="tagInfo_box_company">
                  <p className="tagInfo_box_label">Společnost</p>
                  <p className="company">{tagData.company_Name}</p>
                </div>

                {/* Tag Create */}
                <div className="tagInfo_box_dateCreate">
                  <p className="tagInfo_box_label">Datum vytvoření</p>
                  <p className="date">{f_date_to_cz(tagData.date_create)}</p>
                </div>
              </div>
            </div>
          </div>

          {/* Tag's user response */}
          <TagUsersResponse fetch_result={fetch_result} setFetch_result={setFetch_result} />

          {/* Page Scroller */}
          <section className="pageScroll">
            {fetch_result.totalPages && (
              <UserDataListFromLastPageScroll
                fetch_result={fetch_result}
                page={page}
                setPage={setPage}
                limitResponse={limitResponse}
                setLimitRespose={setLimitRespose}
              />
            )}
          </section>
        </div>
      </div>
    </div>
  );
};

export default TagUsers;
