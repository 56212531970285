const f_initials = (name) => {
    // name initials
    const nameParts = name.split(" ");
    const initials = nameParts
        .map((part) => part.charAt(0).toUpperCase())
        .join("");

    return initials;
}

export default f_initials