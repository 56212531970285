import { useState } from "react";
import "./InputPassword.scss";
import InputContainer from "../InputContainer/InputContainer";
import { ImEye, ImEyeBlocked } from "react-icons/im";

const InputPassword = ({
  label,
  id,
  value,
  set,
  regexSetting,
  autoComplete,
}) => {
  const [type, setType] = useState("password");

  const btnPassword = (e) => {
    e.preventDefault();
    
    setType((prevState) => (prevState === "password" ? "text" : "password"));
  };

  return (
    <div className="InputPassword">
      <InputContainer
        type={type}
        label={label}
        id={id}
        value={value}
        set={set}
        regexSetting={regexSetting}
        autoComplete={autoComplete}
      />
      <button className="password" onClick={btnPassword}>
        {type === "password" ? <ImEye /> : <ImEyeBlocked />}
      </button>
    </div>
  );
};

export default InputPassword;
