import React from "react";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Box from "@mui/material/Box";

const RadioUniversalColumn = ({ options, selectedValue, setSelectedValue }) => {
  return (
    <Box display="flex" justifyContent="center" mt={4}>
      <RadioGroup
        value={selectedValue}
        onChange={(e) => setSelectedValue(e.target.value)}
        aria-label="universal-radio"
        name="universal-radio-buttons"
      >
        {options.map((option, index) => (
          <Box key={index} display="flex" flexDirection="row" alignItems="center" mb={2}>
            <FormControlLabel
              value={option.value}
              control={
                <Radio
                  sx={{
                    color: option.color || "#007bff", // Výchozí barva je modrá
                    "&.Mui-checked": {
                      color: option.color || "#007bff",
                    },
                    transform: "scale(1.5)", // Zvětšení velikosti
                    marginRight: "20px", // Posun labelu od rádia o 20 px doprava
                  }}
                />
              }
              label={option.label}
              labelPlacement="end" // Popisek napravo od tlačítka
            />
          </Box>
        ))}
      </RadioGroup>
    </Box>
  );
};

export default RadioUniversalColumn;