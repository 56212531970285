import "./UserData_listFromLast_pageScroll.scss";
import { VscChevronLeft, VscChevronRight } from "react-icons/vsc";
import UserDataListFromLastLimitResponse from "./UserData_listFromLast_limitResponse/UserData_listFromLast_limitResponse";

const UserData_listFromLast_pageScroll = ({ fetch_result, page, setPage, limitResponse, setLimitRespose }) => {
  return (
    <div className="UserData_listFromLast_pageScroll">
      <div></div>

      {/* Pages */}
      <section className="pageSelect">
        {/* Back */}
        {page > 1 && (
          <button className="arrow" onClick={() => setPage(page - 1)}>
            <VscChevronLeft />
          </button>
        )}

        {/* Pages */}
        {fetch_result.totalPages && (
          <section className="pages">
            {Array.from({ length: fetch_result.totalPages }, (_, i) => (
              <button
                key={i + 1}
                className={page === i + 1 ? "activePage" : ""}
                onClick={() => setPage(i + 1)}
              >
                {i + 1}
              </button>
            ))}
          </section>
        )}

        {/* Next */}
        {page !== fetch_result.totalPages && (
          <button className="arrow" onClick={() => setPage(page + 1)}>
            <VscChevronRight />
          </button>
        )}
      </section>

      {/* Select */}
      <section>
        <UserDataListFromLastLimitResponse limitResponse={limitResponse} setLimitRespose={setLimitRespose} />
      </section>
    </div>
  );
};

export default UserData_listFromLast_pageScroll;
