import { useContext } from "react"
import { GlobalContext } from "../../GlobalContext"
import "./scss/Footer.scss"

const Footer = () => {
  const {userName} = useContext(GlobalContext)
  return (
    <footer className={`${userName? "whiteBG" : ""}`}>
      {!userName && "© Novitim s.r.o. 2024"}</footer>
  )
}

export default Footer