import { useState } from "react";
import InputContainer from "../../../../elements/inputs/InputContainer/InputContainer";
import "./Searcher2_MeaningOfWords.scss";
import route_post_universal from "../../../../../routes/global/route_post_universal";

const Searcher2MeaningOfWords = ({
  id,
  state,
  setError,
  urlFetch_addArray,
  urlServer,
  wordResultKey,
  wordResultKey2,
  inputRegex,
  refresh,
  setRefresh,
  btnLabel,
  inputLabel_AddWord,
  setFetch_data_Sercher2,
}) => {
  const [show, setShow] = useState(false);
  const [state2, setState2] = useState("");
  const btnClick_show = async () => {
    setShow(!show);

    if (!show) {
      const result = await route_post_universal(
        urlServer,
        urlFetch_addArray,
        wordResultKey,
        state,
        // wordResultKey2
      )
      setFetch_data_Sercher2(result.fetch_data);
      setError(result.msg);
      //console.log(result);
    } else {
        setFetch_data_Sercher2([]);
    }
  };

  const btnClick_add = async () => { // TO:DO dodělat přidání
    if (state.length >= 2) {
      // const result = await route_post_universal(
      //   urlServer,
      //   urlFetch_addArray,
      //   wordResultKey,
      //   state,
      //   wordResultKey2,
      //   state2
      // )
    }
  };

  return (
    <div className="Searcher2_MeaningOfWords">
      <button onClick={btnClick_show}>Přidat {btnLabel}</button>

      <div className="addBox">
        {show && (
          <>
            <InputContainer
              type="text"
              label={inputLabel_AddWord}
              id={id}
              regexSetting={inputRegex}
              value={state2}
              set={setState2}
              autocomplete="off"
            />

            <button onClick={btnClick_add}>Přidat</button>
          </>
        )}
      </div>
    </div>
  );
};

export default Searcher2MeaningOfWords;