import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { GlobalContext } from "../../../global/GlobalContext";
import "../UserData/NavUserData/NavUserData.scss";
import { FaUser } from "react-icons/fa";
import { FaRegFileWord } from "react-icons/fa";
import { MdFeaturedPlayList } from "react-icons/md";
import { FaFolder } from "react-icons/fa";

// import BtnRegistration from "../../../global/layouts/UserData/Registration/BtnRegistration";
// import LogOut from "../../../global/layouts/UserData/LogOut/LogOut";

const NavAdminData = () => {
  const { admin_name, admin_email, admin_rank, admin_color } =
    useContext(GlobalContext);

  return (
    <nav className="NavUserData">
      <div className="NavUserData_main">
        <div className="admin_change">
          {/* <NavLink to="/admin_data" className="normalButton">
            <FaLock />
        </NavLink> */}
          <NavLink to="/" className="normalButton">
            <div className="hover_checkout">
              <FaUser />
              <p className="hover_text">Uživatelské</p>
            </div>
          </NavLink>
        </div>

        <div className="nav-box">
          {/* TO:DO WORDS ještě existuje */}
          <NavLink to="meaning_of_words">
            <FaRegFileWord className="icon" /> Význam slov
          </NavLink>
          {/* ColorSets */}
          <NavLink to="word_set">
            <FaFolder className="icon" /> Slovní moduly
          </NavLink>
          {/* NewRespondent */}
          <NavLink to="data_form">
            <MdFeaturedPlayList className="icon" /> Vstupní formulář
          </NavLink>
        </div>
      </div>

      <div className="NavUserData_bottom">
        <div
          style={{ border: `1px solid ${admin_color}` }}
          className="admin_details"
        >
          <p className="name">{admin_name}</p>
          <p className="email">{admin_email}</p>
          <p className="rank">{admin_rank}</p>
        </div>
        {/* <div className="registration">
          <BtnRegistration />
        </div>
        <div className="logOut">
          <LogOut />
        </div> */}
      </div>
    </nav>
  );
};

export default NavAdminData;
