import "./scss/MeaningOfWords.scss";
import { useState, useContext } from "react";
import { GlobalContext } from "../global/GlobalContext";
import route_find_add from "../routes/pages/meaning/route_meaning";
import Searcher from "../components/elements/Searcher/Searcher";
import SearcherResultMeaningOfWords from "../components/pages/MeaningOfWords/SearcherResult_MeaningOfWords/SearcherResult_MeaningOfWords";

const MeaningOfWords = () => {
  const { urlServer} = useContext(GlobalContext);
  const [maleGender, setMaleGender] = useState("");
  const [femaleGender, setFemaleGender] = useState("");

  const [meaning, setMeaning] = useState("");
  const [checkBox, setCheckBox] = useState(true); // Add mainMeaningWord to word colection if true
  const [genderWord_checkBox, setGenderWord_checkBox] = useState(false); // if have word female gender

  const [fetch_result, setFetch_result] = useState([]);
  const [error, setError] = useState("Nic nezadáno");
  const [refresh, setRefresh] = useState(false); // ref. Search
  const btnAddDelete = true; // for delete Add btn (only for this comp)
  // -----------------------------------------------

  // useEffect(() => {
  //   if (maleGender.length <= 2 || meaning.length <= 2) {
  //     setError("Nic nezadáno");
  //   }
  // }, [maleGender, meaning]);

  const btnClick = async () => {
    if (maleGender.length >= 2) {
      const result = await route_find_add(
        "wordOfMeaning",
        "meaning",
        maleGender,
        meaning,
        urlServer,
        "/addWordAndMeaning",
        checkBox,
        //------
        "femaleWord",
        femaleGender,
      );
      setError(result.msg);

      // Clear
      setGenderWord_checkBox(false)
      setCheckBox(true);
      setMaleGender("");
      setFemaleGender("");
    }
  };

  // ------------------------------------------------------

  return (
    <div className="MeaningOfWords">
      <section className="searcher-box">
        <div className="frist_row">
          <div className="checkbox">
            <label htmlFor="genderWord_checkBox">Ženský rod</label>
            <input
              type="checkbox"
              id="genderWord_checkBox"
              checked={genderWord_checkBox}
              onChange={() => setGenderWord_checkBox(!genderWord_checkBox)}
            />
          </div>
          <div className="mainWord">
            <Searcher
              // Delete btn
              btnAddDelete={btnAddDelete}
              //
              title="Můžské hlavní slovo fráze"
              inputType="text"
              inputID="mainWordMeaning_man"
              inputLabel="Zadejte mužský rod"
              inputRegex="word"
              // key-word fetch
              wordResultKey="wordOfMeaning"
              wordResultKey2="meaning"
              //Value
              state={maleGender}
              setState={setMaleGender}
              state_fetch_result={setFetch_result}
              //URL
              urlFetch="/findWordOfMeaning"
              urlFetch_add=""
              urlServer={urlServer}
              setError={setError}
              refresh={refresh}
            />

            {/* Female word */}
            {genderWord_checkBox && (
              <Searcher
                // Delete btn
                btnAddDelete={btnAddDelete}
                //
                title="Ženské hlavní slovo fráze"
                inputType="text"
                inputID="mainWordMeaning_woman"
                inputLabel="Zadejte ženský rod"
                inputRegex="word"
                // key-word fetch
                // wordResultKey="wordOfMeaning"
                // wordResultKey2="meaning"
                //Value
                state={femaleGender}
                setState={setFemaleGender}
                //URL
                urlFetch=""
                // urlFetch_add=""
                // urlServer={urlServer}
                // setError={setError}
                // refresh={refresh}
              />
            )}
          </div>
        </div>

        <div className="meaning_box">
          <div className="checkbox">
            <label htmlFor="checkbox">Vytvořit slovo</label>
            <input
              type="checkbox"
              id="checkbox"
              checked={checkBox}
              onChange={() => setCheckBox(!checkBox)}
            />
          </div>

          <Searcher
            // Delete btn
            btnAddDelete={btnAddDelete}
            //
            title="Fráze výrazu"
            inputType="text"
            inputID="meaning"
            inputLabel="Zadejte výrazy / výrazy oddělené ,"
            inputRegex="text"
            // key-word fetch
            wordResultKey="meaning"
            wordResultKey2="wordOfMeaning"
            //Value
            state={meaning}
            setState={setMeaning}
            state_fetch_result={setFetch_result}
            //URL
            urlFetch="/findMeaning"
            urlFetch_add=""
            urlServer={urlServer}
            setError={setError}
            refresh={refresh}
          />
        </div>

        <div className="btn_box">
            <button onClick={btnClick}>Přidat</button>
        </div>
      </section>

      <section className="SearcherResult">
        <SearcherResultMeaningOfWords
          // Delete btn
          // btnAddDelete={btnAddDelete}
          // word fetch key
          wordResultKey="wordOfMeaning"
          wordResultKey2="meaning"
          //url
          urlFetch_addArray="" // TO:DO dodělat přidání
          urlServer={urlServer}
          // value
          setValue={setMaleGender}
          setValue2={setMeaning}
          fetch_result={fetch_result}
          refresh={refresh}
          setRefresh={setRefresh}
          error={error}
          setError={setError}
          btnLabel_AddWord="/zobrazit slovo/a"
          btnLabel_AddMeaningKey="významový klíč"
          inputLabel_AddWord="Přidat Slovo/a oddělené , k významu"
          inputLabel_AddMeaningKey="Přidat významový klíč"
        />
      </section>
    </div>
  );
};

export default MeaningOfWords;
