import React from "react";
import "./Email_Icons_Info.scss";
import { HiOutlineMailOpen } from "react-icons/hi";
import {
  MdOutlineMarkEmailRead,
  MdOutlineMarkEmailUnread,
} from "react-icons/md";

import { TbMailPause, TbMailX, TbMailOff } from "react-icons/tb";
import { RiSpam3Fill } from "react-icons/ri";
import { IoLinkSharp } from "react-icons/io5";
import { f_date_to_cz } from "../../../utils/date/f_date_to_cz";

const EmailIconsInfo = ({
  noEmail_send,
  email_link_click,
  email_open,
  email_delivered,
  email_bounced,
  email_spam_reported,
  email_dropped,
  email_deferred,
  // - date
  // - email - date
  email_delivered_date,
  email_open_dates,
  email_link_click_dates,
  email_deferred_date,
  email_bounced_date,
  email_dropped_date,
  email_spam_report_date,
}) => {
  return (
    <div className="Email_Icons_Info">
      {
        noEmail_send && <div className="email_details noEmail_send"> <IoLinkSharp title="Email nebyl automaticky odeslán" /> </div>
      }
      {
        !noEmail_send && <>
          {/* delivered */}
      <div className="email_details"> 
        <MdOutlineMarkEmailRead
          style={{ color: email_delivered ? "green" : "#b4b4b4" }}
          title={email_delivered ? "Email doručen" : "Email nedoručen"}
        />
        <p>{email_delivered ? f_date_to_cz(email_delivered_date) : ""}</p>
      </div>
      
      {/* open */}
      <div className="email_details">
        <HiOutlineMailOpen
          style={{ color: email_open > 0 ? "green" : "#b4b4b4" }}
          title={email_open > 0 ? "Email otevřen" : "Email neotevřen"}
        />
        <div>
          {email_open > 0 
            ? email_open_dates.map((oneOpen, index) => {
              return <p key={index}>{f_date_to_cz(oneOpen)}</p>;
            }) 
            : <p></p>}
        </div>
        
      </div>

    {/* Click */}
      <div className="email_details">
        <MdOutlineMarkEmailUnread
          style={{ color: email_link_click > 0 ? "green" : "#b4b4b4" }}
          title={
            email_link_click > 0
              ? "Na odkaz bylo kliknuto"
              : "Na odkaz nebylo kliknuto"
          }
        />
        <div>
      {email_link_click > 0 
        ? email_link_click_dates.map((oneClick, index) => {
          return <p key={index}>{f_date_to_cz(oneClick)}</p>;
        })
        : <p></p>}
    </div>
      </div>
      
      {/* deferred - dočasně odložen */}
      <div className="email_details">
        <TbMailPause style={{ 
          color: email_deferred ? "red" : "#b4b4b4"  }} 
          title={email_deferred 
            ? "Email dočasně odložen" 
            : "Email nebyl odložen"
          }
        />
        <p>{email_deferred_date ? f_date_to_cz(email_deferred_date) : ""}</p>
      </div>
      
      {/* bounce - nebyl přijat - plná schránka, špatná adresa */}
      <div className="email_details">
        <TbMailX
          style={{ color: email_bounced ? "red" : "#b4b4b4"  }}
          title={
            email_bounced
              ? "Špatny E-mail nebo plná schránka"
              : "Email a schránka v pořádku"
          }
        />
        <p>{email_bounced_date ? f_date_to_cz(email_bounced_date) : ""}</p>
      </div>
      
      {/* dropped - nebyl odeslán - blackslist, neaktivní */}
      <div className="email_details">
        <TbMailOff
          style={{ color: email_dropped ? "red" : "#b4b4b4" }}
          title={
            email_dropped
              ? "Jsme na black-list nebo je E-mail neaktivní dlouhodobě"
              : "Nejsme na black-listu a E-mail je aktivní"
          }
        />
        <p>{email_dropped_date ? f_date_to_cz(email_dropped_date) : ""}</p>
      </div>

      {/* spamreport - Označen uživatelem jako spam */}
      <div className="email_details">
        <RiSpam3Fill
          style={{ color: email_spam_reported ? "red" : "#b4b4b4" }}
          title={
            email_spam_reported
              ? "Uživatelem označeno jako SPAM"
              : "Neoznačeno uživatelem jako SPAM"
          }
        />
        <p>{email_spam_report_date ? f_date_to_cz(email_spam_report_date) : ""}</p>
      </div>
        </>
      }
      
      
    </div>
  );
};

export default EmailIconsInfo;
