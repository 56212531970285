import "./Education.scss"
import CompleteFetchUserForm from "../../../components/completeComponents/CompleteFetchUserForm"

const Education = () => {
    
  return (
    <div className="Education">
        <CompleteFetchUserForm
        title="Vzdělání"
        inputType="text"
        inputID="education"
        inputLabel="Přidat / Najít vzdělání"
        inputRegex="text"
        //Key word
        wordResultKey="education"
        wordResultKey2=""
        //Fetch
        urlFetch_find="/findEducation"
        urlFetch_add="/addEducation" 
        urlFetch_addArray=""
        //btn
        btnLabel=""
      />
    </div>
  )
}

export default Education