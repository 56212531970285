import { useEffect, useState } from "react";
import route_universal from "../../../../routes/global/route_universal";
import SelectMoreWithNull from "../SelectMore/SelectMoreWithNull/SelectMoreWithNull";

const SelectPhonePrefixes = ({ set, placeholder }) => {
  const [countryPhone_fetch, setCountryPhone_fetch] = useState(null);

  // Předvolba
  useEffect(() => {
    const fetch_data = async () => {
      const response = await route_universal(
        "GET",
        "",
        "https://restcountries.com/v3.1/all"
      );

      // Filtr (name.official a idd.root kombinované se suffix, pokud existuje)
      const filteredData = response
        .filter(
          (country) =>
            country.name &&
            country.name.official &&
            country.idd &&
            country.idd.root
        )
        .map((country) => ({
          label: `${country.idd.root}${
            country.idd.suffixes && country.idd.suffixes.length > 0
              ? country.idd.suffixes[0]
              : ""
          } ||  ${country.name.official}`,
          value: `${country.idd.root}${
            country.idd.suffixes && country.idd.suffixes.length > 0
              ? country.idd.suffixes[0]
              : ""
          }`,
        }))
        .sort((a, b) => {
          // Extrakce číselné části předvolby pro porovnání
          const aValue = parseInt(a.value.replace("+", ""), 10);
          const bValue = parseInt(b.value.replace("+", ""), 10);
          return aValue - bValue;
        });

      if (filteredData.length !== 0) {
        setCountryPhone_fetch(filteredData);
      }
    };

    if (!countryPhone_fetch) {
      fetch_data();
    }
  }, [countryPhone_fetch]);

  return (
    <div>
      {/* Phone */}
      <SelectMoreWithNull object={countryPhone_fetch} onChange={set} placeholder={placeholder} />
    </div>
  );
};

export default SelectPhonePrefixes;
