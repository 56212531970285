const Word = () => {

  return (
    <div className="Word">

    </div>
  );
};

export default Word;
