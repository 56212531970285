import { useState, useContext } from "react";
import { GlobalContext } from "../../../../../../../global/GlobalContext";
// import "./NewCompany.scss";
import InputContainer from "../../../../../../elements/inputs/InputContainer/InputContainer";
import route_post_universal from "../../../../../../../routes/global/route_post_universal";
import SelectPhonePrefixes from "../../../../../../elements/selects/Select_PhonePrefixes/Select_PhonePrefixes";

const NewCompany = ({ setPhase, setError }) => {
  const { urlServer } = useContext(GlobalContext);

  const [companyName, setCompanyName] = useState("");
  const [companyURL, setCompanyURL] = useState("");
  const [CEOShow, setCEOShow] = useState(false);
  const [CEO_Name, setCEO_Name] = useState("");
  const [CEO_Surname, setCEO_Surname] = useState("");
  const [CEO_Phone, setCEO_Phone] = useState("");
  const [CEO_PhonePrefixes, setCEO_PhonePrefixes] = useState("");
  const [CEO_Email, setCEO_Email] = useState("");
  const [main_processor_Name, setMain_processor_Name] = useState("");
  const [main_processor_Surname, setMain_processor_Surname] = useState("");
  const [main_processor_Phone, setMain_processor_Phone] = useState("");
  const [main_processor_PhonePrefixes, setMain_processor_PhonePrefixes] = useState("");
  const [main_processor_Email, setMain_processor_Email] = useState("");
  // ----------------------------------------

  const btnCreateCompany = async () => {
    if (companyName && companyURL && 
        ((CEO_Name && CEO_Surname && CEO_Phone && CEO_PhonePrefixes && CEO_Email) || 
        (main_processor_Name && main_processor_Surname && main_processor_Email && main_processor_PhonePrefixes && main_processor_Phone))) {
      try {
        const response = await route_post_universal(
          urlServer,
          "/company_create",
          "company",
          companyName,
          "company_URL",
          companyURL,
          "main_processor_Name",
          main_processor_Name,
          "main_processor_Surname",
          main_processor_Surname,
          "main_processor_Email",
          main_processor_Email,
          "main_processor_Phone",
          main_processor_PhonePrefixes + main_processor_Phone,
          "CEO_Name",
          CEO_Name,
          "CEO_Surname",
          CEO_Surname,
          "CEO_Email",
          CEO_Email,
          "CEO_Phone",
          CEO_PhonePrefixes + CEO_Phone,

        );

        // Pokud je odpověď úspěšná
        if (response && response.msg === "Společnost vytvořena") {
          setPhase(0);
          console.log(response);
        }
        // Pokud se jedná o chybu od backendu (např. duplicita společnosti)
        else if (response && response.msg) {
          setError(response.msg); // Odeslání správné chybové zprávy
        }
        // Neznámá chyba
        else {
          setError(response.msg);
        }
      } catch (err) {
        // Chyba na straně klienta nebo nedosažitelný backend
        setError("Client - Catch: " + err.message);
      }
    } else {
      setError("Vyplňte Společnost a kontaktní osobu");
    }
  };

  // -----------------------------------------

  return (
    <section className="NewRespondent">
      <div className="NewRespondent_title">
        <h2>Vytvořit společnost</h2>
      </div>
      <div className="NewRespondent_main">
      <InputContainer
          type="text"
          label="Jméno společnosti"
          id="companyName"
          value={companyName}
          set={setCompanyName}
          regexSetting="tag"
        />

        <InputContainer
          type="text"
          label="URL společnosti"
          id="companyURL"
          value={companyURL}
          set={setCompanyURL}
          regexSetting="URL"
        />

        <button className="normalButton" onClick={() => setCEOShow(!CEOShow)}>
          + CEO
        </button>
        {CEOShow && (
          <div className="CEO">
            <InputContainer
              type="text"
              label="CEO - Jméno"
              id="CEO_Name"
              value={CEO_Name}
              set={setCEO_Name}
              regexSetting="name"
            />
            <InputContainer
              type="text"
              label="CEO - Přijmení"
              id="CEO_Name"
              value={CEO_Surname}
              set={setCEO_Surname}
              regexSetting="name"
            />
            <InputContainer
              type="text"
              label="CEO - E-mail"
              id="CEO_Email"
              value={CEO_Email}
              set={setCEO_Email}
              regexSetting="email"
            />

            {/* Phone */}
            <SelectPhonePrefixes set={setCEO_PhonePrefixes} placeholder="CEO - předvolba" />
            <InputContainer
              type="tel"
              label="CEO - Telefon"
              id="CEO_Phone"
              value={CEO_Phone}
              set={setCEO_Phone}
              regexSetting="phone"
            />
          </div>
        )}

        <InputContainer
          type="text"
          label="Konaktní osoba - Jméno"
          id="main_processor_Name"
          value={main_processor_Name}
          set={setMain_processor_Name}
          regexSetting="name"
        />
        <InputContainer
          type="text"
          label="Konaktní osoba - Přijmení"
          id="main_processor_Name"
          value={main_processor_Surname}
          set={setMain_processor_Surname}
          regexSetting="name"
        />
        <InputContainer
          type="text"
          label="Konaktní osoba - E-mail"
          id="main_processor_Email"
          value={main_processor_Email}
          set={setMain_processor_Email}
          regexSetting="email"
        />

        {/* Phone */}
        <SelectPhonePrefixes set={setMain_processor_PhonePrefixes} placeholder="Konaktní osoba - předvolba" />
            <InputContainer
              type="tel"
              label="Konaktní osoba - Telefon"
              id="main_processor_Phone"
              value={main_processor_Phone}
              set={setMain_processor_Phone}
              regexSetting="phone"
            />

        <button className="normalButton" onClick={btnCreateCompany}>
          Vytvořit společnost
        </button>
      </div>
    </section>
  );
};

export default NewCompany;
