import { useContext } from "react";
import { GlobalContext } from "../../../../../GlobalContext";
import route_post_universa from "../../../../../../routes/global/route_post_universal"

const BtnRegistration = ({
  setRegistration,
  setError,
  name,
  surname,
  gender,
  countryPhone_select,
  phone,
  email,
  password,
  password2,
  rankReg,
  passwordParent,
  selectedColor,
}) => {
  const { urlServer, /* admin_email*/ } = useContext(GlobalContext);
  const btnRegistration = async (e) => {
    e.preventDefault();
    console.log(rankReg)
    if (!name || !surname || (gender !== "0" && gender !== "1") || !countryPhone_select || !phone || !email || !password || !password2 || rankReg < 0 || !passwordParent || !selectedColor) {
      setError("Vyplnte všechny položky");
    } else if (password !== password2) {
        setError("Hesla se neshodují");
    } else {
      // update inputs values
      // clear whiteSpace
      const normalizeInput = (input) => {
        return input.trim().replace(/\s+/g, " ");
      };
      const normalizedName = normalizeInput(name);
      const normalizedSurname = normalizeInput(surname);
      // email remove white space
      const removeEmailWhitespace = (input) => {
        return input.replace(/\s+/g, "").toLowerCase();
      };
      const normalizedEmail = removeEmailWhitespace(email);

      //Regex inputs values
      // Regex name **** ****
      // const regexName = /^\S{2,}\s\S{2,}$/;
      const regexName = /^\S{2,}(?:\s\S{2,})?$/;
      const isValidName = regexName.test(normalizedName);
      const isValidSurname = regexName.test(normalizedSurname);
      if (!isValidName || !isValidSurname) {
        return setError("Jméno nebo Přijmení je ve špatném formátu");
      }
      // Regex email
      const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = regexEmail.test(normalizedEmail);
      if (!isValidEmail) {
        return setError("Email je ve špatném formátu");
      }

      const normalized_Password = normalizeInput(password);
      const normalized_PasswordParent = normalizeInput(passwordParent);

      // -----------------------------------------
      try {
      const response = await route_post_universa(
        urlServer,
        "/registerAdmin",
        "name",
        normalizedName,
        "surname",
        normalizedSurname,
        "gender",
        parseInt(gender),
        "phone",
        countryPhone_select + phone,
        "newEmail",
        normalizedEmail,
        "password",
        normalized_Password,
        "rank_new",
        rankReg,
        "parentUserPassowrd",
        normalized_PasswordParent,
        "color",
        selectedColor
      )

      if(response?.msg === "Nový admin vytvořen"){
        setError(response.msg)
        setTimeout(() => {
            setRegistration(false); // Vyprázdnění konzole po 5 sekundách
        }, 2000); // 5000 milisekund = 5 sekund
      } else {
        setError(response.msg)
      }
    } catch (err) {
      // Zobrazení chybové zprávy uživateli
      setError(err.message || "Nastala chyba serveru. Kontaktujte podporu.");
    }
    }
  };

  return (
    <div>
      <button onClick={btnRegistration}>Registrovat</button>
    </div>
  );
};

export default BtnRegistration;
