import React from "react";
import "./NewRespondent_oneRespondent.scss";
import BtnClose from "../../elements/btn/BtnClose/BtnClose";
import { f_date_to_cz } from "../../../utils/date/f_date_to_cz";
import EmailIconsInfo from "../../elements/email/Email_Icons_Info";

const NewRespondentOneRespondent = ({
  fetch_data_newRespondent,
  setNewRespondent_ID,
}) => {
  const {
    // _id,
    name,
    email,
    adminTag_create,
    code,
    company,
    tag,
    date_create,
    // - email
    noEmail_send,
    email_link_click,
    email_open,
    email_delivered,
    email_bounced,
    email_spam_reported,
    email_dropped,
    email_deferred,
    // - email - date
    email_delivered_date,
    email_open_dates,
    email_link_click_dates,
    email_deferred_date,
    email_bounced_date,
    email_dropped_date,
    email_spam_report_date,
  } = fetch_data_newRespondent?.fetch_data;

  // console.log(fetch_data_newRespondent);
  return (
    <div className="NewRespondent_oneRespondent">
      {/* BTN Close */}
      <BtnClose setClose={setNewRespondent_ID} />
      {fetch_data_newRespondent && (
        <section className="NewRespondent_oneRespondent_main">
          <h1>Respondent</h1>

          <div className="Tag_Users">
            <div className="tag">
              <div
                className="tag_box"
                style={{ backgroundColor: adminTag_create.color }}
              >
                {/* Admin */}
                <div className="tagInfo_box_admin">
                  <p className="tagInfo_box_label">Vytvořil tag</p>
                  <p className="tag_name">{`${adminTag_create.name} ${adminTag_create.surname}`}</p>
                </div>

                {/* Tag Name */}
                <div className="tagInfo_box_tag">
                  <p className="tagInfo_box_label">Jméno Tagu</p>
                  <p className="tag">{tag.name}</p>
                </div>

                {/* Company*/}
                <div className="tagInfo_box_company">
                  <p className="tagInfo_box_label">Společnost</p>
                  <p className="company">{company.company}</p>
                </div>

                {/* Tag Create */}
                <div className="tagInfo_box_dateCreate">
                  <p className="tagInfo_box_label">Datum vytvoření tagu</p>
                  <p className="date">{f_date_to_cz(tag.date_create)}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="NewRespondent_oneRespondent_main_main">
            <div className="NewRespondent_oneRespondent_data">
              {/* date_create */}
              <div className="date_create">
                <p className="date_create_title">Datum vytvoření</p>
                <p className="date_create_date">{f_date_to_cz(date_create)}</p>
              </div>

              {/* Email icons */}
              <div className="email_box">
                <EmailIconsInfo
                  noEmail_send={noEmail_send}
                  email_link_click={email_link_click}
                  email_open={email_open}
                  email_delivered={email_delivered}
                  email_bounced={email_bounced}
                  email_spam_reported={email_spam_reported}
                  email_dropped={email_dropped}
                  email_deferred={email_deferred}
                  // - email - date
                  email_delivered_date={email_delivered_date}
                  email_open_dates={email_open_dates}
                  email_link_click_dates={email_link_click_dates}
                  email_deferred_date={email_deferred_date}
                  email_bounced_date={email_bounced_date}
                  email_dropped_date={email_dropped_date}
                  email_spam_report_date={email_spam_report_date}
                />
              </div>
            </div>

            <div className="others_data">
                {/* UserData */}
                <div className="name_email">
                  <p className="name">{name}</p>
                  <p className="email">{email}</p>
                </div>

                <div className="code_box">
                  <p className="code_title">Kód</p>
                  <p className="code_data">{code}</p>
                </div>
              </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default NewRespondentOneRespondent;
