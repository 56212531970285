import { useState } from "react";
import "../../../completeComponents/UserData_listFrom_last_result/UserData_listFrom_last_result.scss";
import f_initials from "../../../../utils/nameAndEmail/f_initials";
import { f_date_to_cz } from "../../../../utils/date/f_date_to_cz";
import TagUsers from "./Tag_Users/Tag_Users";
import CheckBoxonlyShow from "../../../elements/checkBox/CheckBox_onlyShow/CheckBox_onlyShow";

const TagsResponseListFromLast = ({ fetch_result, setFetch_tags }) => {
  const [selectedTagID, setSelectedTagID] = useState(null);

  // Page Have in bottom POPUP - show Tag USERS

  const btnShow_tagUsers = (tagData) => {
    setSelectedTagID(tagData);
  };
  console.log(fetch_result);

  return (
    <div className="UserData_listFrom_last_result">
      {/* POP UP TagUSers */}
      {selectedTagID?._id && (
        <TagUsers tagData={selectedTagID} setSelectedTagID={setSelectedTagID} setFetch_tags={setFetch_tags} />
      )}

      {fetch_result.fetch_data && (
        <div className="colorSets">
          <div className="titles">
            <p className="private_title">Soukromý</p>
            <p className="nameEmail">Tag</p>
            <p>Společnost</p>
            <p>Slovní modul</p>
            <p>Datum vytvoření</p>
            <p className="lastTag">Nevyplněné</p>
            <p className="lastTag">Vyplněné</p>
          </div>

          {fetch_result.fetch_data.map((oneTag) => {
            const {
              _id,
              name,
              date_create,
              company,
              admin_create,
              newRespondent_number,
              colorSet_number,
            } = oneTag;
            const { /*company_ID, */ company_Name } = company;
            const {
              admin_color,
              admin_name /*, admin_email, admin_company_ID, admin_ID  */,
            } = admin_create;

            return (
              <div
                key={_id}
                className="oneColorSet_info tag_box"
                onClick={() =>
                  btnShow_tagUsers({
                    _id,
                    name,
                    admin_color,
                    admin_name,
                    company_Name,
                    date_create,
                    private_chackBox: admin_create.private
                  })
                }
              >
                {/* Private Tag */}
                <div className="checkBox_box">
                  <CheckBoxonlyShow value={admin_create.private} />
                </div>
                

                <div className="tagName">
                  <div
                    className="tag_box"
                    style={{ backgroundColor: admin_color }}
                  >
                    <p className="tag_name">{f_initials(admin_name)}</p>
                    <p className="tag">{name}</p>
                  </div>
                </div>
                <div className="item">
                  <p>{company_Name}</p>
                </div>
                <div className="item">
                  <p>{oneTag.default_wordSet?.wordSet_Name || ""}</p>
                </div>
                <div className="item">
                  <p>{f_date_to_cz(date_create)}</p>
                </div>
                <div className="item item_last lastTag">
                  <p>{newRespondent_number}</p>
                </div>
                <div className="item item_last lastTag">
                  <p>{colorSet_number}</p>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default TagsResponseListFromLast;
