import { useState, useEffect, useContext } from "react";
import Searcher from "../elements/Searcher/Searcher";
import SearcherResult from "../elements/Searcher/SearcherResult/SearcherResult";
import { GlobalContext } from "../../global/GlobalContext";

// TO:DO přesunout komponentu do com./pages
const CompleteFetchUserForm = ({
  title,
  inputType,
  wordResultKey,
  inputID,
  inputLabel,
  inputRegex,
  urlFetch_find,
  urlFetch_add,
  urlFetch_addArray,
  wordResultKey2,
  btnLabel,
}) => {
  const {urlServer} = useContext(GlobalContext);
  const [value, setValue] = useState("");
  const [fetch_result, setFetch_result] = useState([]); // Country + Region
  const [error, setError] = useState("Nic nezadáno");
  const [fetch_usaRegion, setFetch_usaRegion] = useState([]); // USA REG
  const [refresh, setRefresh] = useState(false) // ref. Search
  // -----------------------------------------------

  useEffect(() => {
    if (value.length < 2) {
      setError("Nic nezadáno");
    }
  }, [value]);

  // ------------------------------------------------------

  return (
    <div className="CompleteFetchUserForm">
      <section className="add">
      <Searcher
          wordResultKey={wordResultKey}
          title={title}
          inputType={inputType}
          inputID={inputID}
          inputLabel={inputLabel}
          inputRegex={inputRegex}
          state={value}
          setState={setValue}
          state_fetch_result={setFetch_result}
          setFetch_usaRegion={setFetch_usaRegion}
          // nameFetchWord={nameFetchWord}
          urlFetch={urlFetch_find}
          urlFetch_add={urlFetch_add}
          setError={setError}
          urlServer={urlServer}
          // wordResultKey2={wordResultKey2}
          refresh={refresh}
        />

        <SearcherResult
          wordResultKey={wordResultKey}
          wordResultKey2={wordResultKey2}
          // value
          fetch_result={fetch_result}
          fetch_usaRegion={fetch_usaRegion} // USA REGIONS
          refresh={refresh}
          setRefresh={setRefresh}
          error={error}
          setError={setError}
          inputRegex={inputRegex}
          btnLabel={btnLabel}
          //url
          urlFetch_addArray={urlFetch_addArray}
          urlServer={urlServer}
        />
      </section>
    </div>
  );
};

export default CompleteFetchUserForm;
