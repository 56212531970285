import { useState } from "react";
import "./ColorSets.scss";
// import Search from "../../../components/elements/Search/Search";
// import route_findUserAndColorSet from "../../../routes/global/route_post_universal";
// new
import useFetch_colroSet_info from "../../../hooks/Pages/UserData/useFetch_colorSet_info";
import UserDataListFromLastResult from "../../../components/pages/UsersData/UserData_listFromLast/UserData_listFromLast_result/UserData_listFromLast_result";
import UserDataListFromLastPageScroll from "../../../components/completeComponents/UserData_listFrom_last_result/UserData_listFromLast_pageScroll/UserData_listFromLast_pageScroll";



const ColorSets = () => {

  const [fetch_result, setFetch_result] = useState([]);
  // const [error, setError] = useState("");

  // new fetch
  const [page, setPage] = useState(1);
  const [limitResponse, setLimitRespose] = useState(10);

  // console.log(fetch_result)


  // if (error) console.log(error);

  // -------------------------------
  //custom hook
  // fetch colroSets info
  useFetch_colroSet_info(
    "/findColorSets_listFromLast",
    "limit",
    limitResponse, // limit on page
    "page",
    page,
    setFetch_result
  );
  // ---------------------------------------



  // ---------------------------------------

  return (
    <div className="ColorSets">
      <div className="UserData_main">
        <section className="title">
          <h1>Vyplněné diagnostiky</h1>
        </section>

        <section className="search">
          {/* <Search
            inputType="text"
            inputLabel="Zadejte jméno uživatele"
            inputID="userName"
            inputRegex="text"
            state={userName}
            setState={setUserName}
            urlServer={urlServer}
            wordResultKey="name"
            urlFetch="/findUserName"
            route={route_findUserAndColorSet}
            fetch_result={fetch_result}
            setFetch_result={setFetch_result}
            setError={setError}
          /> */}
        </section>

        <section className="result">
          <UserDataListFromLastResult fetch_result={fetch_result} setFetch_result={setFetch_result} />
        </section>
      </div>

      {/* Page Scroller */}
      <section className="pageScroll">
        {fetch_result.totalPages && (
          <UserDataListFromLastPageScroll
            fetch_result={fetch_result}
            page={page}
            setPage={setPage}
            limitResponse={limitResponse}
            setLimitRespose={setLimitRespose}
          />
        )}
      </section>
    </div>
  );
};

export default ColorSets;
