const localStorage_get_set_color = (name) => {
    const localStorage_data = localStorage.getItem(name);
  
    if (localStorage_data) {
      let data = JSON.parse(localStorage_data);
      return data;
    } else {
      return null;
    }
  }
  
  export default localStorage_get_set_color;