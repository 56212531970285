// import React, { useState } from "react";
import "./SearcherResult.scss";
import Search2 from "./RegionAdd/Search2";

const SearcherResult = ({
  urlServer,
  fetch_result,
  fetch_usaRegion, // USA REG
  wordResultKey,
  wordResultKey2,
  setError,
  error,
  urlFetch_addArray,
  inputRegex,
  refresh,
  setRefresh,
  btnLabel,
}) => {

  return (
    <div className="SearcherResult">
      <p>{error}</p>
      {/* TO:DO - Pořešit errory - při refreshi u seach je přepsaný výsledek nalezenými a ne přidanými regiony */}
      <div className="fetch_results">
        {fetch_result && fetch_result.length > 0 ? (
          fetch_result.map((oneFetch) => {
            const {
              _id,
              [wordResultKey]: result1,
              [wordResultKey2]: result2,
            } = oneFetch;
            return (
              <div key={_id} className="fetch_oneFetch">
                <div className="fetch_oneFetch_box">
                  <p className="fetch_oneFetch_text">{result1}</p>
                  <div className="search2_box">
                    {btnLabel !== "" && (
                      <Search2
                        id={_id}
                        state={result1}
                        setError={setError}
                        urlFetch_addArray={urlFetch_addArray}
                        urlServer={urlServer}
                        wordResultKey={wordResultKey}
                        wordResultKey2={wordResultKey2}
                        refresh={refresh}
                        setRefresh={setRefresh}
                        inputRegex={inputRegex}
                        btnLabel={btnLabel}
                      />
                    )}
                  </div>
                </div>

                {Array.isArray(result2) && result2.length > 0 && (
                  <div
                    className="array1"
                    // Style for USA
                    style={
                      _id === "669e65955d1df8831ec4bfa7"
                        ? { display: "flex", flexDirection: "column" }
                        : {}
                    }
                  >
                    {result2.map(
                      (item, index) =>
                        item && (
                          <div
                            className="array2"
                            key={index}
                            style={
                              // Style for USA
                              _id === "669e65955d1df8831ec4bfa7"
                                ? { display: "flex", flexDirection: "row" }
                                : {}
                            }
                          >
                            <p>{item}</p>
                            {_id === "669e65955d1df8831ec4bfa7" /*for USA*/ && (
                              <Search2
    
                                id={index}
                                state={item}
                                setError={setError}
                                urlFetch_addArray="/addUSARegion"
                                // setFetch_data = {(data) => f_fetchUSARegion(_id, data)}
                                urlServer={urlServer}
                                wordResultKey="region"
                                wordResultKey2="usaRegion"
                                refresh={refresh}
                                setRefresh={setRefresh}
                                inputRegex={inputRegex}
                                btnLabel={"USA " + btnLabel}
                              />
                            )}
                            {
                              // USA REG
                              _id === "669e65955d1df8831ec4bfa7" &&
                              fetch_usaRegion &&
                              fetch_usaRegion.length > index ? (
                                <div>{fetch_usaRegion[index].join(", ")}</div>
                              ) : null
                            }
                          </div>
                        )
                    )}
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <p>{error}</p>
        )}
      </div>
    </div>
  );
};

export default SearcherResult;
