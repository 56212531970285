import React from "react";
import "./CheckBox_onlyShow.scss";

const CheckBoxonlyShow = ({value}) => {
  return (
    <div className="CheckBox_onlyShow">
      <label>
        <input
          type="checkbox"
          checked={value}
          readOnly={true}
        />
        <span className="checkmark"></span>
      </label>
    </div>
  );
};

export default CheckBoxonlyShow;
