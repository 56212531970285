const frequency_colorSet = [
    [1, 2, 3],
    [1, 2, 4],
    [2, 3, 4],
    [1, 3, 4],
    [5, 1, 2],
    [5, 1, 3],
    [5, 1, 4],
    [5, 2, 3],
    [5, 2, 4],
    [5, 3, 4],
    [6, 1, 2],
    [6, 1, 3],
    [6, 1, 4],
    [6, 2, 3],
    [6, 2, 4],
    [6, 3, 4],
    [1, 0, 2],
    [1, 0, 3],
    [1, 0, 4],
    [2, 0, 3],
    [2, 0, 4],
    [3, 0, 4],
    [7, 1, 2],
    [7, 1, 3],
    [7, 1, 4],
    [7, 2, 3],
    [7, 2, 4],
    [7, 3, 4],
    [6, 1, 5],
    [6, 2, 5],
    [6, 3, 5],
    [6, 4, 5],
    [1, 0, 5],
    [2, 0, 5],
    [3, 0, 5],
    [4, 0, 5],
    [1, 0, 6],
    [2, 0, 6],
    [3, 0, 6],
    [4, 0, 6],
    [7, 1, 5],
    [7, 2, 5],
    [7, 3, 5],
    [7, 4, 5],
    [7, 1, 6],
    [7, 2, 6],
    [7, 3, 6],
    [7, 4, 6],
    [7, 1, 0],
    [7, 2, 0],
    [7, 3, 0],
    [7, 4, 0],
    [5, 0, 6],
    [5, 7, 6],
    [7, 0, 5],
    [7, 0, 6]
]

export default frequency_colorSet;