import { useState, useContext } from "react";
import { GlobalContext } from "../../../../../../global/GlobalContext";
import "./Password_Change.scss";
import InputPassword from "../../../../../elements/inputs/InputPassword/InputPassword";
import route_universal from "../../../../../../routes/global/route_universal";

const PasswordChange = ({ setClose }) => {
  const { urlServer, admin_name } = useContext(GlobalContext);

  const [actual, setActual] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [errors, setErrors] = useState("");

  const btn_change = async (e) => {
    e.preventDefault();
    const response = await route_universal(
      "PATCH",
      urlServer,
      "/password_change",
      "password_old",
      actual,
      "password_new1",
      password1,
      "password_new2",
      password2
    );

    if (response.msg === "Heslo bylo úspěšně změněno") {
      setClose(false);
    } else {
      setErrors(response.msg);
    }
  };

  return (
    <form className="Password_Change">
      <h2>Změna hesla</h2>
      {/* Secret name */}
      <input
        type="text"
        name="username"
        value={admin_name}
        style={{ display: "none" }}
        readOnly
        autoComplete="username"
      />
      <InputPassword
        type="text"
        label="Původní heslo"
        id="actualPassword"
        value={actual}
        set={setActual}
        regexSetting="password"
        autoComplete="current-password"
      />
      <InputPassword
        type="text"
        label="Nové heslo"
        id="password1"
        value={password1}
        set={setPassword1}
        regexSetting="password"
        autoComplete="new-password"
      />
      <InputPassword
        type="text"
        label="Heslo znovu"
        id="password2"
        value={password2}
        set={setPassword2}
        regexSetting="password"
        autoComplete="new-password"
      />
      <button className="normalButton" onClick={btn_change}>
        Změnit heslo
      </button>
      <p>{errors}</p>
    </form>
  );
};

export default PasswordChange;
