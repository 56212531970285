import React from 'react'
import "./User_Setting.scss";
import PasswordChange from "./Password_Change/Password_Change";

const UserSetting = ({setClose}) => {
  return (
    <div className="User_Setting">
      <div className="PasswordChange">
        <PasswordChange setClose={setClose} />
      </div>
    </div>
  )
}

export default UserSetting