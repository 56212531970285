import "./scss/Login.scss";
import { useState } from "react";
import InputContainer from "../components/elements/inputs/InputContainer/InputContainer";
import InputPassword from "../components/elements/inputs/InputPassword/InputPassword";
import BtnLogin from "../components/pages/Login/BtnLogin/BtnLogin";


const Login = () => {
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [error, setError] = useState("");

  // -------------------------

  return (
    <div className="Login">
      <form className="form">
        <div className="title">
          <h1>Portál</h1>
        </div>
        <div className="input_box">
          <InputContainer
            type="email"
            label="E-mail"
            id="email"
            value={loginEmail}
            set={setLoginEmail}
            regexSetting="email"
            autoComplete="email"
          />
          <InputPassword
            label="Heslo"
            id="password"
            value={loginPassword}
            set={setLoginPassword}
            regexSetting="password"
            autoComplete="current-password"
          />
        </div>
        <div
          className="error_box"
          style={{ color: error === "Vítejte" ? "green" : "red" }}
        >
          <p>{error}</p>
        </div>
        <div className="button_box">
          <BtnLogin loginEmail={loginEmail} loginPassword={loginPassword} setError={setError} />
        </div>
      </form>
    </div>
  );
};

export default Login;
