import NavAdminData from "../../../components/layouts/AdminData/Nav_AdminData";
import { Outlet } from "react-router-dom";
import "../UserData/UserDataSharedLayouts.scss";

const AdminDataSharedLayouts = () => {
  return (
    <section className="UserDataSharedLayouts">
      <div className="UserDataSharedLayouts_nav">
        <nav>
          <NavAdminData />
        </nav>
      </div>

      <div className="UserDataSharedLayouts_main">
        <Outlet />
      </div>
    </section>
  )
}

export default AdminDataSharedLayouts