import { useState } from "react";
import "./PopUpNewRespondent.scss";
import BtnPhaseNewRespondent from "./BtnPhaseNewRespondent/BtnPhaseNewRespondent";
import BtnClose from "../../../../elements/btn/BtnClose/BtnClose";

import RespondentNew0 from "./RespondentNew0/RespondentNew0";
import RespondentNew1 from "./RespondentNew1/RespondentNew1";
import RespondentNew2 from "./RespondentNew2/RespondentNew2";

const PopUpNewRespondent = ({ setNewRespondent }) => {
  // phase 0
  const [tagSelect, setTagSelect] = useState({value: '', label: '', index: -1});
  const [newTagShow, setNewTagShow] = useState(false);
  const [selected_TagData, setSelected_TagData] = useState(null);
  const [gender, setGender] = useState(null)
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [addressing, setAddressing] = useState("");
  const [respondent_wordSet_select, setRespondent_wordSet_select] =
    useState("");
  const [emailForm, setEmailForm] = useState(null)
  // phase 1
  const [selectCompany, setSelectCompany] = useState({});
  const [wordSet_select, setWordSet_select] = useState("");
  // phase 3
  const [newCompanyShow, setNewCompanyShow] = useState(false);

  const [phase, setPhase] = useState(0);
  const [error, setError] = useState("");

  return (
    <div className="PopUpNewRespondent">
      <div className="PopUpNewRespondent_window">
        <BtnClose setClose={setNewRespondent} />

        <div className="PopUpNewRespondent_window_main">
          {
            phase === 0 && (
              <RespondentNew0
                tagSelect={tagSelect}
                setNewTagShow={setNewTagShow}
                setTagSelect={setTagSelect}
                setSelected_TagData={setSelected_TagData}
                setError={setError}
                error={error}
                newCompanyShow={newCompanyShow}
                setNewCompanyShow={setNewCompanyShow}
                // Default word set (tag's)
                respondent_wordSet_select={respondent_wordSet_select}
                setRespondent_wordSet_select={setRespondent_wordSet_select}
                // gender + name + email
                gender={gender}
                setGender={setGender}
                name={name}
                setName={setName}
                surname={surname} 
                setSurname={setSurname}
                addressing={addressing}
                setAddressing={setAddressing}
                email={email} 
                setEmail={setEmail}
                // email Form
                emailForm={emailForm}
                setEmailForm={setEmailForm}
              />
            )
          }

          {
            // Phase 1
            phase === 1 && (
              <RespondentNew1
                error={error}
                setError={setError}
                setPhase={setPhase}
                // company
                selectCompany={selectCompany}
                setSelectCompany={setSelectCompany}
                // word Set
                wordSet_select={wordSet_select} 
                setWordSet_select={setWordSet_select}
                setNewTagShow={setNewTagShow}
              />
            )
          }
          {
            // Phase 2
            phase === 2 && (
              <RespondentNew2 setError={setError} setPhase={setPhase} />
            )
          }
        </div>

        <BtnPhaseNewRespondent
          setNewRespondent={setNewRespondent} // show popUp
          phase={phase}
          setPhase={setPhase}
          error={error}
          setError={setError}
          // --
          tagSelect={tagSelect}
          gender={gender}
          name={name}
          surname={surname}
          addressing={addressing}
          email={email}
          setRespondent_wordSet_select={setRespondent_wordSet_select}
          respondent_wordSet_select={respondent_wordSet_select}
          selected_TagData={selected_TagData}
          newTagShow={newTagShow}
          setNewTagShow={setNewTagShow}
          wordSet_select={wordSet_select}
          setTagSelect={setTagSelect}
          emailForm={emailForm}
        />
      </div>
    </div>
  );
};

export default PopUpNewRespondent;
