const languages_name = [
  { code: "AR", name: "العربية" }, // Arabic
  { code: "BG", name: "Български" }, // Bulgarian
  { code: "ZH", name: "简体中文" }, // Chinese (Simplified)
  { code: "ZH-HANT", name: "繁體中文" }, // Chinese (Traditional)
  { code: "CS", name: "Čeština" }, // Czech
  { code: "DA", name: "Dansk" }, // Danish
  { code: "NL", name: "Nederlands" }, // Dutch
  { code: "EN-GB", name: "English (British)" }, // English (British)
  { code: "EN-US", name: "English (American)" }, // English (American)
  { code: "ET", name: "Eesti" }, // Estonian
  { code: "FI", name: "Suomi" }, // Finnish
  { code: "FR", name: "Français" }, // French
  { code: "DE", name: "Deutsch" }, // German
  { code: "EL", name: "Ελληνικά" }, // Greek
  { code: "HU", name: "Magyar" }, // Hungarian
  { code: "ID", name: "Bahasa Indonesia" }, // Indonesian
  { code: "IT", name: "Italiano" }, // Italian
  { code: "JA", name: "日本語" }, // Japanese
  { code: "KO", name: "한국어" }, // Korean
  { code: "LV", name: "Latviešu" }, // Latvian
  { code: "LT", name: "Lietuvių" }, // Lithuanian
  { code: "NB", name: "Norsk Bokmål" }, // Norwegian Bokmål
  { code: "PL", name: "Polski" }, // Polish
  { code: "PT-BR", name: "Português (Brasil)" }, // Portuguese (Brazilian)
  { code: "PT-PT", name: "Português (Europeu)" }, // Portuguese (European)
  { code: "RO", name: "Română" }, // Romanian
  { code: "RU", name: "Русский" }, // Russian
  { code: "SK", name: "Slovenčina" }, // Slovak
  { code: "SL", name: "Slovenščina" }, // Slovenian
  { code: "ES", name: "Español" }, // Spanish
  { code: "SV", name: "Svenska" }, // Swedish
  { code: "TR", name: "Türkçe" }, // Turkish
  { code: "UK", name: "Українська" }, // Ukrainian
];

export default languages_name;
