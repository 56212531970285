import { jsPDF } from "jspdf";
// import { chartInstances } from "../../components/pages/UsersData/UsersData_Result/UsersData_Result_GrafData/OneColorSet_GrafData/GrafData_DataForGraf/Graf/Graf";
import chartInstances from "../../utils/PDF/graf/f_chart_Instances"; // Import sdíleného objektu
import "./font";

const generatePDF = async ({
  // - graf
  id,
  // - user data
  name,
  email,
  company,
  date,
  language,
  years,
  age,
  gender,
  country,
  region,
  usaRegion,
  education,
  profession,
  maritalStatus,
  personsInTheHousehold,
  // - frequenci color set data
  // frequency_colorSet,
  // assigningWordToFrequency,
  // - Color words
  redResponse,
  blueResponse,
  greenResponse,
  whiteResponse,
  purpleResponse,
  // - graf 2 -meanings
  meanings2,
  // - graf 8 -meanings
  topThreeMeanings
}) => {
  const pdf = new jsPDF();
  const pageWidth = pdf.internal.pageSize.getWidth();
  // const imageWidth = 145;
  // const imageHeight = 110;
  // const xOffset = (pageWidth - imageWidth) / 2;

  // Nastavení fontu
  pdf.setFont("RobotoCondensed-VariableFont_wght");
  pdf.setFontSize(25);

  // - user Information
  // name
  const nameWidth = pdf.getTextWidth(name); // Získání šířky textu
  pdf.text(name, (pageWidth - nameWidth) / 2, 20); // Vycentrování textu 'name' na stránce

  //email
  pdf.setFontSize(12);
  const emailWidth = pdf.getTextWidth(email); // Získání šířky textu
  pdf.text(email, (pageWidth - emailWidth) / 2, 27); // Vycentrování textu 'name' na stránce

  // info
  // Zarovnání textu company a date doprava
  const companyTextWidth = pdf.getTextWidth(company);
  const dateTextWidth = pdf.getTextWidth(date);

  pdf.text(company, pageWidth - companyTextWidth - 10, 20); // Zarovnání company textu doprava
  pdf.text(date, pageWidth - dateTextWidth - 10, 30);       // Zarovnání date textu doprava

  //--------------------------------------------

  const drawTable = (x, y, width, height, parts) => {
    const partWidth = width / parts.length;
    const rowHeight = height + 5; // Zvýšení výšky řádku o 10 jednotek
    pdf.setDrawColor(0, 0, 255); // Modrá barva ohraničení
    pdf.setLineWidth(1); // Tloušťka ohraničení
    pdf.roundedRect(x, y, width, rowHeight, 5, 5, 'S'); // Ohraničení s radiusem
    parts.forEach((part, index) => {
      const partX = x + (index * partWidth);
      pdf.text(part, partX + 5, y + rowHeight / 2 + 3); // Text uvnitř tabulky
    });
  };
  // info table
  const drawTable_info = (x, y, width, height, parts) => {
    const partWidth = width / parts.length;
    pdf.setFontSize(10);
    pdf.setTextColor(128, 128, 128); // Nastavení šedé barvy textu (RGB hodnoty pro šedou barvu)
    
    parts.forEach((part, index) => {
      const partX = x + (index * partWidth);
      pdf.text(part, partX + 5, y + height / 2 + 3); // Text uvnitř tabulky
    });

    pdf.setTextColor(0, 0, 0); // Obnovení barvy textu zpět na černou pro další použití
    pdf.setFontSize(12);
  };

  // Tabulka 1
  drawTable_info(10, 38, pageWidth - 20, 10, ["Jazyk", "Rok Narození", "Věk", "Pohlaví"]);
  drawTable(10, 40, pageWidth - 20, 10, [`${language}`, `${years}`, `${age}`, `${gender}`]);

  // Tabulka 2
  drawTable_info(10, 53, pageWidth - 20, 10, ["Země", "Region", usaRegion ? "USA Region" : ""]);
  drawTable(10, 55, pageWidth - 20, 10, [`${country}`, `${region}`, usaRegion ? `${usaRegion}` : '']);

  // Tabulka 3
  drawTable_info(10, 68, pageWidth - 20, 10, ["Vzdělání", `Povolání`]);
  drawTable(10, 70, pageWidth - 20, 10, [`${education}`, `${profession}`]);

  // Tabulka 4
  drawTable_info(10, 83, pageWidth - 20, 10, [`Rodinný stav`, `Osob v domácnosti`]);
  drawTable(10, 85, pageWidth - 20, 10, [`${maritalStatus}`, `${personsInTheHousehold}`]);

  // ------------------------------------------
  // Trojce 
  // pdf.text("Trojce", 10, 39); // Nadpis sekce
  // frequency_colorSet.forEach((oneFreq, index) => {
  //   pdf.text(`${oneFreq[0]}${oneFreq[1]}${oneFreq[2]} : ${assigningWordToFrequency[index].length}`, 10, 45 + index * 4);
  // });
  //-------------------------------------------
  // Barva slov
  const drawColumn = (x, y, width, items, bgColor) => {
    const lineHeight = 5; // Výška jednoho řádku
    const height = items.length * lineHeight + 2; // Výška sloupce, přizpůsobená počtu položek
  
    pdf.setFillColor(...bgColor); // Nastavení barvy pozadí
    pdf.roundedRect(x, y, width, height, 3, 3, 'F'); // Vyplněný obdélník s radiusem
    pdf.setTextColor(0, 0, 0); // Nastavení barvy textu na černou
    pdf.setFontSize(10); // Nastavení menší velikosti fontu
  
    items.forEach((item, index) => {
      const text = item.word?.word_name[0] || ''; // Získání `word_name[0]` nebo prázdného řetězce
      pdf.text(text, x + 2, y + 5 + index * lineHeight); // Text uvnitř sloupce
    });
  };
  
  const columnWidth = (pageWidth - 20) / 5; // Šířka každého sloupce
  const startX = 10;
  const startY = 105;
  
  // Volání funkce pro každý sloupec
  drawColumn(startX, startY, columnWidth, redResponse, [255, 0, 0]);
  drawColumn(startX + columnWidth, startY, columnWidth, blueResponse, [173, 216, 230]);
  drawColumn(startX + columnWidth * 2, startY, columnWidth, greenResponse, [144, 238, 144]);
  drawColumn(startX + columnWidth * 3, startY, columnWidth, whiteResponse, [255, 255, 255]);
  drawColumn(startX + columnWidth * 4, startY, columnWidth, purpleResponse, [216, 191, 216]);


  // ------------------------------------------------------------------------------------------------------------------

  // Přidání nové stránky pro grafy
// pdf.addPage();


for (let i = 1; i <= 8; i++) {

const chartId = `${id}_${i}`;
const chartHeights = {
  1: 150, // Výška pro graf 1
  2: 130, // Výška pro graf 2
  3: 150, // Výška pro graf 3
  4: 150,
  5: 220,
  6: 200,
  7: 100,
  8: 110, // Výška pro graf 8
};

  const chartData = chartInstances[chartId];

  if (chartData) {
    const { chart, grafTitle } = chartData;

    // Před každým grafem přidáme novou stránku
    pdf.addPage();

    const fullPageWidth = pdf.internal.pageSize.getWidth();
    const chartHeight = chartHeights[i] || 110; // Použij specifickou výšku grafu, pokud je nastavena, jinak výchozí výška 110

    let expandedWidth = fullPageWidth * 0.95; // Výchozí šířka grafu
    let yOffset = 20; // Výchozí pozice na stránce

    // Pokud jde o 8. graf (nebo speciální graf), můžeme zvětšit šířku
    if (i === 8) {
      expandedWidth = fullPageWidth * 1.4; // Zvětšíme šířku pro specifický graf (8)
    }

    // Zarovnání grafu horizontálně
    const xOffsetExpanded = (fullPageWidth - expandedWidth) / 2;

    // Zobrazíme nadpis grafu
    pdf.setFontSize(16);
    const wrappedText = pdf.splitTextToSize(grafTitle, fullPageWidth - 20);
    wrappedText.forEach((line, index) => {
      const textWidth = pdf.getTextWidth(line);
      pdf.text((fullPageWidth - textWidth) / 2, yOffset - 10 + index * 6, line);
    });

    // Získání obrázku grafu
    const { imgURI } = await chart.dataURI({
      scale: 1.5, // Zvýšení rozlišení obrázku
      width: fullPageWidth,
    });

    // Vykreslení grafu na stránce s konkrétní výškou
    pdf.addImage(imgURI, "PNG", xOffsetExpanded, yOffset, expandedWidth, chartHeight);

// graf 2
if (i === 2 && meanings2.length > 0) {
  const meaning = meanings2[0].meaning; // Opravený přístup k vnořeným hodnotám

  const meaningBoxHeight = 50; // Výška boxu s významem
  const meaningBoxPadding = 5; // Padding boxu

  const totalBoxWidth = pageWidth - 40; // Celková dostupná šířka boxu
  const startX = (pageWidth - totalBoxWidth) / 2; // Zarovnání boxu na střed
  const textYStart = yOffset + chartHeight + 20; // Pozice Y pro text pod grafem

  // Pokud je text k dispozici
  const title = meaning.title || ''; 
  const description = meaning.description || ''; 
  const practice = meaning.practice || '';

  // Pokud není nic k zobrazení, přeskočíme
  if (title || description || practice) {
    // Vykreslení menšího šedého pozadí, které obklopuje box
    const backgroundPadding = 5; 
    pdf.setFillColor(211, 211, 211); // Světle šedé pozadí
    pdf.roundedRect(
      startX - backgroundPadding, 
      textYStart - backgroundPadding, 
      totalBoxWidth + 2 * backgroundPadding, 
      meaningBoxHeight + 2 * backgroundPadding + 20, // Adjusted height for text space
      5, 5, 'F'
    );

    // Vykreslení bílého boxu
    pdf.setFillColor(255, 255, 255); // Bílé pozadí
    pdf.roundedRect(startX, textYStart, totalBoxWidth, meaningBoxHeight + 20, 3, 3, 'F'); 

    // Nastavení textu uvnitř boxu
    pdf.setFontSize(14);
    pdf.setTextColor(84, 84, 194); // Modrá barva nadpisu
    pdf.text(title, startX + meaningBoxPadding, textYStart + 10); // Nadpis

    // Popis a praktické informace
    pdf.setFontSize(12);
    pdf.setTextColor(0, 0, 0); // Černá barva textu
    const wrappedDescription = pdf.splitTextToSize(description, totalBoxWidth - 2 * meaningBoxPadding);
    wrappedDescription.forEach((line, index) => {
      pdf.text(line, startX + meaningBoxPadding, textYStart + 20 + index * 6); 
    });

    const wrappedPractice = pdf.splitTextToSize(practice, totalBoxWidth - 2 * meaningBoxPadding);
    wrappedPractice.forEach((line, index) => {
      pdf.text(line, startX + meaningBoxPadding, textYStart + 30 + (wrappedDescription.length * 6) + index * 6);
    });
  }
}
// 8. graf, přidáme významy pod něj
if (i === 8 && topThreeMeanings.length > 0) {
  const meaningYStart = yOffset + chartHeight + 20; // Y pozice pod grafem
  const meaningBoxHeight = 40; // Výška každého významového boxu
  const meaningBoxPadding = 1; // Vnitřní padding boxů

  const totalBoxWidth = pageWidth - 40; // Celková dostupná šířka pro boxy (mírně zmenšeno, aby nebylo přes celou šířku stránky)
  const boxWidth = (totalBoxWidth / 3) - 3; // Každý box má třetinu dostupné šířky minus mezery
  const startX = (pageWidth - totalBoxWidth) / 2; // Začátek pro první box, vycentrovaný
  const gapBetweenBoxes = 4; // Mezera mezi boxy

  // Vykreslení menšího šedého pozadí, které obklopuje všechny boxy
  const backgroundPadding = 5; // Padding kolem všech boxů (zmenšeno na 5px)
  pdf.setFillColor(211, 211, 211); // Světle šedé pozadí
  pdf.roundedRect(
    startX - backgroundPadding, 
    meaningYStart - backgroundPadding, 
    totalBoxWidth + 2 * backgroundPadding, 
    meaningBoxHeight + 2 * backgroundPadding, 
    5, 5, 'F'
  );

  // Vykreslení jednotlivých bílých boxů
  topThreeMeanings.forEach((oneMeaning, index) => {
    const boxX = startX + index * (boxWidth + gapBetweenBoxes); // Přidána mezera mezi boxy
    const boxY = meaningYStart;

    // Nastavení bílého pozadí pro každý box
    pdf.setFillColor(255, 255, 255); // Bílé pozadí
    pdf.roundedRect(boxX, boxY, boxWidth, meaningBoxHeight, 3, 3, 'F'); // Ohraničení boxu

    // Nastavení textu uvnitř boxu
    pdf.setFontSize(12);
    pdf.setTextColor(84, 84, 194); // Modrá barva nadpisu
    pdf.text(oneMeaning.meaning.role, boxX + meaningBoxPadding, boxY + 10); // Nadpis role

    pdf.setFontSize(10);
    pdf.setTextColor(0, 0, 0); // Černá barva textu
    pdf.text(oneMeaning.meaning.meaning, boxX + meaningBoxPadding, boxY + 18, { maxWidth: boxWidth - 10 }); // Text významu
  });
}
  } else {
    console.error(`Chart with ID ${chartId} not found`);
  }
}

// Uložení PDF
pdf.save(`Výstup_${name}_${email}.pdf`);
};

export default generatePDF;