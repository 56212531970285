import React from "react";

const AddValue = ({
  wordResultKey,
  state,
  urlServer,
  urlFetch,
  route,
  setError,
}) => {

  const btnClick = async () => {
    if (state.length >= 2) {
      const result = await route(wordResultKey, "", state, "", urlServer, urlFetch);
      setError(result.msg);
      //console.log(result)
    }
  };

  return (
    <div>
      <button onClick={btnClick}>Přidat</button>
    </div>
  );
};

export default AddValue;
