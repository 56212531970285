import { BlockPicker } from "react-color"; // Můžeš použít i jiné pickery, např. ChromePicker, PhotoshopPicker atd.
import "./InputColor.scss"
import BtnClose from "../../btn/BtnClose/BtnClose";

const InputColor = ({selectedColor, setSelectedColor, setShowColor}) => {

    const handleColorChange = (color) => {
        setSelectedColor(color.hex); // Ukládá hex kód barvy
    };

    return (
        <div className="InputColor">
            <BtnClose setClose={setShowColor} />
            <h3>Vyberte barvu</h3>
            <BlockPicker
                color={selectedColor}
                onChangeComplete={handleColorChange} // Změna se ukládá při potvrzení
                colors={[
                    // Červené odstíny (tmavá první, světlá poslední)
                    "#CC0000", "#FF6666", "#FFCCCC",
                    // Oranžové odstíny
                    "#FF8C00", "#FFA500", "#FFDAB9",
                    // Žluté odstíny
                    "#FFD700", "#FFFF33", "#FFFF99",
                    // Zelené odstíny
                    "#008000", "#66FF66", "#CCFFCC",
                    // Tyrkysové odstíny
                    "#00CED1", "#66FFFF", "#CCFFFF",
                    // Modré odstíny
                    "#0000FF", "#6666FF", "#CCCCFF",
                    // Fialové odstíny
                    "#660066", "#9966CC", "#E6CCFF",
                    // Růžové odstíny
                    "#FF1493", "#FF69B4", "#FFC0CB",
                    // Hnědé odstíny
                    "#8B4513", "#A0522D", "#D2B48C",
                    // Šedé odstíny
                    "#696969", "#A9A9A9", "#D3D3D3",
                    // // Černá a bílá
                    // "#000000", "#FFFFFF"
                ]}
                styles={{
                    default: {
                        input: {
                            display: 'none'  // skryje hex input
                        },
                        label: {
                            display: 'none'  // skryje hex label
                        },
                        head: {
                            display: 'none'  // skryje celý náhled nad barvami
                        }
                    }
                }}
            />
            <button className="btnColorSelect" onClick={() => setShowColor(false)} style={{backgroundColor: selectedColor}}>Vybráno</button>
        </div>
    );
};

export default InputColor;