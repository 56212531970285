import { NavLink } from "react-router-dom";
import { useState, useContext } from "react";
import { HiMenu } from "react-icons/hi";
// import { useContext } from "react";
import "./scss/Header.scss";
import mainLogo from "../../../img/novitim_logo_web_250.png";
import { GlobalContext } from "../../GlobalContext";

import LogOut from "../UserData/LogOut/LogOut";
import BtnRegistration from "../UserData/Registration/BtnRegistration";

const Header = () => {
  const { userName } = useContext(GlobalContext);
  const [showMenu, setShowMenu] = useState(false);

  const hideMenu = () => {
    setShowMenu(false);
  };

  return (
    <header className={`${userName ? "whiteBG" : ""}`}>
      <div className="nav-header">
        <div className="logo">
          <NavLink to="/">
            <img src={mainLogo} alt="Novitim Logo" />
          </NavLink>
        </div>

        <button
          className="humburger-button"
          onClick={() => setShowMenu(!showMenu)}
        >
          <HiMenu className="hamburger-icon" />
        </button>
      </div>

      <div className="nav-box">
        <nav className={`${showMenu ? "show" : "hide"}`}>
        <div className="registration" onClick={hideMenu}>
          <BtnRegistration />
        </div>
          <div className="logOut" onClick={hideMenu}>
            <LogOut />
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
