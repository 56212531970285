const route_put_universal = async (
    urlServer,
    urlFetch,
    wordResultKey,
    state1,
    wordResultKey2,
    state2,
    wordResultKey3,
    state3,
    wordResultKey4,
    state4,
    wordResultKey5,
    state5,
    wordResultKey6,
    state6,
    wordResultKey7,
    state7,
    wordResultKey8,
    state8,
    wordResultKey9,
    state9,
    wordResultKey10,
    state10,
    //------- NEXT VALUE GET ONLY HERE BOTTOM !!!!!!!!!
  ) => {
    try {
      let body = {};
  
      if (wordResultKey && state1) {
        body = {
          [wordResultKey]: state1,
        };
      }
  
      // When exist WordResultKey 2
      if (wordResultKey2 && state2) {
        body[wordResultKey2] = state2;
      }
      if (wordResultKey3 && state3 !== "") {
        body[wordResultKey3] = state3;
      }
      if (wordResultKey4 && state4 !== "") {
        body[wordResultKey4] = state4;
      }
      if (wordResultKey5 && state5 !== "") {
        body[wordResultKey5] = state5;
      }
      if (wordResultKey6 && state6 !== "") {
        body[wordResultKey6] = state6;
      }
      if (wordResultKey7 && state7 !== "") {
        body[wordResultKey7] = state7;
      }
      if (wordResultKey8 && state8 !== "") {
        body[wordResultKey8] = state8;
      }
      if (wordResultKey9 && state9 !== "") {
        body[wordResultKey9] = state9;
      }
      if (wordResultKey10 && state10 !== "") {
        body[wordResultKey10] = state10;
      }
  
      const isEmptyObject = obj => {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
      };
  
      const requestOptions = {
        method: "PUT",
        credentials: 'include',
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        ...(isEmptyObject(body) ? {} : { body: JSON.stringify(body) }),
      };
  
      const response = await fetch(urlServer + urlFetch, requestOptions);
  
      const data = await response.json();
  
      if (!response.ok) {
        return console.error(data);
      }
  
      return data;
    } catch (err) {
      return console.error(
        `Chyba FrontEnd catch ${err}. Kontaktujte nás. Váš Novitim.`
      );
    }
  };
  
  export default route_put_universal;